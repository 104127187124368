import moment from "moment";
import { useTranslation } from "react-i18next";
import constants from "../components/constants";

export const PublicationContentCard = (props) => {
  const { t } = useTranslation();
  const publication = props?.publication;
  return (
    <div
      class="col-lg-6 col-md-6 service-item d-flex"
      data-aos="fade-up"
      data-aos-delay="200"
    >
      <div class="icon flex-shrink-0"></div>
      <div>
        <h4 class="bdf-bluefontcolor ">{publication?.title}</h4>
        <p class="description">{publication?.description}</p>
        <h6 className="">
          <a
            href={`${process.env.REACT_APP_BACKEND_URL}/${publication?.fileName}`}
            target={"_blank"}
            className="text-danger"
          >
            {t("Read The File")}
          </a>
        </h6>
        <div className="d-flex justify-content-between p-2 bdf-bluefontcolor">
          <h6>
            {t("Published")}:{" "}
            {moment(publication?.publisheddate).format("DD-MM-YYYY")}
          </h6>
          <h6
            className={
              publication?.type === constants.career ||
              publication?.type === constants.tender
                ? "d-show px-2"
                : "d-none"
            }
          >
            {t("Deadline")}:{" "}
            {moment(publication?.deadline).format("DD-MM-YYYY")}
          </h6>
        </div>
      </div>
    </div>
  );
};
