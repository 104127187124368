import * as actions from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { ScrollToTop } from "../../components/backtotop";
import Header from "../components/header";
import { EditButton, PageLink, ViewButton } from "../components/inputs";
import Modal from "react-bootstrap/Modal";
import moment from "moment";
import {
  successCreationNotification,
  successNotification,
} from "../../components/notifications";
import { ReactNotifications } from "react-notifications-component";
import classnames from "classnames";
import MiSpinner from "../../components/spinner";
import { ObjectDetails } from "../components/output";

export const AdminHomeList = function (props) {
  const WORKSPACE = "HOME";
  const loading = useSelector((state) => state.homepage.homesLoading);
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);
  const object = useSelector((state) => state.homepage.home);
  const objectList = useSelector((state) => state.homepage.homes);

  useEffect(() => {
    dispatch(actions.getHomes());
  }, []);

  const [show, setShow] = useState(false);
  const handleClose = (e) => {
    e.preventDefault();
    dispatch(actions.getHomes());
    setShow(false);
  };

  const viewMore = (id) => {
    dispatch(actions.getOneHomes(token, id));
    setShow(true);
  };

  return (
    <div className="">
      <Header />
      <ReactNotifications />
      <main id="main">
        <div className="breadcrumbs-modified d-flex align-items-center"></div>
        {loading ? (
          <MiSpinner />
        ) : (
          <>
            <Modal show={show} onHide={handleClose} size="xl">
              <Modal.Body>
                {loading ? (
                  <MiSpinner />
                ) : (
                  <div className="row px-3 pt-5 pb-2">
                    <div className="col-md-2"></div>
                    <div className="col-md-10 px-5">
                      <span>
                        <span className="bdf-bluefontcolor">Title:</span>{" "}
                        {object?.title}
                      </span>
                      <div>
                        <p className="description pt-3">
                          <span className="bdf-bluefontcolor">
                            Description:{" "}
                          </span>{" "}
                          {object?.description}
                        </p>
                      </div>
                      <div className="mb-3">
                        <ObjectDetails
                          title={"Language"}
                          value={object?.language}
                        />
                        <ObjectDetails
                          title={"Status"}
                          value={object?.status}
                        />
                        <ObjectDetails
                          title={"Creator"}
                          value={object?.creator}
                        />
                        <ObjectDetails
                          title={"Created At"}
                          value={moment(object?.createdAt).format("DD-MM-YYYY")}
                        />
                      </div>
                      <button className="mi-btn" onClick={handleClose}>
                        Close
                      </button>
                      <span className="px-3"></span>
                      <EditButton
                        title="Edit"
                        page="/admin/home"
                        id={object?.uuid}
                      />
                    </div>
                  </div>
                )}
              </Modal.Body>
            </Modal>
            <section id="contact" className="input-data">
              <div className="container" data-aos="fade-up">
                <div className="row d-flex justify-content-end php-email-form">
                  <div className="col-md-8">
                    <div className="text-center">
                      <h5 className="bdf-bluefontcolor">{WORKSPACE} LIST</h5>
                    </div>
                    <table className="table table-striped">
                      <thead>
                        <tr className="bdf-bluefontcolor">
                          <th>#</th>
                          <th>Title</th>
                          <th>Status</th>
                          <th>Language</th>
                          <th>Creator</th>
                          <th className="text-center">Action</th>
                        </tr>
                      </thead>
                      <tbody className="">
                        {objectList?.map((data, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{data?.title}</td>
                            <td>{data?.status}</td>
                            <td>{data?.language}</td>
                            <td>{data?.creator}</td>
                            <td className="row d-flex justify-content-center">
                              <EditButton
                                title="Edit"
                                page="/admin/home"
                                id={data?.uuid}
                              />
                              <span className="px-2 w-auto"></span>
                              <ViewButton
                                title={"View "}
                                onClick={(e) => {
                                  e.preventDefault();
                                  viewMore(data?.uuid);
                                }}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div className="col-md-3 d-flex gy-5 ml-5 justify-content-end">
                    <div>
                      <div className="flex flex-column h-50">
                        <PageLink page={"/admin/home"} title={"New Home"} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </>
        )}
      </main>
      <ScrollToTop />
    </div>
  );
};

export const AdminHomeAchievementTitlessList = function (props) {
  const WORKSPACE = "HOME ACHIEVEMENT TITLES";
  const loading = useSelector(
    (state) => state.homepage.achievementTitlesLoading
  );
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);
  const object = useSelector((state) => state.homepage.achievementTitle);
  const objectList = useSelector((state) => state.homepage.achievementTitles);

  useEffect(() => {
    dispatch(actions.getHomeAchievementTitles());
  }, []);

  const [show, setShow] = useState(false);
  const handleClose = (e) => {
    e.preventDefault();
    dispatch(actions.getHomeAchievementTitles());
    setShow(false);
  };

  const viewMore = (id) => {
    dispatch(actions.getOneHomeAchievementTitles(token, id));
    setShow(true);
  };

  return (
    <div className="">
      <Header />
      <ReactNotifications />
      <main id="main">
        <div className="breadcrumbs-modified d-flex align-items-center"></div>
        {loading ? (
          <MiSpinner />
        ) : (
          <>
            <Modal show={show} onHide={handleClose} size="xl">
              <Modal.Body>
                {loading ? (
                  <MiSpinner />
                ) : (
                  <div className="row px-3 pt-5 pb-2">
                    <div className="col-md-2"></div>
                    <div className="col-md-10 px-5">
                      <span>
                        <span className="bdf-bluefontcolor">Title:</span>{" "}
                        {object?.title}
                      </span>
                      <div className="mb-3">
                        <ObjectDetails
                          title={"Language"}
                          value={object?.language}
                        />
                        <ObjectDetails
                          title={"Status"}
                          value={object?.status}
                        />
                        <ObjectDetails
                          title={"Creator"}
                          value={object?.creator}
                        />
                        <ObjectDetails
                          title={"Created At"}
                          value={moment(object?.createdAt).format("DD-MM-YYYY")}
                        />
                      </div>
                      <button className="mi-btn" onClick={handleClose}>
                        Close
                      </button>
                      <span className="px-3"></span>
                      <EditButton
                        title="Edit"
                        page="/admin/home-achievements"
                        id={object?.uuid}
                      />
                    </div>
                  </div>
                )}
              </Modal.Body>
            </Modal>
            <section id="contact" className="input-data">
              <div className="container" data-aos="fade-up">
                <div className="row d-flex justify-content-end php-email-form">
                  <div className="col-md-8">
                    <div className="text-center">
                      <h5 className="bdf-bluefontcolor">{WORKSPACE} LIST</h5>
                    </div>
                    <table className="table table-striped">
                      <thead>
                        <tr className="bdf-bluefontcolor">
                          <th>#</th>
                          <th>Title</th>
                          <th>Status</th>
                          <th>Language</th>
                          <th>Creator</th>
                          <th className="text-center">Action</th>
                        </tr>
                      </thead>
                      <tbody className="">
                        {objectList?.map((data, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{data?.title}</td>
                            <td>{data?.status}</td>
                            <td>{data?.language}</td>
                            <td>{data?.creator}</td>
                            <td className="row d-flex justify-content-center">
                              <EditButton
                                title="Edit"
                                page="/admin/home-achievements"
                                id={data?.uuid}
                              />
                              <span className="px-2 w-auto"></span>
                              <ViewButton
                                title={"View "}
                                onClick={(e) => {
                                  e.preventDefault();
                                  viewMore(data?.uuid);
                                }}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div className="col-md-3 d-flex gy-5 ml-5 justify-content-end">
                    <div>
                      <div className="flex flex-column h-50 text-center">
                        <PageLink
                          page={"/admin/home-achievements"}
                          title={"New Title"}
                        />
                        <div className="pt-3">
                          <PageLink
                            page={"/admin/home-achievements-list/objects/list"}
                            title={"Achievements"}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </>
        )}
      </main>
      <ScrollToTop />
    </div>
  );
};

export const AdminHomeAchievementsList = function (props) {
  const WORKSPACE = "HOME ACHIEVEMENT";
  const loading = useSelector((state) => state.homepage.achievementsLoading);
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);
  const object = useSelector((state) => state.homepage.achievement);
  const objectList = useSelector((state) => state.homepage.achievements);

  useEffect(() => {
    dispatch(actions.getHomeAchievements());
  }, []);

  const [show, setShow] = useState(false);
  const handleClose = (e) => {
    e.preventDefault();
    dispatch(actions.getHomeAchievements());
    setShow(false);
  };

  const viewMore = (id) => {
    dispatch(actions.getOneHomeAchievements(token, id));
    setShow(true);
  };

  return (
    <div className="">
      <Header />
      <ReactNotifications />
      <main id="main">
        <div className="breadcrumbs-modified d-flex align-items-center"></div>
        {loading ? (
          <MiSpinner />
        ) : (
          <>
            <Modal show={show} onHide={handleClose} size="xl">
              <Modal.Body>
                {loading ? (
                  <MiSpinner />
                ) : (
                  <div className="row px-3 pt-5 pb-2">
                    <div className="col-md-2"></div>
                    <div className="col-md-10 px-5">
                      <span>
                        <span className="bdf-bluefontcolor">Title:</span>{" "}
                        {object?.name}
                      </span>
                      <div className="mb-3">
                        <ObjectDetails
                          title={"Language"}
                          value={object?.language}
                        />
                        <ObjectDetails
                          title={"Status"}
                          value={object?.status}
                        />
                        <ObjectDetails
                          title={"Creator"}
                          value={object?.creator}
                        />
                        <ObjectDetails
                          title={"Created At"}
                          value={moment(object?.createdAt).format("DD-MM-YYYY")}
                        />
                      </div>
                      <button className="mi-btn" onClick={handleClose}>
                        Close
                      </button>
                      <span className="px-3"></span>
                      <EditButton
                        title="Edit"
                        page="/admin/home-achievements-list"
                        id={object?.uuid}
                      />
                    </div>
                  </div>
                )}
              </Modal.Body>
            </Modal>
            <section id="contact" className="input-data">
              <div className="container" data-aos="fade-up">
                <div className="row d-flex justify-content-end php-email-form">
                  <div className="col-md-8">
                    <div className="text-center">
                      <h5 className="bdf-bluefontcolor">{WORKSPACE} LIST</h5>
                    </div>
                    <table className="table table-striped">
                      <thead>
                        <tr className="bdf-bluefontcolor">
                          <th>#</th>
                          <th>Title</th>
                          <th>Status</th>
                          <th>Language</th>
                          <th>Creator</th>
                          <th className="text-center">Action</th>
                        </tr>
                      </thead>
                      <tbody className="">
                        {objectList?.map((data, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{data?.name}</td>
                            <td>{data?.status}</td>
                            <td>{data?.language}</td>
                            <td>{data?.creator}</td>
                            <td className="row d-flex justify-content-center">
                              <EditButton
                                title="Edit"
                                page="/admin/home-achievements-list"
                                id={data?.uuid}
                              />
                              <span className="px-2 w-auto"></span>
                              <ViewButton
                                title={"View "}
                                onClick={(e) => {
                                  e.preventDefault();
                                  viewMore(data?.uuid);
                                }}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div className="col-md-3 d-flex gy-5 ml-5 justify-content-end">
                    <div>
                      <div className="flex flex-column h-50 text-center">
                        <PageLink
                          page={"/admin/home-achievements-list"}
                          title={"New Achievement"}
                        />
                        <div className="pt-3">
                          <PageLink
                            page={"/admin/home-achievements"}
                            title={"New Title"}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </>
        )}
      </main>
      <ScrollToTop />
    </div>
  );
};

export const PartnersList = function (props) {
  const WORKSPACE = "PARTNERS";
  const loading = useSelector((state) => state.homepage.partnersLoading);
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);
  const object = useSelector((state) => state.homepage.partner);
  const objectList = useSelector((state) => state.homepage.partners);

  useEffect(() => {
    dispatch(actions.getPartners());
  }, []);

  const [show, setShow] = useState(false);
  const handleClose = (e) => {
    e.preventDefault();
    dispatch(actions.getPartners());
    setShow(false);
  };

  const viewMore = (id) => {
    dispatch(actions.getOnePartners(token, id));
    setShow(true);
  };

  return (
    <div className="">
      <Header />
      <ReactNotifications />
      <main id="main">
        <div className="breadcrumbs-modified d-flex align-items-center"></div>
        {loading ? (
          <MiSpinner />
        ) : (
          <>
            <Modal show={show} onHide={handleClose} size="xl">
              <Modal.Body>
                {loading ? (
                  <MiSpinner />
                ) : (
                  <div className="row px-3 pt-5 pb-2">
                    <div className="col-md-2"></div>
                    <div className="col-md-10 px-5">
                      <span>
                        <span className="bdf-bluefontcolor">Name:</span>{" "}
                        {object?.name}
                      </span>
                      <div>
                        <p className="description pt-3">
                          <span className="bdf-bluefontcolor">Logo: </span>{" "}
                          <img
                            alt="logo"
                            src={`${process.env.REACT_APP_BACKEND_URL}/${object?.logo}`}
                          />
                        </p>
                      </div>
                      <div className="mb-3">
                        <ObjectDetails
                          title={"Language"}
                          value={object?.language}
                        />
                        <ObjectDetails
                          title={"Status"}
                          value={object?.status}
                        />
                        <ObjectDetails
                          title={"Creator"}
                          value={object?.creator}
                        />
                        <ObjectDetails
                          title={"Created At"}
                          value={moment(object?.createdAt).format("DD-MM-YYYY")}
                        />
                      </div>
                      <button className="mi-btn" onClick={handleClose}>
                        Close
                      </button>
                      <span className="px-3"></span>
                      <EditButton
                        title="Edit"
                        page="/admin/partners"
                        id={object?.uuid}
                      />
                    </div>
                  </div>
                )}
              </Modal.Body>
            </Modal>
            <section id="contact" className="input-data">
              <div className="container" data-aos="fade-up">
                <div className="row d-flex justify-content-end php-email-form">
                  <div className="col-md-8">
                    <div className="text-center">
                      <h5 className="bdf-bluefontcolor">{WORKSPACE} LIST</h5>
                    </div>
                    <table className="table table-striped">
                      <thead>
                        <tr className="bdf-bluefontcolor">
                          <th>#</th>
                          <th>Title</th>
                          <th>Status</th>
                          <th>Creator</th>
                          <th className="text-center">Action</th>
                        </tr>
                      </thead>
                      <tbody className="">
                        {objectList?.map((data, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{data?.name}</td>
                            <td>{data?.status}</td>
                            <td>{data?.creator}</td>
                            <td className="row d-flex justify-content-center">
                              <EditButton
                                title="Edit"
                                page="/admin/partners"
                                id={data?.uuid}
                              />
                              <span className="px-2 w-auto"></span>
                              <ViewButton
                                title={"View "}
                                onClick={(e) => {
                                  e.preventDefault();
                                  viewMore(data?.uuid);
                                }}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div className="col-md-3 d-flex gy-5 ml-5 justify-content-end">
                    <div>
                      <div className="flex flex-column h-50">
                        <PageLink
                          page={"/admin/partners"}
                          title={"New Partner"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </>
        )}
      </main>
      <ScrollToTop />
    </div>
  );
};
