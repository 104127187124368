import { useEffect, useState } from "react";
import { HeaderLink, HomeHeaderLink } from "../../components/links";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../store/actions/auth";
import { useHistory } from "react-router-dom";
const Header = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const token = useSelector((state) => state.auth.token);
  const displayName = useSelector((state) => state.auth.displayName);

  const logouti = (e) => {
    e.preventDefault();
    dispatch(logout());
    history.push("/sign-in");
  };

  const profile = (e) => {
    e.preventDefault();
    history.push("/admin/profile");
  };

  useEffect(() => {
    // dispatch(actions.getActiveLanguages());
  }, [token]);

  return (
    <header
      id="header"
      className="header d-flex align-items-center fixed-top bg-bdf-blue"
    >
      <div className="container-fluid container-xl d-flex align-items-center justify-content-between">
        <HomeHeaderLink />
        <nav id="navbar" className={token ? "navbar" : "d-none"}>
          <ul className="me-3">
            <li className="dropdown">
              <HeaderLink page="/admin/home" name={"Home"} />
              <ul>
                <li>
                  <HeaderLink page="/admin/home" name={"Home"} />
                </li>
                <li>
                  <HeaderLink
                    page="/admin/home-achievements"
                    name={"Home Achievements"}
                  />
                </li>
                <li>
                  <HeaderLink page="/admin/partners" name={"Partners"} />
                </li>
              </ul>
            </li>
            <li className="dropdown">
              <HeaderLink page="/admin/about/bdf-background" name={"About"} />
              <ul>
                <li>
                  <HeaderLink
                    page="/admin/about/bdf-background"
                    name={"BDF Background"}
                  />
                </li>
                <li>
                  <HeaderLink
                    page="/admin/about/executives"
                    name={"BDF Management"}
                  />
                </li>
                <li>
                  <HeaderLink
                    page="/admin/about/missions"
                    name={"BDF Mission"}
                  />
                </li>
                <li>
                  <HeaderLink page="/admin/about/visions" name={"BDF Vision"} />
                </li>
                <li>
                  <HeaderLink page="/admin/about/values" name={"BDF Values"} />
                </li>
              </ul>
            </li>
            <li className="dropdown">
              <HeaderLink page="/admin/services" name={"Services"} />
            </li>
            <li className="dropdown">
              <HeaderLink page="/admin/publications" name={"Publications"} />
              <ul>
                <li>
                  <HeaderLink
                    page="/admin/publications"
                    name={"Publications"}
                  />
                </li>
                <li>
                  <HeaderLink
                    page="/admin/text-testimonies"
                    name={"Text Testimonies"}
                  />
                </li>
                <li>
                  <HeaderLink
                    page="/admin/youtube-testimonies"
                    name={"Youtube Testimonies"}
                  />
                </li>
              </ul>
            </li>
            <li className="dropdown">
              <HeaderLink page="/admin/trainings-intros" name={"Trainings"} />
              <ul>
                <li>
                  <HeaderLink
                    page="/admin/trainings-intros"
                    name={"Training Intro"}
                  />
                </li>
                <li>
                  <HeaderLink
                    page="/admin/trainings-categories"
                    name={"Training Categories"}
                  />
                </li>
              </ul>
            </li>
            <li className="dropdown">
              <HeaderLink page="/admin/languages" name={"Utilities"} />
              <ul>
                <li>
                  <HeaderLink page="/admin/languages" name={"Languages"} />
                </li>
                <li>
                  <HeaderLink page="/admin/users" name={"Users"} />
                </li>
                <li>
                  <HeaderLink page="/admin/links" name={"Application Links"} />
                </li>
                <li>
                  <HeaderLink page="/sms-module" name={"SMS Module"} />
                </li>
              </ul>
            </li>
          </ul>
        </nav>
        <div className={token ? "row user-profile" : "d-none"}>
          <span className="col-md-8">{displayName}</span>
          <i onMouseDown={profile} className="bi-person-heart col-md-2"></i>
          <i onMouseDown={logouti} className="bi-lock-fill col-md-2"></i>
        </div>
      </div>
    </header>
  );
};

export default Header;
