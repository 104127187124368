import { updateObject } from "../../utils/utility";
import {
  GET_BACKGROUNDS_START,
  GET_BACKGROUNDS_SUCCESS,
  GET_ONE_BACKGROUNDS_SUCCESS,
  GET_BACKGROUNDS_FAIL,
  GET_MISSION_VISION_VALUES_START,
  GET_MISSION_VISION_VALUES_SUCCESS,
  GET_ONE_MISSION_VISION_VALUES_SUCCESS,
  GET_MISSION_VISION_VALUES_FAIL,
  GET_SM_EXECUTIVES_START,
  GET_SM_EXECUTIVES_SUCCESS,
  GET_ONE_SM_EXECUTIVES_SUCCESS,
  GET_SM_EXECUTIVES_FAIL,
  GET_SM_MANAGERS_START,
  GET_SM_MANAGERS_SUCCESS,
  GET_ONE_SM_MANAGERS_SUCCESS,
  GET_SM_MANAGERS_FAIL,
  GET_BD_EXECUTIVES_START,
  GET_BD_EXECUTIVES_SUCCESS,
  GET_ONE_BD_EXECUTIVES_SUCCESS,
  GET_BD_EXECUTIVES_FAIL,
  GET_BD_MANAGERS_START,
  GET_BD_MANAGERS_SUCCESS,
  GET_ONE_BD_MANAGERS_SUCCESS,
  GET_BD_MANAGERS_FAIL,
} from "../actions/about";

const initialReducer = {
  background: null,
  backgrounds: [],
  backgroundsLoading: false,
  backgroundsFail: null,
  misVisValue: null,
  misVisValues: [],
  misVisValuesLoading: false,
  misVisValuesFail: null,
  smExecutive: null,
  smExecutives: [],
  smExecutivesLoading: false,
  smExecutivesFail: null,
  smManager: null,
  smManagers: [],
  smManagersLoading: false,
  smManagersFail: null,
  bdExecutive: null,
  bdExecutives: [],
  bdExecutivesLoading: false,
  bdExecutivesFail: null,
  bdManager: null,
  bdManagers: [],
  bdManagersLoading: false,
  bdManagersFail: null,
};

const getBackgroundsStart = (state, action) => {
  return updateObject(state, {
    backgrounds: [],
    background: null,
    backgroundsLoading: true,
    backgroundsFail: null,
  });
};

const getBackgroundsSuccess = (state, action) => {
  return updateObject(state, {
    backgrounds: action.payload,
    backgroundsLoading: false,
    backgroundsFail: null,
    background: null,
  });
};

const getOneBackgroundsSuccess = (state, action) => {
  return updateObject(state, {
    background: action.payload,
    backgrounds: [],
    backgroundsLoading: false,
    backgroundsFail: null,
  });
};

const getBackgroundsFail = (state, action) => {
  return updateObject(state, {
    backgrounds: [],
    background: null,
    backgroundsLoading: false,
    backgroundsFail: action.payload,
  });
};

const getMissionVisionValuesStart = (state, action) => {
  return updateObject(state, {
    misVisValues: [],
    misVisValue: null,
    misVisValuesLoading: true,
    misVisValuesFail: null,
  });
};

const getMissionVisionValuesSuccess = (state, action) => {
  return updateObject(state, {
    misVisValues: action.payload,
    misVisValuesLoading: false,
    misVisValuesFail: null,
    misVisValue: null,
  });
};

const getOneMissionVisionValuesSuccess = (state, action) => {
  return updateObject(state, {
    misVisValue: action.payload,
    misVisValues: [],
    misVisValuesLoading: false,
    misVisValuesFail: null,
  });
};

const getMissionVisionValuesFail = (state, action) => {
  return updateObject(state, {
    misVisValues: [],
    misVisValue: null,
    misVisValuesLoading: false,
    misVisValuesFail: action.payload,
  });
};

const getSMExecutivesStart = (state, action) => {
  return updateObject(state, {
    smExecutives: [],
    smExecutive: null,
    smExecutivesLoading: true,
    smExecutivesFail: null,
  });
};

const getSMExecutivesSuccess = (state, action) => {
  return updateObject(state, {
    smExecutives: action.payload,
    smExecutivesLoading: false,
    smExecutivesFail: null,
    smExecutive: null,
  });
};

const getOneSMExecutivesSuccess = (state, action) => {
  return updateObject(state, {
    smExecutive: action.payload,
    smExecutives: [],
    smExecutivesLoading: false,
    smExecutivesFail: null,
  });
};

const getSMExecutivesFail = (state, action) => {
  return updateObject(state, {
    smExecutives: [],
    smExecutive: null,
    smExecutivesLoading: false,
    smExecutivesFail: action.payload,
  });
};

const getSMManagersStart = (state, action) => {
  return updateObject(state, {
    smManagers: [],
    smManager: null,
    smManagersLoading: true,
    smManagersFail: null,
  });
};

const getSMManagersSuccess = (state, action) => {
  return updateObject(state, {
    smManagers: action.payload,
    smManagersLoading: false,
    smManagersFail: null,
    smManager: null,
  });
};

const getOneSMManagersSuccess = (state, action) => {
  return updateObject(state, {
    smManager: action.payload,
    smManagers: [],
    smManagersLoading: false,
    smManagersFail: null,
  });
};

const getSMManagersFail = (state, action) => {
  return updateObject(state, {
    smManagers: [],
    smManager: null,
    smManagersLoading: false,
    smManagersFail: action.payload,
  });
};

const getBDExecutivesStart = (state, action) => {
  return updateObject(state, {
    bdExecutives: [],
    bdExecutive: null,
    bdExecutivesLoading: true,
    bdExecutivesFail: null,
  });
};

const getBDExecutivesSuccess = (state, action) => {
  return updateObject(state, {
    bdExecutives: action.payload,
    bdExecutivesLoading: false,
    bdExecutivesFail: null,
    bdExecutive: null,
  });
};

const getOneBDExecutivesSuccess = (state, action) => {
  return updateObject(state, {
    bdExecutive: action.payload,
    bdExecutives: [],
    bdExecutivesLoading: false,
    bdExecutivesFail: null,
  });
};

const getBDExecutivesFail = (state, action) => {
  return updateObject(state, {
    bdExecutives: [],
    bdExecutive: null,
    bdExecutivesLoading: false,
    bdExecutivesFail: action.payload,
  });
};

const getBDManagersStart = (state, action) => {
  return updateObject(state, {
    bdManagers: [],
    bdManager: null,
    bdManagersLoading: true,
    bdManagersFail: null,
  });
};

const getBDManagersSuccess = (state, action) => {
  return updateObject(state, {
    bdManagers: action.payload,
    bdManagersLoading: false,
    bdManagersFail: null,
    bdManager: null,
  });
};

const getOneBDManagersSuccess = (state, action) => {
  return updateObject(state, {
    bdManager: action.payload,
    bdManagers: [],
    bdManagersLoading: false,
    bdManagersFail: null,
  });
};

const getBDManagersFail = (state, action) => {
  return updateObject(state, {
    bdManagers: [],
    bdManager: null,
    bdManagersLoading: false,
    bdManagersFail: action.payload,
  });
};

const reducer = (state = initialReducer, action) => {
  switch (action.type) {
    case GET_BACKGROUNDS_START:
      return getBackgroundsStart(state, action);
    case GET_BACKGROUNDS_SUCCESS:
      return getBackgroundsSuccess(state, action);
    case GET_ONE_BACKGROUNDS_SUCCESS:
      return getOneBackgroundsSuccess(state, action);
    case GET_BACKGROUNDS_FAIL:
      return getBackgroundsFail(state, action);
    case GET_MISSION_VISION_VALUES_START:
      return getMissionVisionValuesStart(state, action);
    case GET_MISSION_VISION_VALUES_SUCCESS:
      return getMissionVisionValuesSuccess(state, action);
    case GET_ONE_MISSION_VISION_VALUES_SUCCESS:
      return getOneMissionVisionValuesSuccess(state, action);
    case GET_MISSION_VISION_VALUES_FAIL:
      return getMissionVisionValuesFail(state, action);
    case GET_SM_EXECUTIVES_START:
      return getSMExecutivesStart(state, action);
    case GET_SM_EXECUTIVES_SUCCESS:
      return getSMExecutivesSuccess(state, action);
    case GET_ONE_SM_EXECUTIVES_SUCCESS:
      return getOneSMExecutivesSuccess(state, action);
    case GET_SM_EXECUTIVES_FAIL:
      return getSMExecutivesFail(state, action);
    case GET_SM_MANAGERS_START:
      return getSMManagersStart(state, action);
    case GET_SM_MANAGERS_SUCCESS:
      return getSMManagersSuccess(state, action);
    case GET_ONE_SM_MANAGERS_SUCCESS:
      return getOneSMManagersSuccess(state, action);
    case GET_SM_MANAGERS_FAIL:
      return getSMManagersFail(state, action);
    case GET_BD_EXECUTIVES_START:
      return getBDExecutivesStart(state, action);
    case GET_BD_EXECUTIVES_SUCCESS:
      return getBDExecutivesSuccess(state, action);
    case GET_ONE_BD_EXECUTIVES_SUCCESS:
      return getOneBDExecutivesSuccess(state, action);
    case GET_BD_EXECUTIVES_FAIL:
      return getBDExecutivesFail(state, action);
    case GET_BD_MANAGERS_START:
      return getBDManagersStart(state, action);
    case GET_BD_MANAGERS_SUCCESS:
      return getBDManagersSuccess(state, action);
    case GET_ONE_BD_MANAGERS_SUCCESS:
      return getOneBDManagersSuccess(state, action);
    case GET_BD_MANAGERS_FAIL:
      return getBDManagersFail(state, action);
    default:
      return state;
  }
};

export default reducer;
