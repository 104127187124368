import { useEffect, useState } from "react";
import { ScrollToTop } from "./components/backtotop";
import { Footer } from "./components/footer";
import Header from "./header";
import { useTranslation } from "react-i18next";
import { ServiceDeatils, TestimonialsComponent } from "./services/service";
import mySwiper from "./services/swiper";
import { useHistory } from "react-router-dom";
import * as actions from "./store/actions/";
import { useDispatch, useSelector } from "react-redux";

const Services = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [showServices, setShowServices] = useState(true);
  const services = useSelector((state) => state.services.services);
  const testimonials = useSelector((state) => state.services.texttestimonies);
  const language = useSelector((state) => state.homepage.language);

  const selectService = (val) => {
    history.push(`/service-page/${val}`);
  };

  useEffect(() => {
    dispatch(actions.getActiveServices(language));
    dispatch(actions.getActiveTextTestimonies(language));
    mySwiper();
  }, [showServices, language]);

  return (
    <div className="page-services">
      <Header />
      <main id="main">
        <div className="breadcrumbs d-flex align-items-center services-pg">
          <div className="container position-relative d-flex flex-column align-items-center">
            <h2>{t("Services")}</h2>
          </div>
        </div>
        <div className={showServices ? "d-none container" : "container"}>
          <span
            onClick={(e) => {
              e.preventDefault();
              setShowServices(!showServices);
            }}
            className="backbtn"
          >
            <i className="bi bi-arrow-left-short"></i>
            {t("Back To Services")}
          </span>
        </div>
        <section id="services-list" className="services-list">
          <div className={"container"} data-aos="fade-up">
            <div className="section-header">
              <h2>{t("Our Services")}</h2>
            </div>
            <div className="row gy-5">
              {services.map((service, index) => (
                <ServiceDeatils
                  key={index}
                  image={`${process.env.REACT_APP_BACKEND_URL}/${service?.image}`}
                  onClick={(e) => {
                    e.preventDefault();
                    selectService(service?.uuid);
                  }}
                  name={t(service?.name)}
                  description={service?.description}
                />
              ))}
            </div>
          </div>
        </section>
        <div className={"bg-light d-none"}>
          <TestimonialsComponent testimonials={testimonials} />
        </div>
      </main>
      <Footer />
      <ScrollToTop />
    </div>
  );
};

export default Services;
