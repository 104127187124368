import { updateObject } from "../../utils/utility";
import {
  GET_MESSAGES_START,
  GET_MESSAGES_SUCCESS,
  GET_ONE_MESSAGES_SUCCESS,
  GET_MESSAGES_FAIL,
} from "../actions/message";

const initialReducer = {
  message: null,
  messages: [],
  messagesLoading: false,
  messagesFail: null,
};

const getMessagesStart = (state, action) => {
  return updateObject(state, {
    messages: [],
    message: null,
    messagesLoading: true,
    messagesFail: null,
  });
};

const getMessagesSuccess = (state, action) => {
  return updateObject(state, {
    messages: action.payload,
    messagesLoading: false,
    messagesFail: null,
    message: null,
  });
};

const getOneMessagesSuccess = (state, action) => {
  return updateObject(state, {
    message: action.payload,
    messages: [],
    messagesLoading: false,
    messagesFail: null,
  });
};

const getMessagesFail = (state, action) => {
  return updateObject(state, {
    messages: [],
    message: null,
    messagesLoading: false,
    messagesFail: action.payload,
  });
};

const reducer = (state = initialReducer, action) => {
  switch (action.type) {
    case GET_MESSAGES_START:
      return getMessagesStart(state, action);
    case GET_MESSAGES_SUCCESS:
      return getMessagesSuccess(state, action);
    case GET_ONE_MESSAGES_SUCCESS:
      return getOneMessagesSuccess(state, action);
    case GET_MESSAGES_FAIL:
      return getMessagesFail(state, action);
    default:
      return state;
  }
};

export default reducer;
