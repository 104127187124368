import * as adminActionTypes from "../store/actions/adminIndex";
import { useDispatch } from "react-redux";
import { useEffect } from "react";

function separateTextAndFiles(givenId) {
  let id = "quill-editor";
  if (givenId !== "") {
    id = id + "-" + givenId;
  }
  let editorValue;
  var image_name;
  let files = [];
  const filesFolder = "${process.env.REACT_APP_BACKEND_URL}/site/bdffiles/";
  var objectivesquill = document
    .getElementById(id)
    .querySelector(".ql-editor").innerHTML;
  var objectivesquill_ = objectivesquill;
  var data_form = new FormData();
  var images = document.getElementById(id).getElementsByTagName("img");
  for (var i = 0; i < images.length; i++) {
    image_name = "img_" + new Date().getTime();
    image_name = image_name + ".jpg";
    //---
    let b64data = images[i].src;
    if (b64data.startsWith("data")) {
    } else {
      continue;
    }
    if (objectivesquill_) {
      objectivesquill_ = objectivesquill_.replaceAll(
        b64data,
        filesFolder + image_name
      );
    }
    let fileToUpload = dataURLtoFile(b64data, image_name);
    const fd = new FormData();
    fd.append("file", fileToUpload);
    files.push(fd);
  }
  editorValue = "`" + objectivesquill_ + "`";
  return { editorValue, files };
}

function dataURLtoFile(dataurl, filename) {
  let arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, {
    type: mime,
  });
}

export default {
  separateTextAndFiles,
};
