import { useEffect } from "react";
import CardHeader from "react-bootstrap/esm/CardHeader";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { TeamMemberSwipper } from "../about/swipper";
import { ScrollToTop } from "../components/backtotop";
import { Footer } from "../components/footer";
import { TabPanelExample } from "../components/TabPanelExample";
import Header from "../header";
import bgcard from "../images/bgcard.jpeg";
import { PublicationCard } from "./publicationcard";
import careerimg from "../images/bgz/carrer.jpg";
import tenderimg from "../images/bgz/tender.jpg";
import newsimg from "../images/bgz/news.jfif";
import reportimg from "../images/bgz/charts.jfif";
import environmentimg from "../images/bgz/environment.jpg";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../store/actions/";

export const Publications = function (props) {
  const dispatch = useDispatch();
  const language = useSelector((state) => state.homepage.language);
  const publications = useSelector((state) => state.publications.publications);
  const { t } = useTranslation();
  const publicationsi = [
    {
      type: "career",
      title: "Amatangazo y'akazi",
      image: careerimg,
      description:
        "Menya amakuru ajyanye n'imyanya y'akazi hamwe n'amanota y'abakoze ibizamini",
    },
    {
      type: "tender",
      title: "Amasoko",
      image: tenderimg,
      description:
        "Menya amakuru yibikenewe muri BDF binyuze mu masoko yatangajwe",
    },
    {
      type: "news",
      title: "Amakuru",
      image: newsimg,
      description:
        "Soma inkuru zitandukanye zanditwe kuri BDF n'ibikorwa ikora",
    },
    {
      type: "report",
      title: "Raporo",
      image: reportimg,
      description: "Soma Raporo zitandukanye za BDF",
    },
    {
      type: "other",
      title: "Amakuru ajyanye n'imishinga itangiza ibidukikije",
      image: environmentimg,
    },
  ];
  useEffect(() => {
    dispatch(actions.getActivePublications(language));
  }, [language]);
  return (
    <body className="page-about">
      <Header />
      <main id="main">
        <div className="breadcrumbs d-flex align-items-center publications-header-bg">
          <div className="container position-relative d-flex flex-column align-items-center">
            <h2>{t("Publications")}</h2>
          </div>
        </div>
        <section id="services-cards" class="services-cards">
          <div class="container" data-aos="fade-up">
            <div class="row gy-4">
              {publications?.map((publication, index) => (
                <PublicationCard publication={publication} key={index} />
              ))}
            </div>
          </div>
        </section>
      </main>
      <Footer />
      <ScrollToTop />
    </body>
  );
};
