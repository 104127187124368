import ReactQuill from "react-quill";
import classnames from "classnames";
import "react-quill/dist/quill.snow.css";

export const InputTextArea2nd = (props) => {
    let actualValue = false;
    let id = "quill-editor";
    if (props.id) {
      id = id + "-" + props.id;
    }
    if (props.data == "" && props.isClicked == true) {
      actualValue = true;
    }
    /*
     * Quill modules to attach to editor
     * See https://quilljs.com/docs/modules/ for complete options
     */
    const modules = {
      toolbar: [
        [{ header: "1" }, { header: "2" }, { font: [] }],
        [{ size: [] }],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [
          { list: "ordered" },
          { list: "bullet" },
          { indent: "-1" },
          { indent: "+1" },
        ],
        ["link", "image", "video"],
        ["clean"],
      ],
      clipboard: {
        // toggle to add extra line breaks when pasting HTML:
        matchVisual: false,
      },
    };
    /*
     * Quill editor formats
     * See https://quilljs.com/docs/formats/
     */
    const formats = [
      "header",
      "font",
      "size",
      "bold",
      "italic",
      "underline",
      "strike",
      "blockquote",
      "list",
      "bullet",
      "indent",
      "link",
      "image",
      "video",
    ];
  
    /*
     * PropType validation
     */
    const propTypes = {
      placeholder: "Write something",
    };
  
    let editorValue = "";
  
    return (
      <div id={id} className="flex flex-col pt-2">
        <label className="input-title">{props.title}</label>
        <div
          className={classnames("", { "border-2 border-red-500": actualValue })}
        >
          <ReactQuill
            theme="snow"
            editorHtml="html"
            value={props.data}
            onChange={props.onChange}
            modules={modules}
            formats={formats}
            placeholder={""}
          />
        </div>
        <label className="text-red-500">
          {actualValue === true ? props.title + " Can Not Be Null" : ""}
        </label>
      </div>
    );
  };