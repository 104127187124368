import { useEffect } from "react";

export const ScrollToTop = function (props) {
    const selectHeader = document.querySelector('#header');
    const scrollTop = document.querySelector('.scroll-top');
    useEffect(() => {
        if (selectHeader) {
            document.addEventListener('scroll', () => {
                window.scrollY > 100 ? selectHeader.classList.add('sticked') : selectHeader.classList.remove('sticked');
            });
            const togglescrollTop = function () {
                window.scrollY > 100 ? scrollTop.classList.add('active') : scrollTop.classList.remove('active');
            }
            window.addEventListener('load', togglescrollTop);
            document.addEventListener('scroll', togglescrollTop);
            scrollTop.addEventListener('click', window.scrollTo({
                top: 0,
                behavior: 'smooth'
            }));
        }
    })
    return (
        <a href="#" className="scroll-top d-flex align-items-center justify-content-center"><i className="bi bi-arrow-up-short"></i></a>
    );
};