import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { ScrollToTop } from "../components/backtotop";
import { Footer } from "../components/footer";
import MiSpinner from "../components/spinner";
import Header from "../header";
import guarantee from "../images/guarentee.png";
import sacco from "../images/sacco.png";
import agribusiness from "../images/agribusiness.png";
import leasing from "../images/leasing.png";
import grants from "../images/grants.png";
import advisory from "../images/advisory.png";
import mySwiper from "../services/swiper";
import { ServiceRequirement, TestimonialsComponent } from "./service";
import * as actions from "../store/actions/";
import { useDispatch, useSelector } from "react-redux";

const ServicePage = () => {
  const { t } = useTranslation();
  const [showServices, setShowServices] = useState(true);

  const service = useSelector((state) => state.services.service);
  // const [service, setService] = useState();
  const [classRow, setclassRow] = useState("row gy-5 justify-content-center");
  const param = useParams();
  const requirementsSize = service?.requirement?.length;
  if (requirementsSize > 1) {
    classRow = "";
  }
  const dispatch = useDispatch();
  useEffect(() => {
    const requirementsSize = service?.requirements?.length;
    if (requirementsSize <= 1) {
      setclassRow("d-flex justify-content-center");
    }
    dispatch(actions.getOneActiveService(param?.id));
  }, []);

  return (
    <div className="page-services">
      <Header />
      <main id="main">
        <div className="breadcrumbs d-flex align-items-center services-pg">
          <div className="container position-relative d-flex flex-column align-items-center">
            <h2>{t(service?.service?.name)}</h2>
          </div>
        </div>
        {service === undefined ? (
          <div>
            <MiSpinner />
          </div>
        ) : (
          <section id="services-list" className="services-list">
            <div className={"container"} data-aos="fade-up">
              <div
                className="col-lg-11 col-md-11 service-item d-flex flex-column"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <div className="row w-100">
                  <div className="text-center col-lg-4 col-md-4">
                    <img
                      className="service-img"
                      src={`${process.env.REACT_APP_BACKEND_URL}/${service?.service?.image}`}
                    ></img>
                    <h4 className="title text-center">
                      <a href="#" className="stretched-link">
                        {service?.service?.name}
                      </a>
                    </h4>
                  </div>
                  <div className="mt-3 col-lg-8 col-md-8">
                    <p className="description">
                      {service?.service?.description}
                    </p>
                    <p className="description">
                      {service?.service?.requirementsDescription}
                    </p>
                  </div>
                </div>
              </div>
              <div className={classRow}>
                {service?.requirements?.map((requirement, index) => (
                  <div className="mb-5 pt-5 col-lg-6" key={index}>
                    <div className="">
                      <h4 className="bdf-bluefontcolor-title">{requirement?.title}</h4>
                      <div >
                        <span className="description">{requirement?.briefsummary}</span>
                      </div>
                      <div className="row">
                        {requirement?.criteria?.map((service, indx) => (
                          <ServiceRequirement
                            key={indx}
                            index={indx + 1}
                            name={t(service?.criteriaName)}
                            description={service?.criteriaValues}
                          />
                        ))}
                      </div>
                      <div className="w-full d-none">
                        <a
                          target="_blanck"
                          href={`${process.env.REACT_APP_BACKEND_URL}/${requirement?.requirementsFile}`}
                        >
                          {t("Requirement File")}
                          <i className="ri-arrow-down-circle-line"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </section>
        )}
      </main>
      <Footer />
      <ScrollToTop />
    </div>
  );
};

export default ServicePage;
