import { useTranslation } from "react-i18next";
import { ViewMoreLink } from "../components/links";
import parse from "html-react-parser";

export const Service = function (props) {
  return (
    <div
      className="col-lg-4 col-md-6 service-item d-flex flex-column"
      data-aos="fade-up"
      data-aos-delay="100"
    >
      <div className="w-100 text-center ">
        <div>
          <img className="service-img" src={props.image}></img>
        </div>
        <div className="mt-3">
          <h4 className="title">
            <a href="#" className="stretched-link">
              {props.name}
            </a>
          </h4>
        </div>
      </div>
    </div>
  );
};

export const ServiceDeatils = function (props) {
  return (
    <div
      onClick={props.onClick}
      className="col-lg-6 col-md-6 service-item d-flex flex-column"
      data-aos="fade-up"
      data-aos-delay="100"
    >
      <div className="w-100">
        <div className="text-center">
          <img className="service-img" src={props.image}></img>
        </div>
        <div className="mt-3">
          <h4 className="title text-center">
            <a href="#" className="stretched-link">
              {props.name}
            </a>
          </h4>
          <p className="description">
            {props?.description}
            <ViewMoreLink onClick={props.onClick} />
          </p>
          <div className="text-center"></div>
        </div>
      </div>
    </div>
  );
};

export const ServiceRequirement = function (props) {
  return (
    <div
      className="service-item d-flex"
      data-aos="fade-up"
      data-aos-delay="100"
    >
      <div className="icon flex-shrink-0"></div>
      <div className="">
        <h4 className="title mt-3">
          <span className="bdf-bluefontcolor-title-2">
            {props?.index + ". " + props?.name}
          </span>
        </h4>
        {props?.description?.map((description, index) => (
          <div key={index} className="d-flex px-4">
            <i class="bi bi-check-circle-fill listing-icons me-2"></i>
            <span className="description">
              {description?.criteriaValue != undefined
                ? parse(eval(description?.criteriaValue))
                : parse("<p></p>")}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export const TestimonyCard = function (props) {
  const { t } = useTranslation();
  let card = [];
  let rate = props?.rate;
  while (rate > 0) {
    card.push(<i className="bi bi-star-fill" key={rate}></i>);
    rate--;
  }
  return (
    <div class="swiper-slide">
      <div class="testimonial-item">
        <div class="stars">{card}</div>
        <p>{props?.testimony}</p>
        <div class="profile mt-auto">
          <img src={props?.image} class="testimonial-img" alt="" />
          <h3>{props?.name}</h3>
          <h4>{props?.title + ", " + props?.company}</h4>
        </div>
      </div>
    </div>
  );
};

export const TestimonialsComponent = function (props) {
  const testimonials = props.testimonials;
  const { t } = useTranslation();
  return (
    <section id="testimonials" class="testimonials d-none">
      <div className="container" data-aos="fade-up">
        <div class="section-header">
          <h2>{t("Testimonials")}</h2>
        </div>
        <div class="slides-3 swiper" data-aos="fade-up" data-aos-delay="100">
          <div class="swiper-wrapper">
            {testimonials?.map((testimony, index) => (
              <TestimonyCard
                key={index}
                name={testimony?.name}
                rate={testimony?.rating}
                title={testimony?.title}
                company={testimony?.company}
                testimony={testimony?.testimony}
                image={`${process.env.REACT_APP_BACKEND_URL}/${testimony?.serviceimage}`}
              />
            ))}
          </div>
          <div class="swiper-pagination"></div>
        </div>
      </div>
    </section>
  );
};
