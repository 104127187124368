import { combineReducers } from "redux";
import homepageReducer from "../reducers/homepage";
import languagesReducer from "../reducers/languages";
import servicesReducer from "../reducers/services";
import aboutReducer from "../reducers/about";
import publicationReducer from "../reducers/publications";
import trainingReducer from "../reducers/training";
import messageReducer from "../reducers/message";
import authReducer from "../reducers/auth";
import adminHomeReducer from "../reducers/adminhomepage";
import smsReducer from "../reducers/sms";

export default combineReducers({
  homepage: homepageReducer,
  languages: languagesReducer,
  services: servicesReducer,
  about: aboutReducer,
  publications: publicationReducer,
  trainings: trainingReducer,
  messages: messageReducer,
  auth: authReducer,
  adminhomepage: adminHomeReducer,
  sms: smsReducer,
});
