
import Spinner from 'react-bootstrap/Spinner'

function MiSpinner(props) {
    return (
        <div className="text-center align-middle mi-spinner" >
            <Spinner animation="border" variant="primary" size="xl" />
        </div >
    );
}

export default MiSpinner;
