import axios from "../../axios-base";

export const GET_MESSAGES_SUCCESS = "GET_MESSAGES_SUCCESS";
export const GET_ONE_MESSAGES_SUCCESS = "GET_ONE_MESSAGES_SUCCESS";
export const GET_MESSAGES_START = "GET_MESSAGES_START";
export const GET_MESSAGES_FAIL = "GET_MESSAGES_FAIL";

export const getMessagesSuccess = (languages) => {
  return {
    type: GET_MESSAGES_SUCCESS,
    payload: languages,
  };
};

export const getOneMessagesSuccess = (language) => {
  return {
    type: GET_ONE_MESSAGES_SUCCESS,
    payload: language,
  };
};

export const getMessagesStart = () => {
  return {
    type: GET_MESSAGES_START,
  };
};
export const getMessagesFail = (error) => {
  return {
    type: GET_MESSAGES_FAIL,
    payload: error,
  };
};

export const getSendMessages = (data) => {
  return (dispatch) => {
    dispatch(getMessagesStart());
    axios
      .post(`/messages/send`, data)
      .then((response) => {
        dispatch(getOneMessagesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getMessagesFail(err));
      });
  };
};
