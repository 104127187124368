import { ApplicationLink, HeaderLink, HomeHeaderLink } from "./links";
import { SocialMedia } from "./socialmedia";
import { useTranslation } from "react-i18next";
import {
  TwitterTimelineEmbed,
  TwitterShareButton,
  TwitterFollowButton,
  TwitterHashtagButton,
  TwitterMentionButton,
  TwitterTweetEmbed,
  TwitterMomentShare,
  TwitterDMButton,
  TwitterVideoEmbed,
  TwitterOnAirButton,
} from "react-twitter-embed";
import { useSelector } from "react-redux";

export const Footer = function (props) {
  const { t } = useTranslation();
  const applicationLinks = useSelector(
    (state) => state.languages.applicationLinks
  );
  return (
    <footer id="footer" className="footer">
      <div className="footer-content">
        <div className="container">
          <div className="row gy-4">
            <div className="col-lg-4 col-md-12 footer-info">
              <HomeHeaderLink />
              <p>{t("Connect With Us")}</p>
              <div className="social-links d-flex  mt-3">
                <SocialMedia
                  type="twitter"
                  link={"https://twitter.com/BDF_rw"}
                />
              <SocialMedia type="facebook" link={"https://www.facebook.com/BusinessDF"} /> 
              <SocialMedia type="instagram" link={"https://www.instagram.com/bdf_rwanda/"} /> 
                <SocialMedia
                  type="youtube"
                  link={
                    "https://www.youtube.com/channel/UChmUaeVxXRYsHUkdD5YD31A"
                  }
                />
                {/*   <SocialMedia type="linkedin" /> */}
              </div>
              <br />
              <p>
                <strong>{t("Call")}:</strong> 4777,{" "}
                <strong>{t("Email")}:</strong> info@bdf.rw
                <br />
                <strong>{t("Location")}:</strong> {t("HQAddress")}
              </p>
            </div>

            <div className="col-lg-2 col-md-6 footer-links">
              <h6 className="text-white"><i class="bi bi-check-circle-fill listing-icons me-2 text-white"></i>{t("Useful Links")}</h6>
              <ul>
                <li>
                  <i className="bi bi-dash"></i>{" "}
                  <HeaderLink page="/" name={t("Home")} />
                </li>
                <li>
                  <i className="bi bi-dash"></i>{" "}
                  <HeaderLink page="/about" name={t("About")} />
                </li>
                <li>
                  <i className="bi bi-dash"></i>{" "}
                  <HeaderLink page="/services" name={t("Services")} />
                </li>
              </ul>
            </div>

            <div className="col-lg-2 col-md-6 footer-links">
              <h6 className="text-white"><i class="bi bi-check-circle-fill listing-icons me-2 text-white"></i>{t("About BDF")}</h6>
              <ul>
                <li>
                  <i className="bi bi-dash"></i>{" "}
                  <HeaderLink page="/publications" name={t("Careers")} />
                </li>
              </ul>
              <h6 className={applicationLinks?.length <= 0 ? "d-none" : "text-white"}><i class="bi bi-check-circle-fill listing-icons me-2 text-white"></i>{t("Apply")}</h6>
              <ul>
                {applicationLinks?.map((link, index) => (
                  <ApplicationLink name={link?.name} link={link?.link} />
                ))}
              </ul>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-12 footer-contact text-center text-md-start">
              <TwitterTimelineEmbed
                sourceType="profile"
                screenName="BDF_rw"
                options={{ height: 250 }}
              />
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
