import axios from "../../axios-base";

export const CHANGE_LANGUAGE = "CHANGE_LANGUAGE";
export const GET_HOME_SUCCESS = "GET_HOME_SUCCESS";
export const GET_ONE_HOME_SUCCESS = "GET_ONE_HOME_SUCCESS";
export const GET_HOME_START = "GET_HOME_START";
export const GET_HOME_FAIL = "GET_HOME_FAIL";
export const GET_HOME_ACHIEVEMENTS_SUCCESS = "GET_HOME_ACHIEVEMENTS_SUCCESS";
export const GET_ONE_HOME_ACHIEVEMENTS_SUCCESS =
  "GET_ONE_HOME_ACHIEVEMENTS_SUCCESS";
export const GET_HOME_ACHIEVEMENTS_START = "GET_HOME_ACHIEVEMENTS_START";
export const GET_HOME_ACHIEVEMENTS_FAIL = "GET_HOME_ACHIEVEMENTS_FAIL";
export const GET_HOME_ACHIEVEMENT_TITLES_SUCCESS =
  "GET_HOME_ACHIEVEMENT_TITLES_SUCCESS";
export const GET_ONE_HOME_ACHIEVEMENT_TITLES_SUCCESS =
  "GET_ONE_HOME_ACHIEVEMENT_TITLES_SUCCESS";
export const GET_HOME_ACHIEVEMENT_TITLES_START =
  "GET_HOME_ACHIEVEMENT_TITLES_START";
export const GET_HOME_ACHIEVEMENT_TITLES_FAIL =
  "GET_HOME_ACHIEVEMENT_TITLES_FAIL";
export const GET_HOME_REQUEST_SERVICES_SUCCESS =
  "GET_HOME_REQUEST_SERVICES_SUCCESS";
export const GET_ONE_HOME_REQUEST_SERVICES_SUCCESS =
  "GET_ONE_HOME_REQUEST_SERVICES_SUCCESS";
export const GET_HOME_REQUEST_SERVICES_START =
  "GET_HOME_REQUEST_SERVICES_START";
export const GET_HOME_REQUEST_SERVICES_FAIL = "GET_HOME_REQUEST_SERVICES_FAIL";
export const GET_PARTNERS_SUCCESS = "GET_PARTNERS_SUCCESS";
export const GET_ONE_PARTNERS_SUCCESS = "GET_ONE_PARTNERS_SUCCESS";
export const GET_PARTNERS_START = "GET_PARTNERS_START";
export const GET_PARTNERS_FAIL = "GET_PARTNERS_FAIL";

export const changeLanguage = (lang) => {
  return {
    type: CHANGE_LANGUAGE,
    language: lang,
  };
};

export const getHomesSuccess = (data) => {
  return {
    type: GET_HOME_SUCCESS,
    payload: data,
  };
};

export const getOneHomesSuccess = (data) => {
  return {
    type: GET_ONE_HOME_SUCCESS,
    payload: data,
  };
};

export const getHomesStart = () => {
  return {
    type: GET_HOME_START,
  };
};
export const getHomesFail = (error) => {
  return {
    type: GET_HOME_FAIL,
    payload: error,
  };
};

export const getActiveHomes = (lang) => {
  return (dispatch) => {
    dispatch(getHomesStart());
    axios
      .get(`/homes/active/${lang}`)
      .then((response) => {
        dispatch(getOneHomesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getHomesFail(err));
      });
  };
};

export const createHomes = (object, token) => {
  return (dispatch) => {
    dispatch(getHomesStart());
    axios
      .post(`/homes/create`, object, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOneHomesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getHomesFail(err));
      });
  };
};

export const getHomes = () => {
  return (dispatch) => {
    dispatch(getHomesStart());
    axios
      .get(`/homes`)
      .then((response) => {
        dispatch(getHomesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getHomesFail(err));
      });
  };
};

export const getOneHomes = (token, id) => {
  return (dispatch) => {
    dispatch(getHomesStart());
    axios
      .get(`/homes/find/${id}`, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOneHomesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getHomesFail(err));
      });
  };
};

export const activatingHomes = (token, id) => {
  return (dispatch) => {
    dispatch(getHomesStart());
    axios
      .put(`/homes/activating/${id}`, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOneHomesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getHomesFail(err));
      });
  };
};

export const desActivatingHomes = (token, id) => {
  return (dispatch) => {
    dispatch(getHomesStart());
    axios
      .put(`/homes/desactivating/${id}`, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOneHomesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getHomesFail(err));
      });
  };
};

export const updateHomes = (token, object) => {
  return (dispatch) => {
    dispatch(getHomesStart());
    axios
      .put(`/homes/edit/${object?.id}`, object, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOneHomesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getHomesFail(err));
      });
  };
};

export const getHomeAchievementsSuccess = (data) => {
  return {
    type: GET_HOME_ACHIEVEMENTS_SUCCESS,
    payload: data,
  };
};

export const getOneHomeAchievementsSuccess = (data) => {
  return {
    type: GET_ONE_HOME_ACHIEVEMENTS_SUCCESS,
    payload: data,
  };
};

export const getHomeAchievementsStart = () => {
  return {
    type: GET_HOME_ACHIEVEMENTS_START,
  };
};
export const getHomeAchievementsFail = (error) => {
  return {
    type: GET_HOME_ACHIEVEMENTS_FAIL,
    payload: error,
  };
};

export const getActiveHomeAchievements = (lang) => {
  return (dispatch) => {
    dispatch(getHomeAchievementsStart());
    axios
      .get(`/home-achievements/active/${lang}`)
      .then((response) => {
        dispatch(getHomeAchievementsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getHomeAchievementsFail(err));
      });
  };
};

export const createHomeAchievements = (object, token) => {
  return (dispatch) => {
    dispatch(getHomeAchievementsStart());
    axios
      .post(`/home-achievements/create`, object, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOneHomeAchievementsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getHomeAchievementsFail(err));
      });
  };
};

export const getHomeAchievements = () => {
  return (dispatch) => {
    dispatch(getHomeAchievementsStart());
    axios
      .get(`/home-achievements`)
      .then((response) => {
        dispatch(getHomeAchievementsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getHomeAchievementsFail(err));
      });
  };
};

export const getOneHomeAchievements = (token, id) => {
  return (dispatch) => {
    dispatch(getHomeAchievementsStart());
    axios
      .get(`/home-achievements/find/${id}`, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOneHomeAchievementsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getHomeAchievementsFail(err));
      });
  };
};

export const activatingHomeAchievements = (token, id) => {
  return (dispatch) => {
    dispatch(getHomeAchievementsStart());
    axios
      .put(`/home-achievements/activating/${id}`, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOneHomeAchievementsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getHomeAchievementsFail(err));
      });
  };
};

export const desActivatingHomeAchievements = (token, id) => {
  return (dispatch) => {
    dispatch(getHomeAchievementsStart());
    axios
      .put(`/home-achievements/desactivating/${id}`, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOneHomeAchievementsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getHomeAchievementsFail(err));
      });
  };
};

export const updateHomeAchievements = (token, object) => {
  return (dispatch) => {
    dispatch(getHomeAchievementsStart());
    axios
      .put(`/home-achievements/edit/${object?.id}`, object, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOneHomeAchievementsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getHomeAchievementsFail(err));
      });
  };
};

export const getHomeAchievementTitlesSuccess = (data) => {
  return {
    type: GET_HOME_ACHIEVEMENT_TITLES_SUCCESS,
    payload: data,
  };
};

export const getOneHomeAchievementTitlesSuccess = (data) => {
  return {
    type: GET_ONE_HOME_ACHIEVEMENT_TITLES_SUCCESS,
    payload: data,
  };
};

export const getHomeAchievementTitlesStart = () => {
  return {
    type: GET_HOME_ACHIEVEMENT_TITLES_START,
  };
};
export const getHomeAchievementTitlesFail = (error) => {
  return {
    type: GET_HOME_ACHIEVEMENT_TITLES_FAIL,
    payload: error,
  };
};

export const getActiveHomeAchievementTitles = (lang) => {
  return (dispatch) => {
    dispatch(getHomeAchievementTitlesStart());
    axios
      .get(`/home-achivment-titles/active/${lang}`)
      .then((response) => {
        dispatch(getOneHomeAchievementTitlesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getHomeAchievementTitlesFail(err));
      });
  };
};

export const createHomeAchievementTitles = (object, token) => {
  return (dispatch) => {
    dispatch(getHomeAchievementTitlesStart());
    axios
      .post(`/home-achivment-titles/create`, object, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOneHomeAchievementTitlesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getHomeAchievementTitlesFail(err));
      });
  };
};

export const getHomeAchievementTitles = () => {
  return (dispatch) => {
    dispatch(getHomeAchievementTitlesStart());
    axios
      .get(`/home-achivment-titles`)
      .then((response) => {
        dispatch(getHomeAchievementTitlesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getHomeAchievementTitlesFail(err));
      });
  };
};

export const getOneHomeAchievementTitles = (token, id) => {
  return (dispatch) => {
    dispatch(getHomeAchievementTitlesStart());
    axios
      .get(`/home-achivment-titles/find/${id}`, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOneHomeAchievementTitlesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getHomeAchievementTitlesFail(err));
      });
  };
};

export const activatingHomeAchievementTitles = (token, id) => {
  return (dispatch) => {
    dispatch(getHomeAchievementTitlesStart());
    axios
      .put(`/home-achivment-titles/activating/${id}`, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOneHomeAchievementTitlesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getHomeAchievementTitlesFail(err));
      });
  };
};

export const desActivatingHomeAchievementTitles = (token, id) => {
  return (dispatch) => {
    dispatch(getHomeAchievementTitlesStart());
    axios
      .put(`/home-achivment-titles/desactivating/${id}`, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOneHomeAchievementTitlesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getHomeAchievementTitlesFail(err));
      });
  };
};

export const updateHomeAchievementTitles = (token, object) => {
  return (dispatch) => {
    dispatch(getHomeAchievementTitlesStart());
    axios
      .put(`/home-achivment-titles/edit/${object?.id}`, object, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOneHomeAchievementTitlesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getHomeAchievementTitlesFail(err));
      });
  };
};

export const getHomeRequestServicesSuccess = (data) => {
  return {
    type: GET_HOME_REQUEST_SERVICES_SUCCESS,
    payload: data,
  };
};

export const getOneHomeRequestServicesSuccess = (data) => {
  return {
    type: GET_ONE_HOME_REQUEST_SERVICES_SUCCESS,
    payload: data,
  };
};

export const getHomeRequestServicesStart = () => {
  return {
    type: GET_HOME_REQUEST_SERVICES_START,
  };
};
export const getHomeRequestServicesFail = (error) => {
  return {
    type: GET_HOME_REQUEST_SERVICES_FAIL,
    payload: error,
  };
};

export const getActiveHomeRequestServices = (lang) => {
  return (dispatch) => {
    dispatch(getHomeRequestServicesStart());
    axios
      .get(`/home-request-services/active/${lang}`)
      .then((response) => {
        dispatch(getOneHomeRequestServicesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getHomeRequestServicesFail(err));
      });
  };
};

export const getPartnersSuccess = (data) => {
  return {
    type: GET_PARTNERS_SUCCESS,
    payload: data,
  };
};

export const getOnePartnersSuccess = (data) => {
  return {
    type: GET_ONE_PARTNERS_SUCCESS,
    payload: data,
  };
};

export const getPartnersStart = () => {
  return {
    type: GET_PARTNERS_START,
  };
};
export const getPartnersFail = (error) => {
  return {
    type: GET_PARTNERS_FAIL,
    payload: error,
  };
};

export const getActivePartners = () => {
  return (dispatch) => {
    dispatch(getPartnersStart());
    axios
      .get(`/partners/active`)
      .then((response) => {
        dispatch(getPartnersSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getPartnersFail(err));
      });
  };
};

export const createPartners = (object, token) => {
  return (dispatch) => {
    dispatch(getPartnersStart());
    axios
      .post(`/partners/create`, object, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOnePartnersSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getPartnersFail(err));
      });
  };
};

export const getPartners = () => {
  return (dispatch) => {
    dispatch(getPartnersStart());
    axios
      .get(`/partners`)
      .then((response) => {
        dispatch(getPartnersSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getPartnersFail(err));
      });
  };
};

export const getOnePartners = (token, id) => {
  return (dispatch) => {
    dispatch(getPartnersStart());
    axios
      .get(`/partners/find/${id}`, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOnePartnersSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getPartnersFail(err));
      });
  };
};

export const activatingPartners = (token, id) => {
  return (dispatch) => {
    dispatch(getPartnersStart());
    axios
      .put(`/partners/activating/${id}`, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOnePartnersSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getPartnersFail(err));
      });
  };
};

export const desActivatingPartners = (token, id) => {
  return (dispatch) => {
    dispatch(getPartnersStart());
    axios
      .put(`/partners/desactivating/${id}`, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOnePartnersSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getPartnersFail(err));
      });
  };
};

export const updatePartners = (token, object) => {
  return (dispatch) => {
    dispatch(getPartnersStart());
    axios
      .put(`/partners/edit/${object?.id}`, object, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOnePartnersSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getPartnersFail(err));
      });
  };
};
