import axios from "../../axios-base";

export const GET_PUBLICATIONS_SUCCESS = "GET_PUBLICATIONS_SUCCESS";
export const GET_ONE_PUBLICATIONS_SUCCESS = "GET_ONE_PUBLICATIONS_SUCCESS";
export const GET_PUBLICATIONS_START = "GET_PUBLICATIONS_START";
export const GET_PUBLICATIONS_FAIL = "GET_PUBLICATIONS_FAIL";
export const GET_PUBLICATION_CONTENTS_SUCCESS =
  "GET_PUBLICATION_CONTENTS_SUCCESS";
export const GET_ONE_PUBLICATION_CONTENTS_SUCCESS =
  "GET_ONE_PUBLICATION_CONTENTS_SUCCESS";
export const GET_PUBLICATION_CONTENTS_START = "GET_PUBLICATION_CONTENTS_START";
export const GET_PUBLICATION_CONTENTS_FAIL = "GET_PUBLICATION_CONTENTS_FAIL";

export const getPublicationsSuccess = (data) => {
  return {
    type: GET_PUBLICATIONS_SUCCESS,
    payload: data,
  };
};

export const getOnePublicationsSuccess = (data) => {
  return {
    type: GET_ONE_PUBLICATIONS_SUCCESS,
    payload: data,
  };
};

export const getPublicationsStart = () => {
  return {
    type: GET_PUBLICATIONS_START,
  };
};
export const getPublicationsFail = (error) => {
  return {
    type: GET_PUBLICATIONS_FAIL,
    payload: error,
  };
};

export const getActivePublications = (lang) => {
  return (dispatch) => {
    dispatch(getPublicationsStart());
    axios
      .get(`/publications/active/${lang}`)
      .then((response) => {
        dispatch(getPublicationsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getPublicationsFail(err));
      });
  };
};

export const createPublications = (object, token) => {
  return (dispatch) => {
    dispatch(getPublicationsStart());
    axios
      .post(`/publications/create`, object, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOnePublicationsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getPublicationsFail(err));
      });
  };
};

export const getPublications = () => {
  return (dispatch) => {
    dispatch(getPublicationsStart());
    axios
      .get(`/publications`)
      .then((response) => {
        dispatch(getPublicationsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getPublicationsFail(err));
      });
  };
};

export const getOnePublications = (token, id) => {
  return (dispatch) => {
    dispatch(getPublicationsStart());
    axios
      .get(`/publications/find/${id}`, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOnePublicationsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getPublicationsFail(err));
      });
  };
};

export const activatingPublications = (token, id) => {
  return (dispatch) => {
    dispatch(getPublicationsStart());
    axios
      .put(`/publications/activating/${id}`, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOnePublicationsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getPublicationsFail(err));
      });
  };
};

export const desActivatingPublications = (token, id) => {
  return (dispatch) => {
    dispatch(getPublicationsStart());
    axios
      .put(`/publications/desactivating/${id}`, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOnePublicationsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getPublicationsFail(err));
      });
  };
};

export const updatePublications = (token, object) => {
  return (dispatch) => {
    dispatch(getPublicationsStart());
    axios
      .put(`/publications/edit/${object?.id}`, object, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOnePublicationsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getPublicationsFail(err));
      });
  };
};

export const getPublicationContentsSuccess = (data) => {
  return {
    type: GET_PUBLICATION_CONTENTS_SUCCESS,
    payload: data,
  };
};

export const getOnePublicationContentsSuccess = (data) => {
  return {
    type: GET_ONE_PUBLICATION_CONTENTS_SUCCESS,
    payload: data,
  };
};

export const getPublicationContentsStart = () => {
  return {
    type: GET_PUBLICATION_CONTENTS_START,
  };
};
export const getPublicationContentsFail = (error) => {
  return {
    type: GET_PUBLICATION_CONTENTS_FAIL,
    payload: error,
  };
};

export const getActivePublicationContents = (id) => {
  return (dispatch) => {
    dispatch(getPublicationContentsStart());
    axios
      .get(`/publication-contents/id/active/${id}`)
      .then((response) => {
        dispatch(getPublicationContentsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getPublicationContentsFail(err));
      });
  };
};

export const getPublicationContents = (token, id) => {
  return (dispatch) => {
    dispatch(getPublicationContentsStart());
    axios
      .get(`/publication-contents/id/${id}`, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getPublicationContentsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getPublicationContentsFail(err));
      });
  };
};

export const createPublicationContents = (object, token) => {
  console.log(object);
  return (dispatch) => {
    dispatch(getPublicationContentsStart());
    axios
      .post(`/publication-contents/create`, object, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOnePublicationContentsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getPublicationContentsFail(err));
      });
  };
};

export const getOnePublicationContents = (token, id) => {
  return (dispatch) => {
    dispatch(getPublicationContentsStart());
    axios
      .get(`/publication-contents/find/${id}`, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOnePublicationContentsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getPublicationContentsFail(err));
      });
  };
};

export const activatingPublicationContents = (token, id) => {
  return (dispatch) => {
    dispatch(getPublicationContentsStart());
    axios
      .put(`/publication-contents/activating/${id}`, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOnePublicationContentsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getPublicationContentsFail(err));
      });
  };
};

export const desActivatingPublicationContents = (token, id) => {
  return (dispatch) => {
    dispatch(getPublicationContentsStart());
    axios
      .put(`/publication-contents/desactivating/${id}`, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOnePublicationContentsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getPublicationContentsFail(err));
      });
  };
};

export const updatePublicationContents = (token, object) => {
  return (dispatch) => {
    dispatch(getPublicationContentsStart());
    axios
      .put(`/publication-contents/edit/${object?.id}`, object, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOnePublicationContentsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getPublicationContentsFail(err));
      });
  };
};

export const getActiveCareers = (lang) => {
  return (dispatch) => {
    dispatch(getPublicationContentsStart());
    axios
      .get(`/publication-contents/careers/active/${lang}`)
      .then((response) => {
        dispatch(getPublicationContentsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getPublicationContentsFail(err));
      });
  };
};
