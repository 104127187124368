import axios from "../../axios-base";

export const GET_SERVICES_SUCCESS = "GET_SERVICES_SUCCESS";
export const GET_ONE_SERVICES_SUCCESS = "GET_ONE_SERVICES_SUCCESS";
export const GET_SERVICES_START = "GET_SERVICES_START";
export const GET_SERVICES_FAIL = "GET_SERVICES_FAIL";
export const GET_TEXT_TESTIMONIES_SUCCESS = "GET_TEXT_TESTIMONIES_SUCCESS";
export const GET_ONE_TEXT_TESTIMONIES_SUCCESS =
  "GET_ONE_TEXT_TESTIMONIES_SUCCESS";
export const GET_TEXT_TESTIMONIES_START = "GET_TEXT_TESTIMONIES_START";
export const GET_TEXT_TESTIMONIES_FAIL = "GET_TEXT_TESTIMONIES_FAIL";
export const GET_VIDEO_TESTIMONIES_SUCCESS = "GET_VIDEO_TESTIMONIES_SUCCESS";
export const GET_ONE_VIDEO_TESTIMONIES_SUCCESS =
  "GET_ONE_VIDEO_TESTIMONIES_SUCCESS";
export const GET_VIDEO_TESTIMONIES_START = "GET_VIDEO_TESTIMONIES_START";
export const GET_VIDEO_TESTIMONIES_FAIL = "GET_VIDEO_TESTIMONIES_FAIL";
export const GET_SERVICE_REQUIREMENTS_SUCCESS =
  "GET_SERVICE_REQUIREMENTS_SUCCESS";
export const GET_ONE_SERVICE_REQUIREMENTS_SUCCESS =
  "GET_ONE_SERVICE_REQUIREMENTS_SUCCESS";
export const GET_SERVICE_REQUIREMENTS_START = "GET_SERVICE_REQUIREMENTS_START";
export const GET_SERVICE_REQUIREMENTS_FAIL = "GET_SERVICE_REQUIREMENTS_FAIL";
export const GET_REQUIREMENT_CRITERIA_SUCCESS =
  "GET_REQUIREMENT_CRITERIA_SUCCESS";
export const GET_ONE_REQUIREMENT_CRITERIA_SUCCESS =
  "GET_ONE_REQUIREMENT_CRITERIA_SUCCESS";
export const GET_REQUIREMENT_CRITERIA_START = "GET_REQUIREMENT_CRITERIA_START";
export const GET_REQUIREMENT_CRITERIA_FAIL = "GET_REQUIREMENT_CRITERIA_FAIL";
export const GET_CRITERIA_VALUES_SUCCESS = "GET_CRITERIA_VALUES_SUCCESS";
export const GET_ONE_CRITERIA_VALUES_SUCCESS =
  "GET_ONE_CRITERIA_VALUES_SUCCESS";
export const GET_CRITERIA_VALUES_START = "GET_CRITERIA_VALUES_START";
export const GET_CRITERIA_VALUES_FAIL = "GET_CRITERIA_VALUES_FAIL";

export const getServicesSuccess = (data) => {
  return {
    type: GET_SERVICES_SUCCESS,
    payload: data,
  };
};

export const getOneServicesSuccess = (data) => {
  return {
    type: GET_ONE_SERVICES_SUCCESS,
    payload: data,
  };
};

export const getServicesStart = () => {
  return {
    type: GET_SERVICES_START,
  };
};
export const getServicesFail = (error) => {
  return {
    type: GET_SERVICES_FAIL,
    payload: error,
  };
};

export const getActiveServices = (lang) => {
  return (dispatch) => {
    dispatch(getServicesStart());
    axios
      .get(`/services/active/${lang}`)
      .then((response) => {
        dispatch(getServicesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getServicesFail(err));
      });
  };
};

export const getServices = () => {
  return (dispatch) => {
    dispatch(getServicesStart());
    axios
      .get(`/services`)
      .then((response) => {
        dispatch(getServicesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getServicesFail(err));
      });
  };
};

export const getServicesLanguage = () => {
  return (dispatch) => {
    dispatch(getServicesStart());
    axios
      .get(`/services/language`)
      .then((response) => {
        dispatch(getServicesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getServicesFail(err));
      });
  };
};

export const createServices = (object, token) => {
  return (dispatch) => {
    dispatch(getServicesStart());
    axios
      .post(`/services/create`, object, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOneServicesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getServicesFail(err));
      });
  };
};

export const getOneServices = (token, id) => {
  return (dispatch) => {
    dispatch(getServicesStart());
    axios
      .get(`/services/find/${id}`, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOneServicesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getServicesFail(err));
      });
  };
};

export const activatingServices = (token, id) => {
  return (dispatch) => {
    dispatch(getServicesStart());
    axios
      .put(`/services/activating/${id}`, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOneServicesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getServicesFail(err));
      });
  };
};

export const desActivatingServices = (token, id) => {
  return (dispatch) => {
    dispatch(getServicesStart());
    axios
      .put(`/services/desactivating/${id}`, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOneServicesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getServicesFail(err));
      });
  };
};

export const updateServices = (token, object) => {
  return (dispatch) => {
    dispatch(getServicesStart());
    axios
      .put(`/services/edit/${object?.id}`, object, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOneServicesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getServicesFail(err));
      });
  };
};

export const getTextTestimoniesSuccess = (data) => {
  return {
    type: GET_TEXT_TESTIMONIES_SUCCESS,
    payload: data,
  };
};

export const getOneTextTestimoniesSuccess = (data) => {
  return {
    type: GET_ONE_TEXT_TESTIMONIES_SUCCESS,
    payload: data,
  };
};

export const getTextTestimoniesStart = () => {
  return {
    type: GET_TEXT_TESTIMONIES_START,
  };
};
export const getTextTestimoniesFail = (error) => {
  return {
    type: GET_TEXT_TESTIMONIES_FAIL,
    payload: error,
  };
};

export const getActiveTextTestimonies = (lang) => {
  return (dispatch) => {
    dispatch(getTextTestimoniesStart());
    axios
      .get(`/word-testimonies/active/${lang}`)
      .then((response) => {
        dispatch(getTextTestimoniesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getTextTestimoniesFail(err));
      });
  };
};

export const createTextTestimonies = (object, token) => {
  return (dispatch) => {
    dispatch(getTextTestimoniesStart());
    axios
      .post(`/word-testimonies/create`, object, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOneTextTestimoniesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getTextTestimoniesFail(err));
      });
  };
};

export const getTextTestimonies = () => {
  return (dispatch) => {
    dispatch(getTextTestimoniesStart());
    axios
      .get(`/word-testimonies`)
      .then((response) => {
        dispatch(getTextTestimoniesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getTextTestimoniesFail(err));
      });
  };
};

export const getOneTextTestimonies = (token, id) => {
  return (dispatch) => {
    dispatch(getTextTestimoniesStart());
    axios
      .get(`/word-testimonies/find/${id}`, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOneTextTestimoniesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getTextTestimoniesFail(err));
      });
  };
};

export const activatingTextTestimonies = (token, id) => {
  return (dispatch) => {
    dispatch(getTextTestimoniesStart());
    axios
      .put(`/word-testimonies/activating/${id}`, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOneTextTestimoniesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getTextTestimoniesFail(err));
      });
  };
};

export const desActivatingTextTestimonies = (token, id) => {
  return (dispatch) => {
    dispatch(getTextTestimoniesStart());
    axios
      .put(`/word-testimonies/desactivating/${id}`, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOneTextTestimoniesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getTextTestimoniesFail(err));
      });
  };
};

export const updateTextTestimonies = (token, object) => {
  return (dispatch) => {
    dispatch(getTextTestimoniesStart());
    axios
      .put(`/word-testimonies/edit/${object?.id}`, object, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOneTextTestimoniesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getTextTestimoniesFail(err));
      });
  };
};

export const getOneActiveService = (serviceId) => {
  return (dispatch) => {
    dispatch(getServicesStart());
    axios
      .get(`/services/find-one-active/${serviceId}`)
      .then((response) => {
        dispatch(getOneServicesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getServicesFail(err));
      });
  };
};

export const getHomeServices = (lang) => {
  return (dispatch) => {
    dispatch(getTextTestimoniesStart());
    axios
      .get(`/services/home-services/${lang}`)
      .then((response) => {
        dispatch(getServicesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getTextTestimoniesFail(err));
      });
  };
};

export const getVideoTestimoniesSuccess = (data) => {
  return {
    type: GET_VIDEO_TESTIMONIES_SUCCESS,
    payload: data,
  };
};

export const getOneVideoTestimoniesSuccess = (data) => {
  return {
    type: GET_ONE_VIDEO_TESTIMONIES_SUCCESS,
    payload: data,
  };
};

export const getVideoTestimoniesStart = () => {
  return {
    type: GET_VIDEO_TESTIMONIES_START,
  };
};
export const getVideoTestimoniesFail = (error) => {
  return {
    type: GET_VIDEO_TESTIMONIES_FAIL,
    payload: error,
  };
};

export const getActiveVideoTestimonies = () => {
  return (dispatch) => {
    dispatch(getVideoTestimoniesStart());
    axios
      .get(`/video-testimonies/active`)
      .then((response) => {
        dispatch(getVideoTestimoniesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getVideoTestimoniesFail(err));
      });
  };
};

export const createVideoTestimonies = (object, token) => {
  return (dispatch) => {
    dispatch(getVideoTestimoniesStart());
    axios
      .post(`/video-testimonies/create`, object, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOneVideoTestimoniesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getVideoTestimoniesFail(err));
      });
  };
};

export const getVideoTestimonies = () => {
  return (dispatch) => {
    dispatch(getVideoTestimoniesStart());
    axios
      .get(`/video-testimonies`)
      .then((response) => {
        dispatch(getVideoTestimoniesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getVideoTestimoniesFail(err));
      });
  };
};

export const getOneVideoTestimonies = (token, id) => {
  return (dispatch) => {
    dispatch(getVideoTestimoniesStart());
    axios
      .get(`/video-testimonies/find/${id}`, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOneVideoTestimoniesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getVideoTestimoniesFail(err));
      });
  };
};

export const activatingVideoTestimonies = (token, id) => {
  return (dispatch) => {
    dispatch(getVideoTestimoniesStart());
    axios
      .put(`/video-testimonies/activating/${id}`, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOneVideoTestimoniesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getVideoTestimoniesFail(err));
      });
  };
};

export const desActivatingVideoTestimonies = (token, id) => {
  return (dispatch) => {
    dispatch(getVideoTestimoniesStart());
    axios
      .put(`/video-testimonies/desactivating/${id}`, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOneVideoTestimoniesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getVideoTestimoniesFail(err));
      });
  };
};

export const updateVideoTestimonies = (token, object) => {
  return (dispatch) => {
    dispatch(getVideoTestimoniesStart());
    axios
      .put(`/video-testimonies/edit/${object?.id}`, object, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOneVideoTestimoniesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getVideoTestimoniesFail(err));
      });
  };
};

export const getServiceRequirementsSuccess = (data) => {
  return {
    type: GET_SERVICE_REQUIREMENTS_SUCCESS,
    payload: data,
  };
};

export const getOneServiceRequirementsSuccess = (data) => {
  return {
    type: GET_ONE_SERVICE_REQUIREMENTS_SUCCESS,
    payload: data,
  };
};

export const getServiceRequirementsStart = () => {
  return {
    type: GET_SERVICE_REQUIREMENTS_START,
  };
};
export const getServiceRequirementsFail = (error) => {
  return {
    type: GET_SERVICE_REQUIREMENTS_FAIL,
    payload: error,
  };
};

export const createServiceRequirements = (object, token) => {
  return (dispatch) => {
    dispatch(getServiceRequirementsStart());
    axios
      .post(`/service-requirements/create`, object, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOneServiceRequirementsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getServiceRequirementsFail(err));
      });
  };
};

export const getServiceRequirements = (token, id) => {
  return (dispatch) => {
    dispatch(getServiceRequirementsStart());
    axios
      .get(`/service-requirements/findby-parent/${id}`, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getServiceRequirementsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getServiceRequirementsFail(err));
      });
  };
};

export const getOneServiceRequirements = (token, id) => {
  return (dispatch) => {
    dispatch(getServiceRequirementsStart());
    axios
      .get(`/service-requirements/find/${id}`, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOneServiceRequirementsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getServiceRequirementsFail(err));
      });
  };
};

export const activatingServiceRequirements = (token, id) => {
  return (dispatch) => {
    dispatch(getServiceRequirementsStart());
    axios
      .put(`/service-requirements/activating/${id}`, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOneServiceRequirementsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getServiceRequirementsFail(err));
      });
  };
};

export const desActivatingServiceRequirements = (token, id) => {
  return (dispatch) => {
    dispatch(getServiceRequirementsStart());
    axios
      .put(`/service-requirements/desactivating/${id}`, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOneServiceRequirementsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getServiceRequirementsFail(err));
      });
  };
};

export const updateServiceRequirements = (token, object) => {
  return (dispatch) => {
    dispatch(getServiceRequirementsStart());
    axios
      .put(`/service-requirements/edit/${object?.id}`, object, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOneServiceRequirementsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getServiceRequirementsFail(err));
      });
  };
};

export const getRequirementsCriteriaSuccess = (data) => {
  return {
    type: GET_REQUIREMENT_CRITERIA_SUCCESS,
    payload: data,
  };
};

export const getOneRequirementsCriteriaSuccess = (data) => {
  return {
    type: GET_ONE_REQUIREMENT_CRITERIA_SUCCESS,
    payload: data,
  };
};

export const getRequirementsCriteriaStart = () => {
  return {
    type: GET_REQUIREMENT_CRITERIA_START,
  };
};
export const getRequirementsCriteriaFail = (error) => {
  return {
    type: GET_REQUIREMENT_CRITERIA_FAIL,
    payload: error,
  };
};

export const createRequirementsCriteria = (object, token) => {
  return (dispatch) => {
    dispatch(getRequirementsCriteriaStart());
    axios
      .post(`/service-requirement-criteria/create`, object, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOneRequirementsCriteriaSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getRequirementsCriteriaFail(err));
      });
  };
};

export const getRequirementsCriteria = (token, id) => {
  return (dispatch) => {
    dispatch(getRequirementsCriteriaStart());
    axios
      .get(`/service-requirement-criteria/findby-parent/${id}`, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getRequirementsCriteriaSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getRequirementsCriteriaFail(err));
      });
  };
};

export const getOneRequirementsCriteria = (token, id) => {
  return (dispatch) => {
    dispatch(getRequirementsCriteriaStart());
    axios
      .get(`/service-requirement-criteria/find/${id}`, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOneRequirementsCriteriaSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getRequirementsCriteriaFail(err));
      });
  };
};

export const activatingRequirementsCriteria = (token, id) => {
  return (dispatch) => {
    dispatch(getRequirementsCriteriaStart());
    axios
      .put(`/service-requirement-criteria/activating/${id}`, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOneRequirementsCriteriaSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getRequirementsCriteriaFail(err));
      });
  };
};

export const desActivatingRequirementsCriteria = (token, id) => {
  return (dispatch) => {
    dispatch(getRequirementsCriteriaStart());
    axios
      .put(`/service-requirement-criteria/desactivating/${id}`, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOneRequirementsCriteriaSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getRequirementsCriteriaFail(err));
      });
  };
};

export const updateRequirementsCriteria = (token, object) => {
  return (dispatch) => {
    dispatch(getRequirementsCriteriaStart());
    axios
      .put(`/service-requirement-criteria/edit/${object?.id}`, object, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOneRequirementsCriteriaSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getRequirementsCriteriaFail(err));
      });
  };
};

export const getCriteriaValuesSuccess = (data) => {
  return {
    type: GET_CRITERIA_VALUES_SUCCESS,
    payload: data,
  };
};

export const getOneCriteriaValuesSuccess = (data) => {
  return {
    type: GET_ONE_CRITERIA_VALUES_SUCCESS,
    payload: data,
  };
};

export const getCriteriaValuesStart = () => {
  return {
    type: GET_CRITERIA_VALUES_START,
  };
};
export const getCriteriaValuesFail = (error) => {
  return {
    type: GET_CRITERIA_VALUES_FAIL,
    payload: error,
  };
};

export const createCriteriaValues = (object, token) => {
  return (dispatch) => {
    dispatch(getCriteriaValuesStart());
    axios
      .post(`/service-requirement-criteria-values/create`, object, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOneCriteriaValuesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getCriteriaValuesFail(err));
      });
  };
};

export const getCriteriaValues = (token, id) => {
  return (dispatch) => {
    dispatch(getCriteriaValuesStart());
    axios
      .get(`/service-requirement-criteria-values/findby-parent/${id}`, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getCriteriaValuesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getCriteriaValuesFail(err));
      });
  };
};

export const getOneCriteriaValues = (token, id) => {
  return (dispatch) => {
    dispatch(getCriteriaValuesStart());
    axios
      .get(`/service-requirement-criteria-values/find/${id}`, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOneCriteriaValuesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getCriteriaValuesFail(err));
      });
  };
};

export const activatingCriteriaValues = (token, id) => {
  return (dispatch) => {
    dispatch(getCriteriaValuesStart());
    axios
      .put(`/service-requirement-criteria-values/activating/${id}`, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOneCriteriaValuesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getCriteriaValuesFail(err));
      });
  };
};

export const desActivatingCriteriaValues = (token, id) => {
  return (dispatch) => {
    dispatch(getCriteriaValuesStart());
    axios
      .put(`/service-requirement-criteria-values/desactivating/${id}`, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOneCriteriaValuesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getCriteriaValuesFail(err));
      });
  };
};

export const updateCriteriaValues = (token, object) => {
  return (dispatch) => {
    dispatch(getCriteriaValuesStart());
    axios
      .put(`/service-requirement-criteria-values/edit/${object?.id}`, object, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOneCriteriaValuesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getCriteriaValuesFail(err));
      });
  };
};
