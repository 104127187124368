import { useEffect } from "react";
import Header from "./header";
import { useTranslation } from "react-i18next";
import { Footer } from "./components/footer";
import { ScrollToTop } from "./components/backtotop";
import { Service, TestimonialsComponent } from "./services/service";
import { NavLink } from "react-router-dom";
import { PartnersSection } from "./about/partners";
import mySwiper from "./services/swiper";
import * as actions from "./store/actions/";
import { useDispatch, useSelector } from "react-redux";

const Home = () => {
  const dispatch = useDispatch();
  const language = useSelector((state) => state.homepage.language);
  const home = useSelector((state) => state.homepage.home);
  const homeServices = useSelector((state) => state.services.services);
  const testimonials = useSelector((state) => state.services.texttestimonies);
  const achievements = useSelector((state) => state.homepage.achievements);
  const achievementTitle = useSelector(
    (state) => state.homepage.achievementTitle
  );
  const requestService = useSelector((state) => state.homepage.requestService);
  const { t } = useTranslation();
  useEffect(() => {
    mySwiper();
    dispatch(actions.getActiveHomes(language));
    dispatch(actions.getActiveHomeAchievements(language));
    dispatch(actions.getActiveHomeAchievementTitles(language));
    dispatch(actions.getActiveHomeRequestServices(language));
    dispatch(actions.getActivePartners());
    dispatch(actions.getHomeServices(language));
    dispatch(actions.getActiveTextTestimonies(language));
  }, [dispatch, language]);
  return (
    <div className="page-index">
      <Header />
      <section id="hero" className="hero d-flex align-items-center">
        <div className="container">
          <div className="row">
            <div className="col-xl-4">
              <h2 data-aos="fade-up">{home?.title}</h2>
              <blockquote data-aos="fade-up" data-aos-delay="100">
                <p>{home?.description}</p>
              </blockquote>
              <div className="d-flex" data-aos="fade-up" data-aos-delay="200">
                <NavLink to="/about" className="btn-get-started">
                  {t("Get Started")}
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </section>

      <main id="main">
        <section id="services-list" className="services-list">
          <div className="container" data-aos="fade-up">
            <div className="section-header">
              <h2>{t("Our Services")}</h2>
            </div>

            <div className="row gy-5">
              {homeServices?.map((service, index) => (
                <Service
                  name={service?.name}
                  image={`${process.env.REACT_APP_BACKEND_URL}/${service?.image}`}
                />
              ))}
            </div>
            <div className="text-center pt-3">
            <h5 className="py-3">{achievementTitle?.title}</h5>
            </div>
          </div>
          <div className="text-center pt-3">
            <NavLink to="/services" className="buttons">
              {t("View More")}
            </NavLink>
          </div>
        </section>

        <section id="call-to-action" className="call-to-action">
          <div className="container" data-aos="fade-up">
            <div className="row justify-content-center">
              <div className="col-lg-6 text-center">
                <h3>{requestService?.title}</h3>
                <p>{requestService?.description}</p>
                <a
                  className="cta-btn"
                  target={"_blank"}
                  href={requestService?.link}
                >
                  {requestService?.btnmessage}
                </a>
              </div>
            </div>
          </div>
        </section>

        <section id="features" className="features bg-light d-none">
          <div className="container" data-aos="fade-up">
            <div className="row">
              <div
                className="col-lg-2 position-relative"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <div className="phone-wrap"></div>
              </div>
              <div className="col-lg-7" data-aos="fade-up" data-aos-delay="100">
                <h3>{achievementTitle?.title}</h3>
                <div className="row gy-4">
                  {achievements?.map((achievement, index) => (
                    <div className="col-md-6" key={index}>
                      <div className="icon-list d-flex">
                        <i className="ri-crop-2-fill style9"></i>
                        <span>{achievement?.name}</span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div></div>
        </section>
        <TestimonialsComponent testimonials={testimonials} />
        <PartnersSection />
      </main>
      <Footer />
      <ScrollToTop />
    </div>
  );
};

export default Home;
