import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

export const TrainingComponent = function (props) {
  const { t } = useTranslation();
  const category = props?.category;
  let rowClass = "";
  let titleClass = "";
  return (
    <div
      className="col-md-6 service-item d-flex"
      data-aos="fade-up"
      data-aos-delay="100"
    >
      <div className="icon flex-shrink-0"></div>
      <div>
        <h4 className="title">
          <span className="stretched-link bdf-bluefontcolor-title">
            {category?.category}
          </span>
        </h4>
        <p className="description">{category?.description}</p>
        <div className="h-50">
        <div className={"h-100"} >
              {category?.subcategories?.map((content, index) => (
                <div class="">
                  <div className={titleClass} key={index}>
                    <i class="bi bi-check-circle-fill listing-icons"></i>
                    {" " + content?.title}
                  </div>
                  <p className="">{" " + content?.description}</p>
                  <ol className="">
                    {content?.content?.map((obj, index) => (
                      <li key={index}>{obj?.title}</li>
                    ))}
                  </ol>
                </div>
              ))}
        </div>
        </div>
        <div className="row d-flex justify-content-around pt-3 d-none">
          <div className="col-md-6 bdf-btn">{t("Register")}</div>
        </div>
      </div>
    </div>
  );
};

export const YTVideoTestimonyCard = function (props) {
  const { t } = useTranslation();
  return (
    <div class="swiper-slide">
      <div class="testimonial-item">
        <iframe
          width="300"
          height="480"
          src={`https://www.youtube.com/embed/${props?.videoId}`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Embedded youtube"
        ></iframe>
      </div>
    </div>
  );
};

export const TrainingTestimonialsComponent = function (props) {
  const testimonies = useSelector((state) => state.services.videoTestimonies);
  const videoIds = [
    { videoLink: "iFddWKiJfWI", name: "Rwiyemezamirimo" },
    { videoLink: "yVBtTak_Mic", name: "Rwiyemezamirimo" },
    { videoLink: "CllHb6lhW1A", name: "Rwiyemezamirimo" },
    { videoLink: "mdzHEt1qG2s", name: "Rwiyemezamirimo" },
  ];
  const { t } = useTranslation();
  return (
    <section id="testimonials" class="testimonials">
      <div className="container" data-aos="fade-up">
        <div class="section-header">
          <h2>{t("Testimonials")}</h2>
        </div>
        <div class="slides-3 swiper" data-aos="fade-up" data-aos-delay="100">
          <div class="swiper-wrapper">
            {testimonies?.map((vid, index) => (
              <YTVideoTestimonyCard videoId={vid?.videoLink} />
            ))}
          </div>
          <div class="swiper-pagination"></div>
        </div>
      </div>
    </section>
  );
};

export const TextTestimonialsComponent = function (props) {
  const videoIds = [
    { id: "iFddWKiJfWI", name: "Rwiyemezamirimo" },
    { id: "yVBtTak_Mic", name: "Rwiyemezamirimo" },
    { id: "CllHb6lhW1A", name: "Rwiyemezamirimo" },
    { id: "mdzHEt1qG2s", name: "Rwiyemezamirimo" },
  ];
  const { t } = useTranslation();
  return (
    <section id="testimonials" class="testimonials">
      <div className="container" data-aos="fade-up">
        <div class="section-header">
          <h2>{t("Testimonials")}</h2>
        </div>
        <div class="slides-3 swiper" data-aos="fade-up" data-aos-delay="100">
          <div class="swiper-wrapper">
            {videoIds?.map((vid, index) => (
              <YTVideoTestimonyCard videoId={vid?.id} />
            ))}
          </div>
          <div class="swiper-pagination"></div>
        </div>
      </div>
    </section>
  );
};
