import { ScrollToTop } from "./components/backtotop";
import { Footer } from "./components/footer";
import Header from "./header";
import { useTranslation } from "react-i18next";
import * as actions from "./store/actions/";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";

export const ContactUs = function (props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [sendBy, setSendBy] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [msg, setMsg] = useState("");
  const statemessage = useSelector((state) => state.messages.message);
  const sendMessage = () => {
    if (sendBy.length <= 2) {
      setError("Name is Required");
    } else if (email.length <= 6) {
      setError("Email is Required");
    } else if (subject.length <= 4) {
      setError("Subject is Required");
    } else if (message.length <= 4) {
      setError("Message is Required");
    } else {
      const object = {
        sendBy,
        email,
        subject,
        message,
      };
      setError("");
      setMsg("Message Successfully Sent");
      dispatch(actions.getSendMessages(object));
    }
  };

  useEffect(() => {
    // setError("");
    // setMsg("");
  }, [statemessage]);
  return (
    <body className="page-contact">
      <Header />
      <main id="main">
        <div className="breadcrumbs d-flex align-items-center contactus-hd-pg">
          <div className="container position-relative d-flex flex-column align-items-center">
            <h2>{t("Contact")}</h2>
          </div>
        </div>
        <section id="contact" className="contact">
          <div className="container position-relative" data-aos="fade-up">
            <div className="row gy-4 d-flex justify-content-end">
              <div className="col-lg-5" data-aos="fade-up" data-aos-delay="100">
                <div className="info-item d-flex">
                  <i className="bi bi-geo-alt flex-shrink-0"></i>
                  <div>
                    <h4>{t("Location")}:</h4>
                    <p>{t("HQAddress")}</p>
                  </div>
                </div>

                <div className="info-item d-flex">
                  <i className="bi bi-envelope flex-shrink-0"></i>
                  <div>
                    <h4>{t("Email")}:</h4>
                    <p>info@bdf.rw</p>
                  </div>
                </div>

                <div className="info-item d-flex">
                  <i className="bi bi-phone flex-shrink-0"></i>
                  <div>
                    <h4>{t("Call")}:</h4>
                    <p>4777</p>
                  </div>
                </div>
              </div>

              <div className="col-lg-6" data-aos="fade-up" data-aos-delay="250">
                <form className="php-email-form">
                  <div className="row">
                    <div className="col-md-6 form-group">
                      <input
                        type="text"
                        name="name"
                        className="form-control"
                        id="name"
                        placeholder={t("Your Name")}
                        required
                        onChange={(e) => setSendBy(e.target.value)}
                      />
                    </div>
                    <div className="col-md-6 form-group mt-3 mt-md-0">
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        id="email"
                        placeholder={t("Your Email")}
                        required
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="form-group mt-3">
                    <input
                      type="text"
                      className="form-control"
                      name="subject"
                      id="subject"
                      placeholder={t("Subject")}
                      required
                      onChange={(e) => setSubject(e.target.value)}
                    />
                  </div>
                  <div className="form-group mt-3">
                    <textarea
                      className="form-control"
                      name="message"
                      rows="5"
                      placeholder={t("Message")}
                      required
                      onChange={(e) => setMessage(e.target.value)}
                    ></textarea>
                  </div>
                  <div className="text-center text-danger">{t(error)}</div>
                  <div className="text-center text-success">{t(msg)}</div>
                  <div className="text-center">
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        sendMessage();
                      }}
                      type="submit"
                    >
                      {t("Send")}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
        {/*<section id="services-list" className="services-list">
                    <div className="container" data-aos="fade-up">

                        <div className="section-header">
                            <h2>{t('Our Branches Location')}</h2>

                        </div>

                        <div className="row gy-5">
                            <Service name={t('Guarantee')} />
                            <Service name={t('Agribusiness')} />
                            <Service name={t('Leasing')} />
                            <Service name={t('Grants')} />
                            <Service name={t('Sacco Refinancing')} />
                            <Service name={t('Advisory')} />
                        </div>

                    </div>
                </section> */}
      </main>

      <Footer />
      <ScrollToTop />
    </body>
  );
};
