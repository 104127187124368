import { useTranslation } from "react-i18next";
import * as actions from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { ScrollToTop } from "../../components/backtotop";
import Header from "../components/header";
import Modal from "react-bootstrap/Modal";
import moment from "moment";
import {
  ActivateButton,
  EditButton,
  InputDate,
  InputTelephone,
  InputText,
  InputText2,
  InputTextArea,
  InputTextSecret,
  PageLink,
  SelectInput,
  SelectRadioButton,
  SubmitButton,
  UplodadFile,
  ViewButton,
} from "../components/inputs";
import { LanguagesTable } from "../components/languagestable";
import {
  successCreationNotification,
  successNotification,
  successUpdateNotification,
} from "../../components/notifications";
import { ReactNotifications } from "react-notifications-component";
import classnames from "classnames";
import { useHistory, useParams } from "react-router-dom";
import MiSpinner from "../../components/spinner";
import constants from "../../components/constants";
import { getLanguages } from "../../store/actions";
import { forEach, set } from "lodash";
import { ImageCard, ObjectDetails } from "../components/output";

export const AdminSMS = function (props) {
  const WORKSPACE = "SMS";
  const history = useHistory();
  const params = useParams();
  const dispatch = useDispatch();
  const [clicked, setclicked] = useState(false);
  const [btndisabled, setbtndisabled] = useState(false);
  const object = useSelector((state) => state.publications.publication);
  const loading = useSelector(
    (state) => state.publications.publicationsLoading
  );
  useEffect(() => {}, [object]);
  const [telephone, settelephone] = useState("");
  const [message, setmessage] = useState("");
  const userNames = useSelector((state) => state.auth.displayName);
  const token = useSelector((state) => state.auth.token);
  const [objects, setObjects] = useState([]);

  const onSubmit = (e) => {
    e.preventDefault();
    if (
      telephone?.toString().trim() === "" ||
      message?.toString().trim() === "" ||
      message?.length < 10
    ) {
      setclicked(true);
    } else {
      if (window.confirm("Are you sure you want sent this sms?")) {
        const themessage = message.replace("yinguzanyo", "y'inguzanyo");
        console.log("MSG: ", themessage);
        const alltels = telephone?.split(",");
        const telsstrimmed = [];
        const allsms = [];
        alltels.forEach((element) => {
          if (element?.trim() !== "" && element?.trim().length >= 10) {
            telsstrimmed?.push(element?.trim().replaceAll(" ",""));
          }
        });
        telsstrimmed.forEach((element) => {
          allsms?.push({
            sentto: element,
            message: themessage,
            sentby: userNames,
          });
        });
        const obj = {
          sentto: telephone,
          message,
          sentby: userNames,
        };
        if (allsms.length > 0) {
          dispatch(actions.createSMSs(allsms, token));
          settelephone("");
          setmessage("");
          setclicked(false);
          successUpdateNotification(WORKSPACE, "Updated");
        }
      } else {
      }
    }
  };

  return (
    <div className="">
      <Header />
      <ReactNotifications />
      <main id="main">
        <div className="breadcrumbs-modified d-flex align-items-center"></div>
        {loading ? (
          <MiSpinner />
        ) : (
          <section id="contact" className="input-data">
            <div className="container" data-aos="fade-up">
              <div className="row d-flex justify-content-end php-email-form">
                <div className="col-md-8">
                  <div className="">
                    <div className="">
                      <h5 className="bdf-bluefontcolor">SEND {WORKSPACE}</h5>
                    </div>
                    <div className="form-group py-3">
                      <InputTextArea
                        isClicked={clicked}
                        title={"Telephone (separated by comma)"}
                        value={telephone}
                        name="telephone"
                        placeholder="07..."
                        rowsNbr={0}
                        onChange={(e) => settelephone(e.target.value)}
                      />
                    </div>
                    <div className="form-group py-3">
                      <InputTextArea
                        isClicked={clicked}
                        title={"Message"}
                        placeholder="message"
                        value={message}
                        name="message"
                        rowsNbr={4}
                        onChange={(e) => setmessage(e.target.value)}
                      />
                    </div>
                    <div className="row gx-5"></div>
                    <SubmitButton
                      classes={object === null ? "bg-success" : "d-none"}
                      action={"Send"}
                      onSubmit={onSubmit}
                    />
                  </div>
                </div>
                <div className="col-md-3 d-flex gy-5 ml-5 justify-content-end">
                  <div>
                    <div className="flex flex-column h-50">
                      <PageLink
                        page={"/sms-module/objects/list"}
                        title={"SMS Sent"}
                      />
                    </div>
                    <div></div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
      </main>
      <ScrollToTop />
    </div>
  );
};

export const AdminSMSsList = function (props) {
  const WORKSPACE = "SMS List";
  const loading = useSelector((state) => state.services.servicesLoading);
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);
  const object = useSelector((state) => state.sms.sms);
  const objectList = useSelector((state) => state.sms.smss);

  useEffect(() => {
    dispatch(actions.getSMSs());
  }, []);

  const [show, setShow] = useState(false);
  const handleClose = (e) => {
    e.preventDefault();
    dispatch(actions.getServices());
    setShow(false);
  };

  const viewMore = (id) => {
    dispatch(actions.getOneServices(token, id));
    setShow(true);
  };

  return (
    <div className="">
      <Header />
      <ReactNotifications />
      <main id="main">
        <div className="breadcrumbs-modified d-flex align-items-center"></div>
        {loading ? (
          <MiSpinner />
        ) : (
          <>
            <Modal show={show} onHide={handleClose} size="xl">
              <Modal.Body>
                {loading ? (
                  <MiSpinner />
                ) : (
                  <div className="row px-3 pt-5 pb-2">
                    <div className="col-md-4">
                      <ImageCard src={object?.image} />
                    </div>
                    <div className="col-md-8 px-5">
                      <span>
                        <span className="bdf-bluefontcolor">Name:</span>{" "}
                        {object?.name}
                      </span>
                      <div>
                        <p className="description pt-3">
                          <span className="bdf-bluefontcolor">
                            Description:{" "}
                          </span>{" "}
                          {object?.description}
                        </p>
                      </div>
                      <div className="mb-3">
                        <ObjectDetails
                          title={"Language"}
                          value={object?.language}
                        />
                        <ObjectDetails
                          title={"Status"}
                          value={object?.status}
                        />
                        <ObjectDetails
                          title={"Creator"}
                          value={object?.creator}
                        />
                        <ObjectDetails
                          title={"Created At"}
                          value={moment(object?.createdAt).format("DD-MM-YYYY")}
                        />
                      </div>
                      <button className="mi-btn" onClick={handleClose}>
                        Close
                      </button>
                      <span className="px-3"></span>
                      <EditButton
                        title="Edit"
                        page="/admin/services"
                        id={object?.uuid}
                      />
                    </div>
                  </div>
                )}
              </Modal.Body>
            </Modal>
            <section id="contact" className="input-data">
              <div className="container" data-aos="fade-up">
                <div className="row d-flex justify-content-end php-email-form">
                  <div className="col-md-8">
                    <div className="text-center">
                      <h5 className="bdf-bluefontcolor">{WORKSPACE} LIST</h5>
                    </div>
                    <table className="table table-striped">
                      <thead>
                        <tr className="bdf-bluefontcolor">
                          <th>#</th>
                          <th>Sent By</th>
                          <th>Sent to</th>
                          <th>Message</th>
                          <th>Sent Date & Time</th>
                          <th className="text-center d-none">Action</th>
                        </tr>
                      </thead>
                      <tbody className="">
                        {objectList?.map((data, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{data?.sentby}</td>
                            <td>{data?.sentto}</td>
                            <td>{data?.message}</td>
                            <td>
                              {moment(data?.sentdate).format(
                                "DD-MM-YYYY hh:mm"
                              )}
                            </td>
                            <td>{data?.sentstatus}</td>
                            <td className="row d-flex justify-content-center d-none">
                              <EditButton
                                title="Requirements"
                                page="/admin/services-requirements"
                                id={data?.uuid}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div className="col-md-3 d-flex gy-5 ml-5 justify-content-end">
                    <div>
                      <div className="flex flex-column h-50">
                        <PageLink page="/sms-module" title={"New SMS"} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </>
        )}
      </main>
      <ScrollToTop />
    </div>
  );
};
