import { useTranslation } from "react-i18next";
import * as actions from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { ScrollToTop } from "../../components/backtotop";
import Header from "../components/header";
import Modal from "react-bootstrap/Modal";
import {
  ActivateButton,
  EditButton,
  InputText,
  InputText2,
  InputTextArea,
  InputTextSecret,
  PageLink,
  SelectRadioButton,
  SubmitButton,
  UplodadFile,
  ViewButton,
} from "../components/inputs";
import { LanguagesTable } from "../components/languagestable";
import {
  successCreationNotification,
  successNotification,
  successUpdateNotification,
} from "../../components/notifications";
import { ReactNotifications } from "react-notifications-component";
import classnames from "classnames";
import { useHistory, useParams } from "react-router-dom";
import MiSpinner from "../../components/spinner";
import constants from "../../components/constants";
import { getLanguages } from "../../store/actions";
import { set } from "lodash";
import { ImageCard, ObjectDetails } from "../components/output";
import moment from "moment";
import richtextconstants from "../../components/richtextconstants";
import { InputTextArea2nd } from "../components/inputs2";

export const AdminServices = function (props) {
  const WORKSPACE = "Services";
  const history = useHistory();
  const params = useParams();
  const [counter, setcounter] = useState(0);
  const dispatch = useDispatch();
  const [clicked, setclicked] = useState(false);
  const [btndisabled, setbtndisabled] = useState(false);
  const object = useSelector((state) => state.services.service);
  const loading = useSelector((state) => state.services.servicesLoading);
  useEffect(() => {
    dispatch(getLanguages());
    if (params?.objectId && object === null) {
      dispatch(actions.getOneServices(token, params?.objectId));
    }
    if (object !== null) {
      setname(object?.name);
      setdescription(object?.description);
    }
  }, [object]);
  const [name, setname] = useState("");
  const [description, setdescription] = useState("");
  const [image, setimage] = useState(null);
  const languages = useSelector((state) => state.languages.languages);
  const userNames = useSelector((state) => state.auth.displayName);
  const token = useSelector((state) => state.auth.token);
  const [objects, setObjects] = useState([]);

  const fileSelectorHandler = (event) => {
    setimage(event.target.files[0]);
  };

  const activating = (e) => {
    e.preventDefault();
    if (window.confirm("Are you sure you want change the status?")) {
      if (object?.status === constants.active) {
        dispatch(actions.desActivatingServices(token, object?.uuid));
        successUpdateNotification(WORKSPACE, "Inactive");
      } else {
        dispatch(actions.activatingServices(token, object?.uuid));
        successUpdateNotification(WORKSPACE, "Active");
      }
    } else {
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (name === "" || description === "") {
      setclicked(true);
    } else {
      if (object != null) {
        if (window.confirm("Are you sure you want update?")) {
          const obj = {
            name,
            description,
            id: object.uuid,editedBy:userNames,
          };
          dispatch(actions.updateServices(token, obj));
          setname("");
          setdescription("");
          setclicked(false);
          successUpdateNotification(WORKSPACE, "Updated");
        } else {
        }
      } else {
        if (counter < languages.length) {
          const lang = languages[counter]
            ? languages[counter].abbreviation
            : "";
          const languageName = languages[counter]
            ? languages[counter].name
            : "";
          const subs = setObjects([
            ...objects,
            {
              name,
              description,
              creator: userNames,
              language: lang,
            },
          ]);
          setcounter(counter + 1);
          successNotification(WORKSPACE, languageName);
          setname("");
          setdescription("");
          setclicked(false);
        }
        if (counter == languages.length - 1) {
          setname(name);
          setdescription(description);
          setbtndisabled(true);
        }
        if (counter == languages.length) {
          if (window.confirm("Are you sure you want submit?")) {
            const fd = new FormData();
            fd.append("image", image);
            fd.append("objects", JSON.stringify(objects));
            dispatch(actions.createServices(fd, token));
            setcounter(0);
            setObjects([]);
            setname("");
            setdescription("");
            setclicked(false);
            setbtndisabled(false);
            successCreationNotification(WORKSPACE);
          } else {
          }
        }
      }
    }
  };

  return (
    <div className="">
      <Header />
      <ReactNotifications />
      <main id="main">
        <div className="breadcrumbs-modified d-flex align-items-center"></div>
        {loading ? (
          <MiSpinner />
        ) : (
          <section id="contact" className="input-data">
            <div className="container" data-aos="fade-up">
              <div className="row d-flex justify-content-end php-email-form">
                <div className="col-md-8">
                  <div className="">
                    <div className="">
                      {object !== null ? (
                        <>
                          <h5 className="bdf-bluefontcolor">
                            EDIT {WORKSPACE} ({object?.language})
                          </h5>
                        </>
                      ) : (
                        <h5 className="bdf-bluefontcolor">
                          CREATE {WORKSPACE} IN: {languages[counter]?.name}
                        </h5>
                      )}
                    </div>
                    <div className="form-group py-3">
                      <InputText2
                        name={"Title"}
                        value={name}
                        title="name"
                        placeholder="Title"
                        isClicked={clicked}
                        onChange={(e) => setname(e.target.value)}
                      />
                    </div>
                    <div className="form-group py-3">
                      <InputTextArea
                        isClicked={clicked}
                        title={"Description"}
                        placeholder="Description"
                        value={description}
                        name="description"
                        onChange={(e) => setdescription(e.target.value)}
                      />
                    </div>
                    <div className="form-group py-3">
                      <UplodadFile
                        title="Image"
                        updating={
                          object != null ? constants.isTrue : constants.isFalse
                        }
                        name="image"
                        required
                        onChange={fileSelectorHandler}
                      />
                    </div>
                    <div className="row gx-5"></div>
                    <SubmitButton
                      disabled={btndisabled}
                      action={object === null ? "Create" : "Update"}
                      onSubmit={onSubmit}
                    />
                    <span className="px-2"></span>
                    <SubmitButton
                      disabled={!btndisabled}
                      classes={object === null ? "bg-success" : "d-none"}
                      action={"Submit"}
                      onSubmit={onSubmit}
                    />
                    <span className="px-5"></span>
                    <ActivateButton
                      status={object?.status}
                      onSubmit={activating}
                    />
                  </div>
                </div>
                <div className="col-md-3 d-flex gy-5 ml-5 justify-content-end">
                  <div>
                    <div className="flex flex-column h-50">
                      <PageLink
                        page={"/admin/services/objects/list"}
                        title={"List"}
                      />
                    </div>
                    <div>
                      <LanguagesTable languages={languages} counter={counter} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
      </main>
      <ScrollToTop />
    </div>
  );
};

export const AdminServiceRequirements = function (props) {
  const WORKSPACE = " Components";
  const params = useParams();
  const dispatch = useDispatch();
  const object = useSelector((state) => state.services.service);
  const requirement = useSelector((state) => state.services.requirement);
  const loading = useSelector((state) => state.services.requirementsLoading);
  const requirements = useSelector((state) => state.services.requirements);
  const requirementsLoading = useSelector(
    (state) => state.services.requirementsLoading
  );
  useEffect(() => {
    if (params?.objectId && object === null) {
      dispatch(actions.getOneServices(token, params?.objectId));
    }
    if (object !== null) {
      dispatch(actions.getServiceRequirements(token, params?.objectId));
    }
  }, [object]);
  const token = useSelector((state) => state.auth.token);

  const [show, setShow] = useState(false);
  const handleClose = (e) => {
    e.preventDefault();
    dispatch(actions.getServiceRequirements(token, params?.objectId));
    setShow(false);
  };

  const viewMore = (id) => {
    dispatch(actions.getOneServiceRequirements(token, id));
    setShow(true);
  };

  return (
    <div className="">
      <Header />
      <ReactNotifications />
      <main id="main">
        <div className="breadcrumbs-modified d-flex align-items-center"></div>
        {loading ? (
          <MiSpinner />
        ) : (
          <>
            <Modal show={show} onHide={handleClose} size="xl">
              <Modal.Body>
                {loading ? (
                  <MiSpinner />
                ) : (
                  <div className="row px-3 pt-5 pb-2">
                    <div className="col-md-2"></div>
                    <div className="col-md-8 px-5">
                      <span>
                        <span className="bdf-bluefontcolor">Title:</span>{" "}
                        {requirement?.title}
                      </span>
                      <div>
                        <p className="description pt-3">
                          <span className="bdf-bluefontcolor">
                            Description:{" "}
                          </span>{" "}
                          {requirement?.briefsummary}
                        </p>
                      </div>
                      <div className="w-full">
                        <a
                          target="_blanck"
                          href={`${process.env.REACT_APP_BACKEND_URL}/${requirement?.requirementsFile}`}
                        >
                          {"Requirement File " + requirement?.requirementsFile}
                          <i className="ri-arrow-down-circle-line"></i>
                        </a>
                      </div>
                      <div className="mb-3">
                        <ObjectDetails
                          title={"Language"}
                          value={requirement?.language}
                        />
                        <ObjectDetails
                          title={"Status"}
                          value={requirement?.status}
                        />
                        <ObjectDetails
                          title={"Creator"}
                          value={requirement?.creator}
                        />
                        <ObjectDetails
                          title={"Created At"}
                          value={moment(requirement?.createdAt).format(
                            "DD-MM-YYYY"
                          )}
                        />
                      </div>
                      <button className="mi-btn" onClick={handleClose}>
                        Close
                      </button>
                      <span className="px-3"></span>
                      <EditButton
                        title="Edit"
                        page="/admin/services-requirements-new"
                        id={requirement?.uuid}
                        parentId={object?.uuid}
                      />
                    </div>
                  </div>
                )}
              </Modal.Body>
            </Modal>
            <section id="contact" className="input-data">
              <div className="container" data-aos="fade-up">
                <div className="row d-flex justify-content-end php-email-form">
                  <div className="col-md-8">
                    <div className="text-center">
                      <h5 className="bdf-bluefontcolor">
                        {}
                        {object?.name} ({object?.language}) {WORKSPACE} LIST
                      </h5>
                    </div>
                    <table className="table table-striped">
                      <thead>
                        <tr className="bdf-bluefontcolor">
                          <th>#</th>
                          <th>Title</th>
                          <th>Status</th>
                          <th>Language</th>
                          <th className="text-center">Action</th>
                        </tr>
                      </thead>
                      <tbody className="">
                        {requirements?.map((data, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{data?.title}</td>
                            <td>{data?.status}</td>
                            <td>{data?.language}</td>
                            <td className="row d-flex justify-content-center">
                              <EditButton
                                title="Edit"
                                page="/admin/services-requirements-new"
                                id={data?.uuid}
                                parentId={object?.uuid}
                              />
                              <span className="px-2 w-auto"></span>
                              <ViewButton
                                title={"View "}
                                onClick={(e) => {
                                  e.preventDefault();
                                  viewMore(data?.uuid);
                                }}
                              />
                              <span className="px-2 w-auto"></span>
                              <EditButton
                                title="Criterias"
                                page="/admin/services-requirements-criterias"
                                id={data?.uuid}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div className="col-md-3 d-flex gy-5 ml-5 justify-content-end">
                    <div>
                      <div className="flex flex-column h-50 text-center">
                        <div>
                          <PageLink
                            page={"/admin/services-requirements-new"}
                            title={"New Component"}
                          />
                        </div>
                        <span></span>
                        <div className="pt-5">
                          <PageLink
                            page={"/admin/services/objects/list"}
                            title={"Services"}
                          />
                        </div>
                      </div>
                      <div></div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </>
        )}
      </main>
      <ScrollToTop />
    </div>
  );
};

export const AdminServiceRequirementCriteria = function (props) {
  const WORKSPACE = " Description";
  const params = useParams();
  const dispatch = useDispatch();
  const service = useSelector((state) => state.services.service);
  const object = useSelector((state) => state.services.requirement);
  const loading = useSelector(
    (state) => state.services.requirementCriteriasLoading
  );
  const requirementCriterias = useSelector(
    (state) => state.services.requirementCriterias
  );
  const requirementCriteriasLoading = useSelector(
    (state) => state.services.requirementCriteriasLoading
  );
  useEffect(() => {
    if (params?.objectId && object === null) {
      dispatch(actions.getOneServiceRequirements(token, params?.objectId));
    }
    if (object !== null) {
      dispatch(actions.getRequirementsCriteria(token, params?.objectId));
    }
  }, [object]);
  const token = useSelector((state) => state.auth.token);

  return (
    <div className="">
      <Header />
      <ReactNotifications />
      <main id="main">
        <div className="breadcrumbs-modified d-flex align-items-center"></div>
        {loading ? (
          <MiSpinner />
        ) : (
          <section id="contact" className="input-data">
            <div className="container" data-aos="fade-up">
              <div className="row d-flex justify-content-end php-email-form">
                <div className="col-md-8">
                  <div className="text-center">
                    <h4 className="bdf-bluefontcolor">
                      Service: {service?.name}
                    </h4>
                    <h5 className="bdf-bluefontcolor">
                      {}
                      {object?.title} ({object?.language}) {WORKSPACE}
                    </h5>
                  </div>
                  <table className="table table-striped">
                    <thead>
                      <tr className="bdf-bluefontcolor">
                        <th>#</th>
                        <th>Title</th>
                        <th>Status</th>
                        <th>Language</th>
                        <th className="text-center">Action</th>
                      </tr>
                    </thead>
                    <tbody className="">
                      {requirementCriterias?.map((data, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{data?.criteriaName}</td>
                          <td>{data?.status}</td>
                          <td>{data?.language}</td>
                          <td className="row d-flex justify-content-center">
                            <EditButton
                              title="Edit"
                              page="/admin/services-requirements-criterias-new"
                              id={data?.uuid}
                              parentId={object?.uuid}
                            />
                            <span className="px-2 w-auto"></span>
                            <EditButton
                              title="Contents"
                              page="/admin/services-requirements-criterias-values"
                              id={data?.uuid}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className="col-md-3 d-flex gy-5 ml-5 justify-content-end">
                  <div>
                    <div className="flex flex-column h-50 text-center">
                      <div>
                        <PageLink
                          page={"/admin/services-requirements-criterias-new"}
                          title={"New Content"}
                        />
                      </div>
                      <span></span>
                      <div className="pt-5">
                        <PageLink
                          page={"/admin/services-requirements/" + service?.uuid}
                          title={"Service Components"}
                        />
                      </div>
                    </div>
                    <div></div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
      </main>
      <ScrollToTop />
    </div>
  );
};

export const AdminServiceRequirementCriteriaValues = function (props) {
  const WORKSPACE = " Contents";
  const params = useParams();
  const dispatch = useDispatch();
  const service = useSelector((state) => state.services.service);
  const component = useSelector((state) => state.services.requirement);
  const object = useSelector((state) => state.services.requirementCriteria);
  const loading = useSelector((state) => state.services.criteriaValuesLoading);
  const criteriaValues = useSelector((state) => state.services.criteriaValues);
  const criteriaValuesLoading = useSelector(
    (state) => state.services.criteriaValuesLoading
  );
  useEffect(() => {
    if (params?.objectId && object === null) {
      dispatch(actions.getOneRequirementsCriteria(token, params?.objectId));
    }
    if (object !== null) {
      dispatch(actions.getCriteriaValues(token, params?.objectId));
    }
  }, [object]);
  const token = useSelector((state) => state.auth.token);

  return (
    <div className="">
      <Header />
      <ReactNotifications />
      <main id="main">
        <div className="breadcrumbs-modified d-flex align-items-center"></div>
        {loading ? (
          <MiSpinner />
        ) : (
          <section id="contact" className="input-data">
            <div className="container" data-aos="fade-up">
              <div className="row d-flex justify-content-end php-email-form">
                <div className="col-md-8">
                  <div className="text-center">
                    <h4 className="bdf-bluefontcolor">
                      Service: {service?.name}
                    </h4>
                    <h5 className="bdf-bluefontcolor">
                      Component: {component?.title}
                    </h5>
                    <h6 className="bdf-bluefontcolor">
                      {}
                      {object?.criteriaName} ({object?.language}) {WORKSPACE}{" "}
                      LIST
                    </h6>
                  </div>
                  <table className="table table-striped">
                    <thead>
                      <tr className="bdf-bluefontcolor">
                        <th>#</th>
                        <th>Content</th>
                        <th>Status</th>
                        <th>Language</th>
                        <th className="text-center">Action</th>
                      </tr>
                    </thead>
                    <tbody className="">
                      {criteriaValues?.map((data, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{data?.value}</td>
                          <td>{data?.status}</td>
                          <td>{data?.language}</td>
                          <td className="row d-flex justify-content-center">
                            <EditButton
                              title="Edit"
                              page="/admin/services-requirements-criterias-values-new"
                              id={data?.uuid}
                              parentId={object?.uuid}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className="col-md-3 d-flex gy-5 ml-5 justify-content-end">
                  <div>
                    <div className="flex flex-column h-50 text-center">
                      <div>
                        <PageLink
                          page={
                            "/admin/services-requirements-criterias-values-new"
                          }
                          title={"New Criteria"}
                        />
                      </div>
                      <span></span>
                      <div className="pt-5">
                        <PageLink
                          page={
                            "/admin/services-requirements-criterias/" +
                            component?.uuid
                          }
                          title={"Back"}
                        />
                      </div>
                    </div>
                    <div></div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
      </main>
      <ScrollToTop />
    </div>
  );
};

export const AdminServiceRequirementsNew = function (props) {
  const WORKSPACE = " Components";
  const params = useParams();
  const dispatch = useDispatch();
  const [clicked, setclicked] = useState(false);
  const [btndisabled, setbtndisabled] = useState(false);
  const parentObject = useSelector((state) => state.services.service);
  // const loading = useSelector((state) => state.services.categoriesLoading);
  const object = useSelector((state) => state.services.requirement);
  const loading = useSelector((state) => state.services.requirementsLoading);
  useEffect(() => {
    if (params?.parentId && parentObject === null) {
      dispatch(actions.getOneServices(token, params?.parentId));
    }
    if (params?.objectId && object === null) {
      dispatch(actions.getOneServiceRequirements(token, params?.objectId));
    }
    if (object !== null) {
      settitle(object?.title);
      setbriefsummary(object?.briefsummary);
    }
  }, [object]);
  const [title, settitle] = useState("");
  const [briefsummary, setbriefsummary] = useState("");
  const [requirementsFile, setrequirementsFile] = useState(null);
  const userNames = useSelector((state) => state.auth.displayName);
  const token = useSelector((state) => state.auth.token);

  const fileSelectorHandler = (event) => {
    setrequirementsFile(event.target.files[0]);
  };

  const activating = (e) => {
    e.preventDefault();
    if (window.confirm("Are you sure you want change the status?")) {
      if (object?.status === constants.active) {
        dispatch(actions.desActivatingServiceRequirements(token, object?.uuid));
        successUpdateNotification(WORKSPACE, "Inactive");
      } else {
        dispatch(actions.activatingServiceRequirements(token, object?.uuid));
        successUpdateNotification(WORKSPACE, "Active");
      }
    } else {
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (title === "" || briefsummary === "") {
      setclicked(true);
    } else {
      if (object != null) {
        if (window.confirm("Are you sure you want update?")) {
          const obj = {
            title,
            briefsummary,
            id: object.uuid,editedBy:userNames,
          };
          dispatch(actions.updateServiceRequirements(token, obj));
          settitle("");
          setbriefsummary("");
          setclicked(false);
          successUpdateNotification(WORKSPACE, "Updated");
        } else {
        }
      } else {
        if (window.confirm("Are you sure you want submit?")) {
          const fd = new FormData();
          fd.append("requirementsFile", requirementsFile);
          fd.append("title", title);
          fd.append("briefsummary", briefsummary);
          fd.append("serviceid", parentObject?.uuid);
          fd.append("creator", userNames);
          dispatch(actions.createServiceRequirements(fd, token));
          settitle("");
          setbriefsummary("");
          setclicked(false);
          setbtndisabled(false);
          successCreationNotification(WORKSPACE);
        } else {
        }
      }
    }
  };

  return (
    <div className="">
      <Header />
      <ReactNotifications />
      <main id="main">
        <div className="breadcrumbs-modified d-flex align-items-center"></div>
        {loading ? (
          <MiSpinner />
        ) : (
          <section id="contact" className="input-data">
            <div className="container" data-aos="fade-up">
              <div className="row d-flex justify-content-end php-email-form">
                <div className="col-md-8">
                  <div className="">
                    <div className="">
                      {object !== null ? (
                        <>
                          <h5 className="bdf-bluefontcolor">
                            EDITING {WORKSPACE} IN {parentObject?.name} (
                            {parentObject?.language})
                          </h5>
                        </>
                      ) : (
                        <h5 className="bdf-bluefontcolor">
                          CREATE {WORKSPACE} IN {parentObject?.name} - (
                          {parentObject?.language})
                        </h5>
                      )}
                    </div>
                    <div className="form-group py-3">
                      <InputText2
                        title={"Title"}
                        value={title}
                        name="title"
                        placeholder="Title"
                        isClicked={clicked}
                        onChange={(e) => settitle(e.target.value)}
                      />
                    </div>
                    <div className="form-group py-3">
                      <InputTextArea
                        title={"Description"}
                        value={briefsummary}
                        name="briefsummary"
                        placeholder="Description"
                        isClicked={clicked}
                        onChange={(e) => setbriefsummary(e.target.value)}
                      />
                    </div>
                    <div className="form-group py-3">
                      <UplodadFile
                        accept=".pdf"
                        title="Requirements File"
                        updating={
                          object != null ? constants.isTrue : constants.isFalse
                        }
                        name="requirementsFile"
                        required
                        onChange={fileSelectorHandler}
                      />
                    </div>
                    <div className="row gx-5"></div>
                    <SubmitButton
                      disabled={btndisabled}
                      action={object === null ? "Create" : "Update"}
                      onSubmit={onSubmit}
                    />
                    <span className="px-5"></span>
                    <ActivateButton
                      status={object?.status}
                      onSubmit={activating}
                    />
                  </div>
                </div>
                <div className="col-md-3 d-flex gy-5 ml-5 justify-content-end">
                  <div>
                    <div className="flex flex-column h-50 text-center">
                      <div>
                        <PageLink
                          page={
                            "/admin/services-requirements/" + parentObject?.uuid
                          }
                          title={"Components List"}
                        />
                      </div>

                      <div className="d-none pt-5">
                        <PageLink
                          page={"/admin/services-requirements-new"}
                          title={"New Component"}
                        />
                      </div>
                      <div className="pt-5">
                        <PageLink
                          page={"/admin/services/objects/list"}
                          title={"Services"}
                        />
                      </div>
                    </div>
                    <div></div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
      </main>
      <ScrollToTop />
    </div>
  );
};

export const AdminServiceRequirementCriteriaNew = function (props) {
  const WORKSPACE = " Description";
  const params = useParams();
  const dispatch = useDispatch();
  const [clicked, setclicked] = useState(false);
  const [btndisabled, setbtndisabled] = useState(false);
  const service = useSelector((state) => state.services.service);
  const parentObject = useSelector((state) => state.services.requirement);
  // const loading = useSelector((state) => state.services.categoriesLoading);
  const object = useSelector((state) => state.services.requirementCriteria);
  const loading = useSelector(
    (state) => state.services.requirementCriteriasLoading
  );
  useEffect(() => {
    if (params?.parentId && parentObject === null) {
      dispatch(actions.getOneServiceRequirements(token, params?.parentId));
    }
    if (params?.objectId && object === null) {
      dispatch(actions.getOneRequirementsCriteria(token, params?.objectId));
    }
    if (object !== null) {
      setcriteriaName(object?.criteriaName);
    }
  }, [object]);
  const [criteriaName, setcriteriaName] = useState("");
  const userNames = useSelector((state) => state.auth.displayName);
  const token = useSelector((state) => state.auth.token);

  const activating = (e) => {
    e.preventDefault();
    if (window.confirm("Are you sure you want change the status?")) {
      if (object?.status === constants.active) {
        dispatch(
          actions.desActivatingRequirementsCriteria(token, object?.uuid)
        );
        successUpdateNotification(WORKSPACE, "Inactive");
      } else {
        dispatch(actions.activatingRequirementsCriteria(token, object?.uuid));
        successUpdateNotification(WORKSPACE, "Active");
      }
    } else {
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (criteriaName === "") {
      setclicked(true);
    } else {
      if (object != null) {
        if (window.confirm("Are you sure you want update?")) {
          const obj = {
            criteriaName,
            id: object.uuid,editedBy:userNames,
          };
          dispatch(actions.updateRequirementsCriteria(token, obj));
          setcriteriaName("");
          setclicked(false);
          successUpdateNotification(WORKSPACE, "Updated");
        } else {
        }
      } else {
        if (window.confirm("Are you sure you want submit?")) {
          const object = {
            creator: userNames,
            requirementid: parentObject?.uuid,
            criteriaName,
          };
          dispatch(actions.createRequirementsCriteria(object, token));
          setclicked(false);
          setbtndisabled(false);
          successCreationNotification(WORKSPACE);
        } else {
        }
      }
    }
  };

  return (
    <div className="">
      <Header />
      <ReactNotifications />
      <main id="main">
        <div className="breadcrumbs-modified d-flex align-items-center"></div>
        {loading ? (
          <MiSpinner />
        ) : (
          <section id="contact" className="input-data">
            <div className="container" data-aos="fade-up">
              <div className="row d-flex justify-content-end php-email-form">
                <div className="col-md-8">
                  <div className="">
                    <div className="">
                      {object !== null ? (
                        <>
                          <h4 className="bdf-bluefontcolor">
                            Service: {service?.name}
                          </h4>
                          <h5 className="bdf-bluefontcolor">
                            {}
                            {object?.title} ({object?.language}) {WORKSPACE}{" "}
                            LIST
                          </h5>
                          <h5 className="bdf-bluefontcolor">
                            EDITING {WORKSPACE} IN {parentObject?.title} (
                            {parentObject?.language})
                          </h5>
                        </>
                      ) : (
                        <>
                          <h4 className="bdf-bluefontcolor">
                            Service: {service?.name}
                          </h4>
                          <h5 className="bdf-bluefontcolor">
                            CREATE {WORKSPACE} IN {parentObject?.title} - (
                            {parentObject?.language})
                          </h5>
                        </>
                      )}
                    </div>
                    <div className="form-group py-3">
                      <InputText2
                        title={"Title"}
                        value={criteriaName}
                        name="criteriaName"
                        placeholder="Title"
                        isClicked={clicked}
                        onChange={(e) => setcriteriaName(e.target.value)}
                      />
                    </div>

                    <div className="row gx-5"></div>
                    <SubmitButton
                      disabled={btndisabled}
                      action={object === null ? "Create" : "Update"}
                      onSubmit={onSubmit}
                    />
                    <span className="px-5"></span>
                    <ActivateButton
                      status={object?.status}
                      onSubmit={activating}
                    />
                  </div>
                </div>
                <div className="col-md-3 d-flex gy-5 ml-5 justify-content-end">
                  <div>
                    <div className="flex flex-column h-50 text-center">
                      <div>
                        <PageLink
                          page={
                            "/admin/services-requirements-criterias/" +
                            parentObject?.uuid
                          }
                          title={"Components List"}
                        />
                      </div>
                      <div className="pt-5">
                        <PageLink
                          page={"/admin/services-requirements/" + service?.uuid}
                          title={"Service Components"}
                        />
                      </div>
                    </div>
                    <div></div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
      </main>
      <ScrollToTop />
    </div>
  );
};

export const AdminServiceRequirementCriteriasValuesNew = function (props) {
  const WORKSPACE = " Criterias";
  const params = useParams();
  const dispatch = useDispatch();
  const [clicked, setclicked] = useState(false);
  const [btndisabled, setbtndisabled] = useState(false);
  const service = useSelector((state) => state.services.service);
  const component = useSelector((state) => state.services.requirement);
  const parentObject = useSelector(
    (state) => state.services.requirementCriteria
  );
  // const loading = useSelector((state) => state.services.categoriesLoading);
  const object = useSelector((state) => state.services.criteriaValue);
  const loading = useSelector((state) => state.services.criteriaValuesLoading);
  useEffect(() => {
    if (params?.parentId && parentObject === null) {
      dispatch(actions.getOneRequirementsCriteria(token, params?.parentId));
    }
    if (params?.objectId && object === null) {
      dispatch(actions.getOneCriteriaValues(token, params?.objectId));
    }
    if (object !== null) {
      setvalue(eval(object?.value));
    }
  }, [object]);
  const [value, setvalue] = useState("");
  const userNames = useSelector((state) => state.auth.displayName);
  const token = useSelector((state) => state.auth.token);

  const activating = (e) => {
    e.preventDefault();
    if (window.confirm("Are you sure you want change the status?")) {
      if (object?.status === constants.active) {
        dispatch(actions.desActivatingCriteriaValues(token, object?.uuid));
        successUpdateNotification(WORKSPACE, "Inactive");
      } else {
        dispatch(actions.activatingCriteriaValues(token, object?.uuid));
        successUpdateNotification(WORKSPACE, "Active");
      }
    } else {
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (value === "") {
      setclicked(true);
    } else {
      if (object != null) {
        const richtext = richtextconstants?.separateTextAndFiles("");
        if (window.confirm("Are you sure you want update?")) {
          const obj = {
            value:richtext?.editorValue,
            id: object.uuid,editedBy:userNames,
          };
          dispatch(actions.updateCriteriaValues(token, obj));
          setvalue("");
          setclicked(false);
          successUpdateNotification(WORKSPACE, "Updated");
        } else {
        }
      } else {
        const richtext = richtextconstants?.separateTextAndFiles("");
        if (window.confirm("Are you sure you want submit?")) {
          const object = {
            creator: userNames,
            criteriaid: parentObject?.uuid,
            value:richtext?.editorValue,
          };
          dispatch(actions.createCriteriaValues(object, token));
          setclicked(false);
          setbtndisabled(false);
          successCreationNotification(WORKSPACE);
        } else {
        }
      }
    }
  };

  return (
    <div className="">
      <Header />
      <ReactNotifications />
      <main id="main">
        <div className="breadcrumbs-modified d-flex align-items-center"></div>
        {loading ? (
          <MiSpinner />
        ) : (
          <section id="contact" className="input-data">
            <div className="container" data-aos="fade-up">
              <div className="row d-flex justify-content-end php-email-form">
                <div className="col-md-8">
                  <div className="">
                    <div className="text-center">
                      {object !== null ? (
                        <>
                          <h4 className="bdf-bluefontcolor">
                            Service: {service?.name}
                          </h4>
                          <h5 className="bdf-bluefontcolor">
                            {}
                            Component: {component?.title}
                          </h5>
                          <h6 className="bdf-bluefontcolor">
                            EDITING {WORKSPACE} IN {parentObject?.criteriaName}{" "}
                            ({parentObject?.language})
                          </h6>
                        </>
                      ) : (
                        <>
                          <h4 className="bdf-bluefontcolor">
                            Service: {service?.name}
                          </h4>
                          <h5 className="bdf-bluefontcolor">
                            {}
                            Component: {component?.title}
                          </h5>
                          <h6 className="bdf-bluefontcolor">
                            CREATE {WORKSPACE} IN {parentObject?.criteriaName} -
                            ({parentObject?.language})
                          </h6>
                        </>
                      )}
                    </div>
                    <div className="form-group py-3 d-none">
                      <InputTextArea
                        title={"Critera Value"}
                        value={value}
                        name="value"
                        placeholder="Critera Value"
                        isClicked={clicked}
                        onChange={(e) => setvalue(e.target.value)}
                      />
                    </div>

                    <InputTextArea2nd
                    title={"Critera Value"}
                    value={value}
                    data={value}
                    name="value"
                    placeholder="Critera Value"
                    isClicked={clicked}
                    onChange={(e) => setvalue(e)}
                      />

                    <div className="row gx-5"></div>
                    <SubmitButton
                      disabled={btndisabled}
                      action={object === null ? "Create" : "Update"}
                      onSubmit={onSubmit}
                    />
                    <span className="px-5"></span>
                    <ActivateButton
                      status={object?.status}
                      onSubmit={activating}
                    />
                  </div>
                </div>
                <div className="col-md-3 d-flex gy-5 ml-5 justify-content-end">
                  <div>
                    <div className="flex flex-column h-50 text-center">
                      <div>
                        <PageLink
                          page={
                            "/admin/services-requirements-criterias-values/" +
                            parentObject?.uuid
                          }
                          title={"Components List"}
                        />
                      </div>
                      <div className="pt-5">
                        <PageLink
                          page={"/admin/services-requirements/" + service?.uuid}
                          title={"Service Components"}
                        />
                      </div>
                    </div>
                    <div></div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
      </main>
      <ScrollToTop />
    </div>
  );
};
