import { useTranslation } from "react-i18next";
import * as actions from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { ScrollToTop } from "../../components/backtotop";
import Header from "../components/header";
import Modal from "react-bootstrap/Modal";
import {
  ActivateButton,
  InputText,
  InputText2,
  InputTextArea,
  InputTextSecret,
  PageLink,
  SubmitButton,
  UplodadFile,
} from "../components/inputs";
import { LanguagesTable } from "../components/languagestable";
import {
  successCreationNotification,
  successNotification,
  successUpdateNotification,
} from "../../components/notifications";
import { ReactNotifications } from "react-notifications-component";
import classnames from "classnames";
import { useHistory, useParams } from "react-router-dom";
import MiSpinner from "../../components/spinner";
import constants from "../../components/constants";
import { getLanguages } from "../../store/actions";
import { set } from "lodash";
import { InputTextArea2nd } from "../components/inputs2";
import richtextconstants from "../../components/richtextconstants";

export const AdminHome = function (props) {
  const WORKSPACE = "HOME";
  const history = useHistory();
  const params = useParams();
  const [counter, setcounter] = useState(0);
  const dispatch = useDispatch();
  const [clicked, setclicked] = useState(false);
  const [btndisabled, setbtndisabled] = useState(false);
  const object = useSelector((state) => state.homepage.home);
  const loading = useSelector((state) => state.homepage.homesLoading);
  useEffect(() => {
    dispatch(getLanguages());
    if (params?.objectId && object === null) {
      dispatch(actions.getOneHomes(token, params?.objectId));
    }
    if (object !== null) {
      settitle(object?.title);
      setdescription(object?.description);
    }
  }, [object]);
  const [title, settitle] = useState("");
  const [description, setdescription] = useState("");
  const languages = useSelector((state) => state.languages.languages);
  const userNames = useSelector((state) => state.auth.displayName);
  const token = useSelector((state) => state.auth.token);
  const [objects, setObjects] = useState([]);

  const activating = (e) => {
    e.preventDefault();
    if (window.confirm("Are you sure you want change the status?")) {
      if (object?.status === constants.active) {
        dispatch(actions.desActivatingHomes(token, object?.uuid));
        successUpdateNotification(WORKSPACE, "Inactive");
      } else {
        dispatch(actions.activatingHomes(token, object?.uuid));
        successUpdateNotification(WORKSPACE, "Active");
      }
    } else {
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (title === "" || description === "") {
      setclicked(true);
    } else {
      if (object != null) {
        if (window.confirm("Are you sure you want update?")) {
          const obj = {
            title,
            description,
            id: object.uuid,editedBy:userNames,
          };
         // dispatch(actions.updateHomes(token, obj));
          settitle("");
          setdescription("");
          setclicked(false);
          successUpdateNotification(WORKSPACE, "Updated");
        } else {
        }
      } else {
        if (counter < languages.length) {
          const lang = languages[counter]
            ? languages[counter].abbreviation
            : "";
          const languageName = languages[counter]
            ? languages[counter].name
            : "";
          const subs = setObjects([
            ...objects,
            {
              title,
              description,
              creator: userNames,
              language: lang,
            },
          ]);
          setcounter(counter + 1);
          successNotification(WORKSPACE, languageName);
          settitle("");
          setdescription("");
          setclicked(false);
        }
        if (counter == languages.length - 1) {
          settitle(title);
          setdescription(description);
          setbtndisabled(true);
        }
        if (counter == languages.length) {
          if (window.confirm("Are you sure you want submit?")) {
            console.log("object");
            console.log(object);
            console.log("object");
          //  dispatch(actions.createHomes(objects, token));
            setcounter(0);
            setObjects([]);
            settitle("");
            setdescription("");
            setclicked(false);
            setbtndisabled(false);
            successCreationNotification(WORKSPACE);
            history.push("/admin/home/objects/list");
          } else {
          }
        }
      }
    }
  };

  return (
    <div className="">
      <Header />
      <ReactNotifications />
      <main id="main">
        <div className="breadcrumbs-modified d-flex align-items-center"></div>
        {loading ? (
          <MiSpinner />
        ) : (
          <section id="contact" className="input-data">
            <div className="container" data-aos="fade-up">
              <div className="row d-flex justify-content-end php-email-form">
                <div className="col-md-8">
                  <div className="">
                    <div className="">
                      {object !== null ? (
                        <>
                          <h5 className="bdf-bluefontcolor">
                            EDIT {WORKSPACE} ({object?.language})
                          </h5>
                        </>
                      ) : (
                        <h5 className="bdf-bluefontcolor">
                          CREATE {WORKSPACE} IN: {languages[counter]?.name}
                        </h5>
                      )}
                    </div>
                    <div className="form-group py-3">
                      <InputText2
                        title={"Title"}
                        value={title}
                        name="title"
                        placeholder="Title"
                        isClicked={clicked}
                        onChange={(e) => settitle(e.target.value)}
                      />
                    </div>
                    <div className="form-group py-3">
                      <InputTextArea
                        isClicked={clicked}
                        title={"Description"}
                        placeholder="Description"
                        value={description}
                        name="description"
                        onChange={(e) => setdescription(e.target.value)}
                      />
                    </div>
                    <div className="row gx-5"></div>
                    <SubmitButton
                      disabled={btndisabled}
                      action={object === null ? "Create" : "Update"}
                      onSubmit={onSubmit}
                    />
                    <span className="px-2"></span>
                    <SubmitButton
                      disabled={!btndisabled}
                      classes={object === null ? "bg-success" : "d-none"}
                      action={"Submit"}
                      onSubmit={onSubmit}
                    />
                    <span className="px-5"></span>
                    <ActivateButton
                      status={object?.status}
                      onSubmit={activating}
                    />
                  </div>
                </div>
                <div className="col-md-3 d-flex gy-5 ml-5 justify-content-end">
                  <div>
                    <div className="flex flex-column h-50">
                      <PageLink
                        page={"/admin/home/objects/list"}
                        title={"List"}
                      />
                    </div>
                    <div>
                      <LanguagesTable languages={languages} counter={counter} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
      </main>
      <ScrollToTop />
    </div>
  );
};

export const AdminHomeAchievementsTitle = function (props) {
  const WORKSPACE = "HOME ACHIEVEMENT TITLE";
  const history = useHistory();
  const params = useParams();
  const [counter, setcounter] = useState(0);
  const dispatch = useDispatch();
  const [clicked, setclicked] = useState(false);
  const [btndisabled, setbtndisabled] = useState(false);
  const object = useSelector((state) => state.homepage.achievementTitle);
  const loading = useSelector(
    (state) => state.homepage.achievementTitlesLoading
  );
  useEffect(() => {
    dispatch(getLanguages());
    if (params?.objectId && object === null) {
      dispatch(actions.getOneHomeAchievementTitles(token, params?.objectId));
    }
    if (object !== null) {
      settitle(object?.title);
    }
  }, [object]);
  const [title, settitle] = useState("");
  const languages = useSelector((state) => state.languages.languages);
  const userNames = useSelector((state) => state.auth.displayName);
  const token = useSelector((state) => state.auth.token);
  const [objects, setObjects] = useState([]);

  const activating = (e) => {
    e.preventDefault();
    if (window.confirm("Are you sure you want change the status?")) {
      if (object?.status === constants.active) {
        dispatch(
          actions.desActivatingHomeAchievementTitles(token, object?.uuid)
        );
        successUpdateNotification(WORKSPACE, "Inactive");
      } else {
        dispatch(actions.activatingHomeAchievementTitles(token, object?.uuid));
        successUpdateNotification(WORKSPACE, "Active");
      }
    } else {
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (title === "") {
      setclicked(true);
    } else {
      if (object != null) {
        if (window.confirm("Are you sure you want update?")) {
          const obj = {
            title,
            id: object.uuid,editedBy:userNames,
          };
          dispatch(actions.updateHomeAchievementTitles(token, obj));
          settitle("");
          setclicked(false);
          successUpdateNotification(WORKSPACE, "Updated");
        } else {
        }
      } else {
        if (counter < languages.length) {
          const lang = languages[counter]
            ? languages[counter].abbreviation
            : "";
          const languageName = languages[counter]
            ? languages[counter].name
            : "";
          const subs = setObjects([
            ...objects,
            {
              title,
              creator: userNames,
              language: lang,
            },
          ]);
          setcounter(counter + 1);
          successNotification(WORKSPACE, languageName);
          settitle("");
          setclicked(false);
        }
        if (counter == languages.length - 1) {
          settitle(title);
          setbtndisabled(true);
        }
        if (counter == languages.length) {
          if (window.confirm("Are you sure you want submit?")) {
            dispatch(actions.createHomeAchievementTitles(objects, token));
            setcounter(0);
            setObjects([]);
            object = null;
            settitle("");
            setclicked(false);
            setbtndisabled(false);
            successCreationNotification(WORKSPACE);
            history.push("/admin/home-achievements/objects/list");
          } else {
          }
        }
      }
    }
  };

  return (
    <div className="">
      <Header />
      <ReactNotifications />
      <main id="main">
        <div className="breadcrumbs-modified d-flex align-items-center"></div>
        {loading ? (
          <MiSpinner />
        ) : (
          <section id="contact" className="input-data">
            <div className="container" data-aos="fade-up">
              <div className="row d-flex justify-content-end php-email-form">
                <div className="col-md-8">
                  <div className="">
                    <div className="">
                      {object !== null ? (
                        <>
                          <h5 className="bdf-bluefontcolor">
                            EDIT {WORKSPACE} ({object?.language})
                          </h5>
                        </>
                      ) : (
                        <h5 className="bdf-bluefontcolor">
                          CREATE {WORKSPACE} IN: {languages[counter]?.name}
                        </h5>
                      )}
                    </div>
                    <div className="form-group py-3">
                      <InputText2
                        title={"Title"}
                        value={title}
                        name="title"
                        placeholder="Title"
                        isClicked={clicked}
                        onChange={(e) => settitle(e.target.value)}
                      />
                    </div>
                    <div className="row gx-5"></div>
                    <SubmitButton
                      disabled={btndisabled}
                      action={object === null ? "Create" : "Update"}
                      onSubmit={onSubmit}
                    />
                    <span className="px-2"></span>
                    <SubmitButton
                      disabled={!btndisabled}
                      classes={object === null ? "bg-success" : "d-none"}
                      action={"Submit"}
                      onSubmit={onSubmit}
                    />
                    <span className="px-5"></span>
                    <ActivateButton
                      status={object?.status}
                      onSubmit={activating}
                    />
                  </div>
                </div>
                <div className="col-md-3 d-flex gy-5 ml-5 justify-content-end">
                  <div>
                    <div className="flex flex-column h-50">
                      <PageLink
                        page={"/admin/home-achievements/objects/list"}
                        title={"List"}
                      />
                    </div>
                    <div>
                      <LanguagesTable languages={languages} counter={counter} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
      </main>
      <ScrollToTop />
    </div>
  );
};

export const AdminHomeAchievements = function (props) {
  const WORKSPACE = "HOME ACHIEVEMENT";
  const history = useHistory();
  const params = useParams();
  const [counter, setcounter] = useState(0);
  const dispatch = useDispatch();
  const [clicked, setclicked] = useState(false);
  const [btndisabled, setbtndisabled] = useState(false);
  const object = useSelector((state) => state.homepage.achievement);
  const loading = useSelector((state) => state.homepage.achievementsLoading);
  useEffect(() => {
    dispatch(getLanguages());
    if (params?.objectId && object === null) {
      dispatch(actions.getOneHomeAchievements(token, params?.objectId));
    }
    if (object !== null) {
      setname(object?.name);
    }
  }, [object]);
  const [name, setname] = useState("");
  const languages = useSelector((state) => state.languages.languages);
  const userNames = useSelector((state) => state.auth.displayName);
  const token = useSelector((state) => state.auth.token);
  const [objects, setObjects] = useState([]);

  const activating = (e) => {
    e.preventDefault();
    if (window.confirm("Are you sure you want change the status?")) {
      if (object?.status === constants.active) {
        dispatch(actions.desActivatingHomeAchievements(token, object?.uuid));
        successUpdateNotification(WORKSPACE, "Inactive");
      } else {
        dispatch(actions.activatingHomeAchievements(token, object?.uuid));
        successUpdateNotification(WORKSPACE, "Active");
      }
    } else {
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (name === "") {
      setclicked(true);
    } else {
      if (object != null) {
        if (window.confirm("Are you sure you want update?")) {
          const obj = {
            name,
            id: object.uuid,editedBy:userNames,
          };
          dispatch(actions.updateHomeAchievements(token, obj));
          setname("");
          setclicked(false);
          successUpdateNotification(WORKSPACE, "Updated");
        } else {
        }
      } else {
        if (counter < languages.length) {
          const lang = languages[counter]
            ? languages[counter].abbreviation
            : "";
          const languageName = languages[counter]
            ? languages[counter].name
            : "";
          const subs = setObjects([
            ...objects,
            {
              name,
              creator: userNames,
              language: lang,
            },
          ]);
          setcounter(counter + 1);
          successNotification(WORKSPACE, languageName);
          setname("");
          setclicked(false);
        }
        if (counter == languages.length - 1) {
          setname(name);
          setbtndisabled(true);
        }
        if (counter == languages.length) {
          if (window.confirm("Are you sure you want submit?")) {
            dispatch(actions.createHomeAchievements(objects, token));
            setcounter(0);
            setObjects([]);
            object = null;
            setname("");
            setclicked(false);
            setbtndisabled(false);
            successCreationNotification(WORKSPACE);
            history.push("/admin/home-achievements-list/objects/list");
          } else {
          }
        }
      }
    }
  };

  return (
    <div className="">
      <Header />
      <ReactNotifications />
      <main id="main">
        <div className="breadcrumbs-modified d-flex align-items-center"></div>
        {loading ? (
          <MiSpinner />
        ) : (
          <section id="contact" className="input-data">
            <div className="container" data-aos="fade-up">
              <div className="row d-flex justify-content-end php-email-form">
                <div className="col-md-8">
                  <div className="">
                    <div className="">
                      {object !== null ? (
                        <>
                          <h5 className="bdf-bluefontcolor">
                            EDIT {WORKSPACE} ({object?.language})
                          </h5>
                        </>
                      ) : (
                        <h5 className="bdf-bluefontcolor">
                          CREATE {WORKSPACE} IN: {languages[counter]?.name}
                        </h5>
                      )}
                    </div>
                    <div className="form-group py-3">
                      <InputText2
                        title={"Name"}
                        value={name}
                        name="name"
                        placeholder="Name"
                        isClicked={clicked}
                        onChange={(e) => setname(e.target.value)}
                      />
                    </div>
                    <div className="row gx-5"></div>
                    <SubmitButton
                      disabled={btndisabled}
                      action={object === null ? "Create" : "Update"}
                      onSubmit={onSubmit}
                    />
                    <span className="px-2"></span>
                    <SubmitButton
                      disabled={!btndisabled}
                      classes={object === null ? "bg-success" : "d-none"}
                      action={"Submit"}
                      onSubmit={onSubmit}
                    />
                    <span className="px-5"></span>
                    <ActivateButton
                      status={object?.status}
                      onSubmit={activating}
                    />
                  </div>
                </div>
                <div className="col-md-3 d-flex gy-5 ml-5 justify-content-end">
                  <div>
                    <div className="flex flex-column h-50">
                      <PageLink
                        page={"/admin/home-achievements-list/objects/list"}
                        title={"List"}
                      />
                    </div>
                    <div>
                      <LanguagesTable languages={languages} counter={counter} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
      </main>
      <ScrollToTop />
    </div>
  );
};

export const Partners = function (props) {
  const WORKSPACE = "PARTNERS";
  const history = useHistory();
  const params = useParams();
  const [counter, setcounter] = useState(0);
  const dispatch = useDispatch();
  const [clicked, setclicked] = useState(false);
  const [btndisabled, setbtndisabled] = useState(false);
  const object = useSelector((state) => state.homepage.partner);
  const loading = useSelector((state) => state.homepage.partnersLoading);
  useEffect(() => {
    if (params?.objectId && object === null) {
      dispatch(actions.getOnePartners(token, params?.objectId));
    }
    if (object !== null) {
      setname(object?.name);
    }
  }, [object]);
  const [name, setname] = useState("");
  const [logo, setlogo] = useState(null);
  const userNames = useSelector((state) => state.auth.displayName);
  const token = useSelector((state) => state.auth.token);
  // const [objects, setObjects] = useState([]);

  const activating = (e) => {
    e.preventDefault();
    if (window.confirm("Are you sure you want change the status?")) {
      if (object?.status === constants.active) {
        dispatch(actions.desActivatingPartners(token, object?.uuid));
        successUpdateNotification(WORKSPACE, "Inactive");
      } else {
        dispatch(actions.activatingPartners(token, object?.uuid));
        successUpdateNotification(WORKSPACE, "Active");
      }
    } else {
    }
  };

  const fileSelectorHandler = (event) => {
    setlogo(event.target.files[0]);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (name === "") {
      setclicked(true);
    } else {
      if (object != null) {
        if (window.confirm("Are you sure you want update?")) {
          const obj = {
            name,
            id: object.uuid,editedBy:userNames,
          };
          dispatch(actions.updatePartners(token, obj));
          setname("");
          setclicked(false);
          successUpdateNotification(WORKSPACE, "Updated");
        } else {
        }
      } else {
        if (window.confirm("Are you sure you want submit?")) {
          const fd = new FormData();
          fd.append("logo", logo);
          fd.append("name", name);
          fd.append("creator", userNames);
          dispatch(actions.createPartners(fd, token));
          setname("");
          setlogo(null);
          setclicked(false);
          setbtndisabled(false);
          successCreationNotification(WORKSPACE);
        } else {
        }
      }
    }
  };

  return (
    <div className="">
      <Header />
      <ReactNotifications />
      <main id="main">
        <div className="breadcrumbs-modified d-flex align-items-center"></div>
        {loading ? (
          <MiSpinner />
        ) : (
          <section id="contact" className="input-data">
            <div className="container" data-aos="fade-up">
              <div className="row d-flex justify-content-end php-email-form">
                <div className="col-md-8">
                  <div className="">
                    <div className="">
                      {object !== null ? (
                        <>
                          <h5 className="bdf-bluefontcolor">
                            EDIT {WORKSPACE}
                          </h5>
                        </>
                      ) : (
                        <h5 className="bdf-bluefontcolor">
                          CREATE {WORKSPACE}
                        </h5>
                      )}
                    </div>
                    <div className="form-group py-3">
                      <InputText2
                        title={"Name"}
                        value={name}
                        name="name"
                        placeholder="Name"
                        isClicked={clicked}
                        onChange={(e) => setname(e.target.value)}
                      />
                    </div>
                    <div className="form-group py-3">
                      <UplodadFile
                        title="Logo"
                        updating={
                          object != null ? constants.isTrue : constants.isFalse
                        }
                        name="logo"
                        required
                        onChange={fileSelectorHandler}
                      />
                    </div>
                    <div className="row gx-5"></div>
                    <SubmitButton
                      disabled={btndisabled}
                      action={object === null ? "Create" : "Update"}
                      onSubmit={onSubmit}
                    />

                    <span className="px-5"></span>
                    <ActivateButton
                      status={object?.status}
                      onSubmit={activating}
                    />
                  </div>
                </div>
                <div className="col-md-3 d-flex gy-5 ml-5 justify-content-end">
                  <div>
                    <div className="flex flex-column h-50">
                      <PageLink
                        page={"/admin/partners/objects/list"}
                        title={"List"}
                      />
                    </div>
                    <div></div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
      </main>
      <ScrollToTop />
    </div>
  );
};
