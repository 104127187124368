import * as actions from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { ScrollToTop } from "../../components/backtotop";
import Header from "../components/header";
import {
  EditButton,
  InputTextSecret2,
  PageLink,
  SelectInput,
  ViewButton,
} from "../components/inputs";
import Modal from "react-bootstrap/Modal";
import moment from "moment";
import { ReactNotifications } from "react-notifications-component";
import classnames from "classnames";
import MiSpinner from "../../components/spinner";
import { ImageCard, ObjectDetails } from "../components/output";
import {
  ActivateButton,
  InputText,
  InputText2,
  InputTextArea,
  InputTextSecret,
  SelectRadioButton,
  SubmitButton,
  UplodadFile,
} from "../components/inputs";
import { LanguagesTable } from "../components/languagestable";
import { useHistory, useParams } from "react-router-dom";
import constants from "../../components/constants";
import { getLanguages } from "../../store/actions";
import { set } from "lodash";
import {
  successCreationNotification,
  successNotification,
  successUpdateNotification,
} from "../../components/notifications";

export const AdminLanguages = function (props) {
  const WORKSPACE = "Language";
  const history = useHistory();
  const params = useParams();
  const [counter, setcounter] = useState(0);
  const dispatch = useDispatch();
  const [clicked, setclicked] = useState(false);
  const [btndisabled, setbtndisabled] = useState(false);
  const object = useSelector((state) => state.languages.language);
  const loading = useSelector((state) => state.languages.languagesLoading);
  useEffect(() => {
    if (params?.objectId && object === null) {
      dispatch(actions.getOneLanguages(token, params?.objectId));
    }
    if (object !== null) {
      setname(object?.name);
      setabbreviation(object?.abbreviation);
    }
  }, [object]);
  const [name, setname] = useState("");
  const [abbreviation, setabbreviation] = useState("");
  const languages = useSelector((state) => state.languages.languages);
  const userNames = useSelector((state) => state.auth.displayName);
  const token = useSelector((state) => state.auth.token);

  const activating = (e) => {
    e.preventDefault();
    if (window.confirm("Are you sure you want change the status?")) {
      if (object?.status === constants.active) {
        dispatch(actions.desActivatingLanguages(token, object?.uuid));
        successUpdateNotification(WORKSPACE, "Inactive");
      } else {
        dispatch(actions.activatingLanguages(token, object?.uuid));
        successUpdateNotification(WORKSPACE, "Active");
      }
    } else {
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (name === "" || abbreviation === "") {
      setclicked(true);
    } else {
      if (object != null) {
        if (window.confirm("Are you sure you want update?")) {
          const obj = {
            name,
            abbreviation,
            id: object.uuid,editedBy:userNames,
          };
          dispatch(actions.updateLanguages(token, obj));
          setname("");
          setabbreviation("");
          setclicked(false);
          successUpdateNotification(WORKSPACE, "Updated");
        } else {
        }
      } else {
        if (counter == languages.length) {
          if (window.confirm("Are you sure you want submit?")) {
            const objects = {
              name,
              abbreviation,
              creator: userNames,
            };
            dispatch(actions.createLanguages(objects, token));
            setname("");
            setabbreviation("");
            setclicked(false);
            setbtndisabled(false);
            successCreationNotification(WORKSPACE);
          } else {
          }
        }
      }
    }
  };

  return (
    <div className="">
      <Header />
      <ReactNotifications />
      <main id="main">
        <div className="breadcrumbs-modified d-flex align-items-center"></div>
        {loading ? (
          <MiSpinner />
        ) : (
          <section id="contact" className="input-data">
            <div className="container" data-aos="fade-up">
              <div className="row d-flex justify-content-end php-email-form">
                <div className="col-md-8">
                  <div className="">
                    <div className="">
                      {object !== null ? (
                        <>
                          <h5 className="bdf-bluefontcolor">
                            EDIT {WORKSPACE}
                          </h5>
                        </>
                      ) : (
                        <h5 className="bdf-bluefontcolor">
                          CREATE {WORKSPACE}
                        </h5>
                      )}
                    </div>
                    <div className="form-group py-3">
                      <InputText2
                        title={"Title"}
                        value={name}
                        name="name"
                        placeholder="Title"
                        isClicked={clicked}
                        onChange={(e) => setname(e.target.value)}
                      />
                    </div>
                    <div className="form-group py-3">
                      <InputText2
                        isClicked={clicked}
                        title={"Abbreviation"}
                        placeholder="Abbreviation"
                        value={abbreviation}
                        name="abbreviation"
                        onChange={(e) => setabbreviation(e.target.value)}
                      />
                    </div>

                    <div className="row gx-5"></div>
                    <SubmitButton
                      disabled={btndisabled}
                      action={object === null ? "Create" : "Update"}
                      onSubmit={onSubmit}
                    />
                    <span className="px-5"></span>
                    <ActivateButton
                      status={object?.status}
                      onSubmit={activating}
                    />
                  </div>
                </div>
                <div className="col-md-3 d-flex gy-5 ml-5 justify-content-end">
                  <div>
                    <div className="flex flex-column h-50">
                      <PageLink
                        page={"/admin/languages/objects/list"}
                        title={"List"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
      </main>
      <ScrollToTop />
    </div>
  );
};

export const AdminLanguagesList = function (props) {
  const WORKSPACE = "LANGUAGES";
  const loading = useSelector((state) => state.languages.languagesLoading);
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);
  const object = useSelector((state) => state.languages.language);
  const objectList = useSelector((state) => state.languages.languages);

  useEffect(() => {
    dispatch(actions.getLanguages());
  }, []);

  const [show, setShow] = useState(false);
  const handleClose = (e) => {
    e.preventDefault();
    dispatch(actions.getLanguages());
    setShow(false);
  };

  const viewMore = (id) => {
    dispatch(actions.getOneLanguages(token, id));
    setShow(true);
  };

  return (
    <div className="">
      <Header />
      <ReactNotifications />
      <main id="main">
        <div className="breadcrumbs-modified d-flex align-items-center"></div>
        {loading ? (
          <MiSpinner />
        ) : (
          <>
            <section id="contact" className="input-data">
              <div className="container" data-aos="fade-up">
                <div className="row d-flex justify-content-end php-email-form">
                  <div className="col-md-8">
                    <div className="text-center">
                      <h5 className="bdf-bluefontcolor">{WORKSPACE} LIST</h5>
                    </div>
                    <table className="table table-striped">
                      <thead>
                        <tr className="bdf-bluefontcolor">
                          <th>#</th>
                          <th>Name</th>
                          <th>Abbreviation</th>
                          <th>Status</th>
                          <th>Creator</th>
                          <th className="text-center">Action</th>
                        </tr>
                      </thead>
                      <tbody className="">
                        {objectList?.map((data, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{data?.name}</td>
                            <td>{data?.abbreviation}</td>
                            <td>{data?.status}</td>
                            <td>{data?.creator}</td>
                            <td className="row d-flex justify-content-center">
                              <EditButton
                                title="Edit"
                                page="/admin/languages"
                                id={data?.uuid}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div className="col-md-3 d-flex gy-5 ml-5 justify-content-end">
                    <div>
                      <div className="flex flex-column h-50">
                        <PageLink
                          page="/admin/languages"
                          title={"New Language"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </>
        )}
      </main>
      <ScrollToTop />
    </div>
  );
};

export const AdminUsers = function (props) {
  const WORKSPACE = "Users";
  const history = useHistory();
  const params = useParams();
  const [counter, setcounter] = useState(0);
  const dispatch = useDispatch();
  const [clicked, setclicked] = useState(false);
  const [btndisabled, setbtndisabled] = useState(false);
  const object = useSelector((state) => state.auth.user);
  const loading = useSelector((state) => state.auth.loading);
  useEffect(() => {
    if (params?.objectId && object === null) {
      dispatch(actions.getOneUsers(token, params?.objectId));
    }
    if (object !== null) {
      setfullName(object?.fullName);
      settelephone(object?.telephone);
      settitle(object?.title);
      setrole(object?.role);
      setusername(object?.username);
    }
  }, [object]);
  const [fullName, setfullName] = useState("");
  const [title, settitle] = useState("");
  const [role, setrole] = useState("");
  const [username, setusername] = useState("");
  const [telephone, settelephone] = useState("");
  const languages = useSelector((state) => state.languages.languages);
  const userNames = useSelector((state) => state.auth.displayName);
  const token = useSelector((state) => state.auth.token);

  const roles = [
    { uuid: "admin", name: "admin" },
    { uuid: "user", name: "user" },
  ];

  const setTheRole = (value) => {
    setrole(value);
  };

  const activating = (e) => {
    e.preventDefault();
    if (window.confirm("Are you sure you want change the status?")) {
      if (object?.status === constants.active) {
        dispatch(actions.desActivatingUsers(token, object?.uuid));
        successUpdateNotification(WORKSPACE, "Inactive");
      } else {
        dispatch(actions.activatingUsers(token, object?.uuid));
        successUpdateNotification(WORKSPACE, "Active");
      }
    } else {
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (
      fullName === "" ||
      title === "" ||
      role === "" ||
      telephone === "" ||
      username === ""
    ) {
      setclicked(true);
    } else {
      if (object != null) {
        if (window.confirm("Are you sure you want update?")) {
          const obj = {
            fullName,
            title,
            role,
            telephone,
            id: object.uuid,editedBy:userNames,
          };
          dispatch(actions.updateUsers(token, obj));
          setfullName("");
          settelephone("");
          settitle("");
          setrole("");
          setusername("");
          setclicked(false);
          successUpdateNotification(WORKSPACE, "Updated");
        } else {
        }
      } else {
        if (window.confirm("Are you sure you want submit?")) {
          const obj = {
            fullName,
            title,
            role,
            username,
            telephone,
            creator: userNames,
          };
          dispatch(actions.createUsers(obj, token));
          setfullName("");
          settelephone("");
          settitle("");
          setrole("");
          setusername("");
          setclicked(false);
          setbtndisabled(false);
          successCreationNotification(WORKSPACE);
        } else {
        }
      }
    }
  };

  return (
    <div className="">
      <Header />
      <ReactNotifications />
      <main id="main">
        <div className="breadcrumbs-modified d-flex align-items-center"></div>
        {loading ? (
          <MiSpinner />
        ) : (
          <section id="contact" className="input-data">
            <div className="container" data-aos="fade-up">
              <div className="row d-flex justify-content-end php-email-form">
                <div className="col-md-8">
                  <div className="">
                    <div className="">
                      {object !== null ? (
                        <>
                          <h5 className="bdf-bluefontcolor">
                            EDIT {WORKSPACE}
                          </h5>
                        </>
                      ) : (
                        <h5 className="bdf-bluefontcolor">
                          CREATE {WORKSPACE}
                        </h5>
                      )}
                    </div>
                    <div className="form-group py-1">
                      <InputText2
                        title={"Names"}
                        value={fullName}
                        name="fullName"
                        placeholder="Names"
                        isClicked={clicked}
                        onChange={(e) => setfullName(e.target.value)}
                      />
                    </div>
                    <div className="form-group py-1">
                      <InputText2
                        isClicked={clicked}
                        title={"Title"}
                        placeholder="Title"
                        value={title}
                        name="title"
                        onChange={(e) => settitle(e.target.value)}
                      />
                    </div>
                    <div className="form-group py-1">
                      <InputText2
                        isClicked={clicked}
                        title={"Telephone"}
                        placeholder="Telephone"
                        value={telephone}
                        name="telephone"
                        onChange={(e) => settelephone(e.target.value)}
                      />
                    </div>
                    <div className="form-group py-1">
                      <InputText2
                        title={"E-mail/username"}
                        value={username}
                        name="username"
                        placeholder="E-mail/username"
                        isClicked={clicked}
                        onChange={(e) => setusername(e.target.value)}
                      />
                    </div>
                    <div className="form-group col-md-8">
                      <SelectInput
                        title="Select Role"
                        startingValue={"SELECT"}
                        options={roles}
                        value={role}
                        changed={setTheRole}
                      />
                    </div>

                    <div className="row gx-5"></div>
                    <SubmitButton
                      disabled={btndisabled}
                      action={object === null ? "Create" : "Update"}
                      onSubmit={onSubmit}
                    />
                    <span className="px-5"></span>
                    <ActivateButton
                      status={object?.status}
                      onSubmit={activating}
                    />
                  </div>
                </div>
                <div className="col-md-3 d-flex gy-5 ml-5 justify-content-end">
                  <div>
                    <div className="flex flex-column h-50">
                      <PageLink
                        page={"/admin/users/objects/list"}
                        title={"List"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
      </main>
      <ScrollToTop />
    </div>
  );
};

export const AdminUsersList = function (props) {
  const WORKSPACE = "Users";
  const loading = useSelector((state) => state.auth.loading);
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);
  const object = useSelector((state) => state.auth.user);
  const objectList = useSelector((state) => state.auth.users);

  useEffect(() => {
    dispatch(actions.getUsers());
  }, []);

  const [show, setShow] = useState(false);
  const handleClose = (e) => {
    e.preventDefault();
    dispatch(actions.getUsers());
    setShow(false);
  };

  const viewMore = (id) => {
    dispatch(actions.getOneUsers(token, id));
    setShow(true);
  };

  return (
    <div className="">
      <Header />
      <ReactNotifications />
      <main id="main">
        <div className="breadcrumbs-modified d-flex align-items-center"></div>
        {loading ? (
          <MiSpinner />
        ) : (
          <>
            <section id="contact" className="input-data">
              <div className="container" data-aos="fade-up">
                <div className="row d-flex justify-content-end php-email-form">
                  <div className="col-md-8">
                    <div className="text-center">
                      <h5 className="bdf-bluefontcolor">{WORKSPACE} LIST</h5>
                    </div>
                    <table className="table table-striped">
                      <thead>
                        <tr className="bdf-bluefontcolor">
                          <th>#</th>
                          <th>Name</th>
                          <th>Title</th>
                          <th>Telephone</th>
                          <th>Role</th>
                          <th>Username</th>
                          <th>Status</th>
                          <th className="text-center">Action</th>
                        </tr>
                      </thead>
                      <tbody className="">
                        {objectList?.map((data, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{data?.fullName}</td>
                            <td>{data?.title}</td>
                            <td>{data?.telephone}</td>
                            <td>{data?.role}</td>
                            <td>{data?.username}</td>
                            <td>{data?.status}</td>
                            <td className="row d-flex justify-content-center">
                              <EditButton
                                title="Edit"
                                page="/admin/users"
                                id={data?.uuid}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div className="col-md-3 d-flex gy-5 ml-5 justify-content-end">
                    <div>
                      <div className="flex flex-column h-50">
                        <PageLink page="/admin/users" title={"New User"} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </>
        )}
      </main>
      <ScrollToTop />
    </div>
  );
};

export const AdminUserProfile = function (props) {
  const WORKSPACE = "USER PROFILE";
  const loading = useSelector((state) => state.auth.loading);
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);
  const user = useSelector((state) => state.auth.user);
  const id = useSelector((state) => state.auth.userId);
  const object = useSelector((state) => state.auth.user);
  const objectList = useSelector((state) => state.auth.users);

  const [pswd, setpswd] = useState("");
  const [cfrmpswd, setcfrmpswd] = useState("");
  const [error, seterror] = useState();
  const [clicked, setclicked] = useState(false);

  useEffect(() => {
    dispatch(actions.getOneUsers(token, id));
  }, []);

  const changepswd = (e) => {
    e.preventDefault();
    if (pswd === "" || cfrmpswd === "") {
      setclicked(true);
    } else if (pswd !== cfrmpswd && pswd.length !== cfrmpswd.length) {
      seterror("Password mismatch");
    } else {
      if (window.confirm("Are you sure you want submit?")) {
        const obj = {
          password: pswd,
          id,
        };
        dispatch(actions.changePassword(obj, token));
        setpswd("");
        setcfrmpswd("");
        setclicked(false);
        successCreationNotification(WORKSPACE);
      } else {
      }
    }
  };

  const [show, setShow] = useState(false);
  const handleClose = (e) => {
    e.preventDefault();
    dispatch(actions.getUsers());
    setShow(false);
  };

  const viewMore = (id) => {
    dispatch(actions.getOneUsers(token, id));
    setShow(true);
  };

  return (
    <div className="">
      <Header />
      <ReactNotifications />
      <main id="main">
        <div className="breadcrumbs-modified d-flex align-items-center"></div>
        {loading ? (
          <MiSpinner />
        ) : (
          <>
            <section id="contact" className="input-data">
              <div className="container" data-aos="fade-up">
                <div className="row d-flex justify-content-end php-email-form">
                  <div className="col-md-8">
                    <div className="text-center">
                      <h5 className="bdf-bluefontcolor">{WORKSPACE}</h5>
                    </div>
                    <div className="py-5 row">
                      <div className="col-md-5">
                        <ObjectDetails title={"Names"} value={user?.fullName} />
                        <ObjectDetails title={"Title"} value={user?.title} />
                        <ObjectDetails title={"Email"} value={user?.username} />
                        <ObjectDetails
                          title={"Telephone"}
                          value={user?.telephone}
                        />
                        <ObjectDetails title={"Role"} value={user?.role} />
                      </div>
                      <div className="col-md-5 bdf-bluefontcolor">
                        CHANGE PASSWORD
                        <div>
                          <InputTextSecret2
                            placeholder={"Password"}
                            isClicked={clicked}
                            value={pswd}
                            name="pswd"
                            title={"Password"}
                            onChange={(e) => setpswd(e.target.value)}
                          />
                        </div>
                        <div className="py-2">
                          <InputTextSecret2
                            title={"Confirm Password"}
                            placeholder={"Re-type Password"}
                            isClicked={clicked}
                            value={cfrmpswd}
                            name="cfrmpswd"
                            onChange={(e) => setcfrmpswd(e.target.value)}
                          />
                        </div>
                        <span className="text-danger">{error}</span>
                        <div className="pb-3">
                          <SubmitButton
                            action={"Submit"}
                            onSubmit={changepswd}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3 d-flex gy-5 ml-5 justify-content-end">
                    <div>
                      <div className="flex flex-column h-50">
                        <PageLink page="/admin/users" title={"New User"} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </>
        )}
      </main>
      <ScrollToTop />
    </div>
  );
};

export const AdminLinks = function (props) {
  const WORKSPACE = "Application Links";
  const history = useHistory();
  const params = useParams();
  const dispatch = useDispatch();
  const [clicked, setclicked] = useState(false);
  const [btndisabled, setbtndisabled] = useState(false);
  const object = useSelector((state) => state.languages.applicationLink);
  const loading = useSelector(
    (state) => state.languages.applicationLinksLoading
  );
  useEffect(() => {
    if (params?.objectId && object === null) {
      dispatch(actions.getOneApplicationLinks(token, params?.objectId));
    }
    if (object !== null) {
      setname(object?.name);
      setlink(object?.link);
    }
  }, [object]);
  const [name, setname] = useState("");
  const [title, settitle] = useState("");
  const [role, setrole] = useState("");
  const [username, setusername] = useState("");
  const [link, setlink] = useState("");
  const languages = useSelector((state) => state.languages.languages);
  const userNames = useSelector((state) => state.auth.displayName);
  const token = useSelector((state) => state.auth.token);

  const activating = (e) => {
    e.preventDefault();
    if (window.confirm("Are you sure you want change the status?")) {
      if (object?.status === constants.active) {
        dispatch(actions.desActivatingApplicationLinks(token, object?.uuid));
        successUpdateNotification(WORKSPACE, "Inactive");
      } else {
        dispatch(actions.activatingApplicationLinks(token, object?.uuid));
        successUpdateNotification(WORKSPACE, "Active");
      }
    } else {
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (name === "" || link === "") {
      setclicked(true);
    } else {
      if (object != null) {
        if (window.confirm("Are you sure you want update?")) {
          const obj = {
            name,
            link,
            id: object.uuid,editedBy:userNames,
          };
          dispatch(actions.updateApplicationLinks(token, obj));
          setname("");
          setlink("");
          settitle("");
          setrole("");
          setusername("");
          setclicked(false);
          successUpdateNotification(WORKSPACE, "Updated");
        } else {
        }
      } else {
        if (window.confirm("Are you sure you want submit?")) {
          const obj = {
            name,
            link,
            creator: userNames,
          };
          dispatch(actions.createApplicationLinks(obj, token));
          setname("");
          setlink("");
          setclicked(false);
          setbtndisabled(false);
          successCreationNotification(WORKSPACE);
        } else {
        }
      }
    }
  };

  return (
    <div className="">
      <Header />
      <ReactNotifications />
      <main id="main">
        <div className="breadcrumbs-modified d-flex align-items-center"></div>
        {loading ? (
          <MiSpinner />
        ) : (
          <section id="contact" className="input-data">
            <div className="container" data-aos="fade-up">
              <div className="row d-flex justify-content-end php-email-form">
                <div className="col-md-8">
                  <div className="">
                    <div className="">
                      {object !== null ? (
                        <>
                          <h5 className="bdf-bluefontcolor">
                            EDIT {WORKSPACE}
                          </h5>
                        </>
                      ) : (
                        <h5 className="bdf-bluefontcolor">
                          CREATE {WORKSPACE}
                        </h5>
                      )}
                    </div>
                    <div className="form-group py-1">
                      <InputText2
                        title={"Name"}
                        value={name}
                        name="name"
                        placeholder="Name"
                        isClicked={clicked}
                        onChange={(e) => setname(e.target.value)}
                      />
                    </div>
                    <div className="form-group py-1">
                      <InputText2
                        isClicked={clicked}
                        title={"Link"}
                        placeholder="Link"
                        value={link}
                        name="link"
                        onChange={(e) => setlink(e.target.value)}
                      />
                    </div>
                    <div className="row gx-5"></div>
                    <SubmitButton
                      disabled={btndisabled}
                      action={object === null ? "Create" : "Update"}
                      onSubmit={onSubmit}
                    />
                    <span className="px-5"></span>
                    <ActivateButton
                      status={object?.status}
                      onSubmit={activating}
                    />
                  </div>
                </div>
                <div className="col-md-3 d-flex gy-5 ml-5 justify-content-end">
                  <div>
                    <div className="flex flex-column h-50">
                      <PageLink
                        page={"/admin/links/objects/list"}
                        title={"List"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
      </main>
      <ScrollToTop />
    </div>
  );
};

export const AdminLinksList = function (props) {
  const WORKSPACE = "Application Links";
  const loading = useSelector((state) => state.auth.loading);
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);
  const objectList = useSelector((state) => state.languages.applicationLinks);

  useEffect(() => {
    dispatch(actions.getApplicationLinks());
  }, []);

  return (
    <div className="">
      <Header />
      <ReactNotifications />
      <main id="main">
        <div className="breadcrumbs-modified d-flex align-items-center"></div>
        {loading ? (
          <MiSpinner />
        ) : (
          <>
            <section id="contact" className="input-data">
              <div className="container" data-aos="fade-up">
                <div className="row d-flex justify-content-end php-email-form">
                  <div className="col-md-8">
                    <div className="text-center">
                      <h5 className="bdf-bluefontcolor">{WORKSPACE} LIST</h5>
                    </div>
                    <table className="table table-striped">
                      <thead>
                        <tr className="bdf-bluefontcolor">
                          <th>#</th>
                          <th>Name</th>
                          <th>Link</th>
                          <th>Status</th>
                          <th className="text-center">Action</th>
                        </tr>
                      </thead>
                      <tbody className="">
                        {objectList?.map((data, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{data?.name}</td>
                            <td>{data?.link}</td>
                            <td>{data?.status}</td>
                            <td className="row d-flex justify-content-center">
                              <EditButton
                                title="Edit"
                                page="/admin/links"
                                id={data?.uuid}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div className="col-md-3 d-flex gy-5 ml-5 justify-content-end">
                    <div>
                      <div className="flex flex-column h-50">
                        <PageLink page="/admin/links" title={"New Link"} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </>
        )}
      </main>
      <ScrollToTop />
    </div>
  );
};
