import axios from "../../axios-base";

export const GET_ADMIN_HOME_SUCCESS = "GET_ADMIN_HOME_SUCCESS";
export const GET_ONE_ADMIN_HOME_SUCCESS = "GET_ONE_ADMIN_HOME_SUCCESS";
export const GET_ADMIN_HOME_START = "GET_ADMIN_HOME_START";
export const GET_ADMIN_HOME_FAIL = "GET_ADMIN_HOME_FAIL";

export const getAdminHomesSuccess = (data) => {
  return {
    type: GET_ADMIN_HOME_SUCCESS,
    payload: data,
  };
};

export const getOneAdminHomesSuccess = (data) => {
  return {
    type: GET_ONE_ADMIN_HOME_SUCCESS,
    payload: data,
  };
};

export const getAdminHomesStart = () => {
  return {
    type: GET_ADMIN_HOME_START,
  };
};
export const getAdminHomesFail = (error) => {
  return {
    type: GET_ADMIN_HOME_FAIL,
    payload: error,
  };
};

export const getActiveAdminHomes = (lang) => {
  return (dispatch) => {
    dispatch(getAdminHomesStart());
    axios
      .get(`/homes/active/${lang}`)
      .then((response) => {
        dispatch(getOneAdminHomesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminHomesFail(err));
      });
  };
};

export const createAdminHomes = (object, token) => {
  return (dispatch) => {
    dispatch(getAdminHomesStart());
    axios
      .post(`/homes/create`, object, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOneAdminHomesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminHomesFail(err));
      });
  };
};

export const getAdminHomes = (token) => {
  return (dispatch) => {
    dispatch(getAdminHomesStart());
    axios
      .get(`/homes`, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getAdminHomesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminHomesFail(err));
      });
  };
};

export const getOneAdminHomes = (token, id) => {
  return (dispatch) => {
    dispatch(getAdminHomesStart());
    axios
      .get(`/homes/find/${id}`, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOneAdminHomesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminHomesFail(err));
      });
  };
};

export const updateAdminHomes = (token, object) => {
  return (dispatch) => {
    dispatch(getAdminHomesStart());
    axios
      .put(`/homes/edit/${object?.id}`, object, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOneAdminHomesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminHomesFail(err));
      });
  };
};

export const activatingAdminHomes = (id) => {
  return (dispatch) => {
    dispatch(getAdminHomesStart());
    axios
      .put(`/homes/activating/${id}`)
      .then((response) => {
        dispatch(getOneAdminHomesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminHomesFail(err));
      });
  };
};

export const desActivatingAdminHomes = (id) => {
  return (dispatch) => {
    dispatch(getAdminHomesStart());
    axios
      .put(`/homes/desactivating/${id}`)
      .then((response) => {
        dispatch(getOneAdminHomesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminHomesFail(err));
      });
  };
};
