import { useTranslation } from "react-i18next";
import * as actions from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { ScrollToTop } from "../../components/backtotop";
import Header from "../components/header";
import Modal from "react-bootstrap/Modal";
import {
  ActivateButton,
  EditButton,
  InputText,
  InputText2,
  InputTextArea,
  InputTextSecret,
  PageLink,
  SelectRadioButton,
  SubmitButton,
  UplodadFile,
  ViewButton,
} from "../components/inputs";
import { LanguagesTable } from "../components/languagestable";
import {
  successCreationNotification,
  successNotification,
  successUpdateNotification,
} from "../../components/notifications";
import { ReactNotifications } from "react-notifications-component";
import classnames from "classnames";
import { useHistory, useParams } from "react-router-dom";
import MiSpinner from "../../components/spinner";
import constants from "../../components/constants";
import { getLanguages } from "../../store/actions";
import { set } from "lodash";

export const AdminTrainingIntro = function (props) {
  const WORKSPACE = "Training Intro";
  const history = useHistory();
  const params = useParams();
  const [counter, setcounter] = useState(0);
  const dispatch = useDispatch();
  const [clicked, setclicked] = useState(false);
  const [btndisabled, setbtndisabled] = useState(false);
  const object = useSelector((state) => state.trainings.trainingIntro);
  const error = useSelector((state) => state.trainings.trainingFail);
  const loading = useSelector((state) => state.trainings.trainingIntrosLoading);
  useEffect(() => {
    dispatch(getLanguages());
    if (params?.objectId && object === null) {
      dispatch(actions.getOneTrainingIntros(token, params?.objectId));
    }
    if (object !== null) {
      settitle(object?.title);
      setdescription(object?.description);
    }
  }, [object]);
  const [title, settitle] = useState("");
  const [description, setdescription] = useState("");
  const [image, setimage] = useState(null);
  const languages = useSelector((state) => state.languages.languages);
  const userNames = useSelector((state) => state.auth.displayName);
  const token = useSelector((state) => state.auth.token);
  const [objects, setObjects] = useState([]);

  const fileSelectorHandler = (event) => {
    setimage(event.target.files[0]);
  };

  const activating = (e) => {
    e.preventDefault();
    if (window.confirm("Are you sure you want change the status?")) {
      if (object?.status === constants.active) {
        dispatch(actions.desActivatingTrainingIntros(token, object?.uuid));
        successUpdateNotification(WORKSPACE, "Inactive");
      } else {
        dispatch(actions.activatingTrainingIntros(token, object?.uuid));
        successUpdateNotification(WORKSPACE, "Active");
      }
    } else {
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (title === "" || description === "") {
      setclicked(true);
    } else {
      if (object != null) {
        if (window.confirm("Are you sure you want update?")) {
          const obj = {
            title,
            description,
            id: object.uuid,editedBy:userNames,
          };
          dispatch(actions.updateTrainingIntros(token, obj));
          settitle("");
          setdescription("");
          setclicked(false);
          successUpdateNotification(WORKSPACE, "Updated");
        } else {
        }
      } else {
        if (counter < languages.length) {
          const lang = languages[counter]
            ? languages[counter].abbreviation
            : "";
          const languageName = languages[counter]
            ? languages[counter].name
            : "";
          const subs = setObjects([
            ...objects,
            {
              title,
              description,
              creator: userNames,
              language: lang,
            },
          ]);
          setcounter(counter + 1);
          successNotification(WORKSPACE, languageName);
          settitle("");
          setdescription("");
          setclicked(false);
        }
        if (counter == languages.length - 1) {
          settitle(title);
          setdescription(description);
          setbtndisabled(true);
        }
        if (counter == languages.length) {
          if (window.confirm("Are you sure you want submit?")) {
            const fd = new FormData();
            fd.append("image", image);
            fd.append("objects", JSON.stringify(objects));
            dispatch(actions.createTrainingIntros(fd, token));
            setcounter(0);
            setObjects([]);
            settitle("");
            setdescription("");
            setclicked(false);
            setbtndisabled(false);
            successCreationNotification(WORKSPACE);
          } else {
          }
        }
      }
    }
  };

  return (
    <div className="">
      <Header />
      <ReactNotifications />
      <main id="main">
        <div className="breadcrumbs-modified d-flex align-items-center"></div>
        {loading ? (
          <MiSpinner />
        ) : (
          <section id="contact" className="input-data">
            <div className="container" data-aos="fade-up">
              <div className="row d-flex justify-content-end php-email-form">
                <div className="col-md-8">
                  <div className="">
                    <div className="">
                      {object !== null ? (
                        <>
                          <h5 className="bdf-bluefontcolor">
                            EDIT {WORKSPACE} ({object?.language})
                          </h5>
                        </>
                      ) : (
                        <h5 className="bdf-bluefontcolor">
                          CREATE {WORKSPACE} IN: {languages[counter]?.name}
                        </h5>
                      )}
                    </div>
                    <div className="form-group py-3">
                      <InputText2
                        title={"Title"}
                        value={title}
                        name="title"
                        placeholder="Title"
                        isClicked={clicked}
                        onChange={(e) => settitle(e.target.value)}
                      />
                    </div>
                    <div className="form-group py-3">
                      <InputTextArea
                        isClicked={clicked}
                        title={"Description"}
                        placeholder="Description"
                        value={description}
                        name="description"
                        onChange={(e) => setdescription(e.target.value)}
                      />
                    </div>
                    <div className="form-group py-3">
                      <UplodadFile
                        title="Image"
                        updating={
                          object != null ? constants.isTrue : constants.isFalse
                        }
                        name="image"
                        required
                        onChange={fileSelectorHandler}
                      />
                    </div>
                    <div className="row gx-5"></div>
                    <SubmitButton
                      disabled={btndisabled}
                      action={object === null ? "Create" : "Update"}
                      onSubmit={onSubmit}
                    />
                    <span className="px-2"></span>
                    <SubmitButton
                      disabled={!btndisabled}
                      classes={object === null ? "bg-success" : "d-none"}
                      action={"Submit"}
                      onSubmit={onSubmit}
                    />
                    <span className="px-5"></span>
                    <ActivateButton
                      status={object?.status}
                      onSubmit={activating}
                    />
                  </div>
                </div>
                <div className="col-md-3 d-flex gy-5 ml-5 justify-content-end">
                  <div>
                    <div className="flex flex-column h-50">
                      <PageLink
                        page={"/admin/trainings-intros/objects/list"}
                        title={"List"}
                      />
                    </div>
                    <div>
                      <LanguagesTable languages={languages} counter={counter} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
      </main>
      <ScrollToTop />
    </div>
  );
};

export const AdminTrainingCategories = function (props) {
  const WORKSPACE = "Training Categories";
  const history = useHistory();
  const params = useParams();
  const [counter, setcounter] = useState(0);
  const dispatch = useDispatch();
  const [clicked, setclicked] = useState(false);
  const [btndisabled, setbtndisabled] = useState(false);
  const object = useSelector((state) => state.trainings.category);
  const loading = useSelector((state) => state.trainings.categoriesLoading);
  useEffect(() => {
    dispatch(getLanguages());
    if (params?.objectId && object === null) {
      dispatch(actions.getOneTrainingCategories(token, params?.objectId));
    }
    if (object !== null) {
      settitle(object?.title);
      setdescription(object?.description);
    }
  }, [object]);
  const [title, settitle] = useState("");
  const [description, setdescription] = useState("");
  const languages = useSelector((state) => state.languages.languages);
  const userNames = useSelector((state) => state.auth.displayName);
  const token = useSelector((state) => state.auth.token);
  const [objects, setObjects] = useState([]);

  const activating = (e) => {
    e.preventDefault();
    if (window.confirm("Are you sure you want change the status?")) {
      if (object?.status === constants.active) {
        dispatch(actions.desActivatingTrainingCategories(token, object?.uuid));
        successUpdateNotification(WORKSPACE, "Inactive");
      } else {
        dispatch(actions.activatingTrainingCategories(token, object?.uuid));
        successUpdateNotification(WORKSPACE, "Active");
      }
    } else {
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (title === "" || description === "") {
      setclicked(true);
    } else {
      if (object != null) {
        if (window.confirm("Are you sure you want update?")) {
          const obj = {
            title,
            description,
            id: object.uuid,editedBy:userNames,
          };
          dispatch(actions.updateTrainingCategories(token, obj));
          settitle("");
          setdescription("");
          setclicked(false);
          successUpdateNotification(WORKSPACE, "Updated");
        } else {
        }
      } else {
        if (counter < languages.length) {
          const lang = languages[counter]
            ? languages[counter].abbreviation
            : "";
          const languageName = languages[counter]
            ? languages[counter].name
            : "";
          const subs = setObjects([
            ...objects,
            {
              title,
              description,
              creator: userNames,
              language: lang,
            },
          ]);
          setcounter(counter + 1);
          successNotification(WORKSPACE, languageName);
          settitle("");
          setdescription("");
          setclicked(false);
        }
        if (counter == languages.length - 1) {
          settitle(title);
          setdescription(description);
          setbtndisabled(true);
        }
        if (counter == languages.length) {
          if (window.confirm("Are you sure you want submit?")) {
            dispatch(actions.createTrainingCategories(objects, token));
            setcounter(0);
            setObjects([]);
            settitle("");
            setdescription("");
            setclicked(false);
            setbtndisabled(false);
            successCreationNotification(WORKSPACE);
          } else {
          }
        }
      }
    }
  };

  return (
    <div className="">
      <Header />
      <ReactNotifications />
      <main id="main">
        <div className="breadcrumbs-modified d-flex align-items-center"></div>
        {loading ? (
          <MiSpinner />
        ) : (
          <section id="contact" className="input-data">
            <div className="container" data-aos="fade-up">
              <div className="row d-flex justify-content-end php-email-form">
                <div className="col-md-8">
                  <div className="">
                    <div className="">
                      {object !== null ? (
                        <>
                          <h5 className="bdf-bluefontcolor">
                            EDIT {WORKSPACE} ({object?.language})
                          </h5>
                        </>
                      ) : (
                        <h5 className="bdf-bluefontcolor">
                          CREATE {WORKSPACE} IN: {languages[counter]?.name}
                        </h5>
                      )}
                    </div>
                    <div className="form-group py-3">
                      <InputText2
                        title={"Title"}
                        value={title}
                        name="title"
                        placeholder="Title"
                        isClicked={clicked}
                        onChange={(e) => settitle(e.target.value)}
                      />
                    </div>
                    <div className="form-group py-3">
                      <InputTextArea
                        isClicked={clicked}
                        title={"Description"}
                        placeholder="Description"
                        value={description}
                        name="description"
                        onChange={(e) => setdescription(e.target.value)}
                      />
                    </div>
                    <div className="row gx-5"></div>
                    <SubmitButton
                      disabled={btndisabled}
                      action={object === null ? "Create" : "Update"}
                      onSubmit={onSubmit}
                    />
                    <span className="px-2"></span>
                    <SubmitButton
                      disabled={!btndisabled}
                      classes={object === null ? "bg-success" : "d-none"}
                      action={"Submit"}
                      onSubmit={onSubmit}
                    />
                    <span className="px-5"></span>
                    <ActivateButton
                      status={object?.status}
                      onSubmit={activating}
                    />
                  </div>
                </div>
                <div className="col-md-3 d-flex gy-5 ml-5 justify-content-end">
                  <div>
                    <div className="flex flex-column h-50">
                      <PageLink
                        page={"/admin/trainings-categories/objects/list"}
                        title={"List"}
                      />
                    </div>
                    <div>
                      <LanguagesTable languages={languages} counter={counter} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
      </main>
      <ScrollToTop />
    </div>
  );
};

export const AdminTrainingCategoriesComponents = function (props) {
  const WORKSPACE = "CONTENTS";
  const history = useHistory();
  const params = useParams();
  const [counter, setcounter] = useState(0);
  const dispatch = useDispatch();
  const [clicked, setclicked] = useState(false);
  const [btndisabled, setbtndisabled] = useState(false);
  const object = useSelector((state) => state.trainings.category);
  const loading = useSelector((state) => state.trainings.categoriesLoading);
  const components = useSelector((state) => state.trainings.components);
  const componentLoading = useSelector(
    (state) => state.trainings.componentsLoading
  );
  useEffect(() => {
    dispatch(getLanguages());
    if (params?.objectId && object === null) {
      dispatch(actions.getOneTrainingCategories(token, params?.objectId));
    }
    if (object !== null) {
      dispatch(actions.getCategoryComponents(token, params?.objectId));
    }
  }, [object]);
  const [title, settitle] = useState("");
  const [description, setdescription] = useState("");
  const languages = useSelector((state) => state.languages.languages);
  const userNames = useSelector((state) => state.auth.displayName);
  const token = useSelector((state) => state.auth.token);
  const [objects, setObjects] = useState([]);

  const activating = (e) => {
    e.preventDefault();
    if (window.confirm("Are you sure you want change the status?")) {
      if (object?.status === constants.active) {
        dispatch(actions.desActivatingTrainingCategories(token, object?.uuid));
        successUpdateNotification(WORKSPACE, "Inactive");
      } else {
        dispatch(actions.activatingTrainingCategories(token, object?.uuid));
        successUpdateNotification(WORKSPACE, "Active");
      }
    } else {
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (title === "" || description === "") {
      setclicked(true);
    } else {
      if (object != null) {
        if (window.confirm("Are you sure you want update?")) {
          const obj = {
            title,
            description,
            id: object.uuid,editedBy:userNames,
          };
          dispatch(actions.updateTrainingCategories(token, obj));
          settitle("");
          setdescription("");
          setclicked(false);
          successUpdateNotification(WORKSPACE, "Updated");
        } else {
        }
      } else {
        if (counter < languages.length) {
          const lang = languages[counter]
            ? languages[counter].abbreviation
            : "";
          const languageName = languages[counter]
            ? languages[counter].name
            : "";
          const subs = setObjects([
            ...objects,
            {
              title,
              description,
              creator: userNames,
              language: lang,
            },
          ]);
          setcounter(counter + 1);
          successNotification(WORKSPACE, languageName);
          settitle("");
          setdescription("");
          setclicked(false);
        }
        if (counter == languages.length - 1) {
          settitle(title);
          setdescription(description);
          setbtndisabled(true);
        }
        if (counter == languages.length) {
          if (window.confirm("Are you sure you want submit?")) {
            dispatch(actions.createTrainingCategories(objects, token));
            setcounter(0);
            setObjects([]);
            settitle("");
            setdescription("");
            setclicked(false);
            setbtndisabled(false);
            successCreationNotification(WORKSPACE);
          } else {
          }
        }
      }
    }
  };

  return (
    <div className="">
      <Header />
      <ReactNotifications />
      <main id="main">
        <div className="breadcrumbs-modified d-flex align-items-center"></div>
        {loading ? (
          <MiSpinner />
        ) : (
          <section id="contact" className="input-data">
            <div className="container" data-aos="fade-up">
              <div className="row d-flex justify-content-end php-email-form">
                <div className="col-md-8">
                  <div className="text-center">
                    <h5 className="bdf-bluefontcolor">
                      {}
                      {object?.title} ({object?.language}) {WORKSPACE} LIST
                    </h5>
                  </div>
                  <table className="table table-striped">
                    <thead>
                      <tr className="bdf-bluefontcolor">
                        <th>#</th>
                        <th>Title</th>
                        <th>Status</th>
                        <th>Language</th>
                        <th className="text-center">Action</th>
                      </tr>
                    </thead>
                    <tbody className="">
                      {components?.map((data, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{data?.title}</td>
                          <td>{data?.status}</td>
                          <td>{data?.language}</td>
                          <td className="row d-flex justify-content-center">
                            <EditButton
                              title="Edit"
                              page="/admin/trainings-categories-components-new"
                              id={data?.uuid}
                              parentId={object?.uuid}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className="col-md-3 d-flex gy-5 ml-5 justify-content-end">
                  <div>
                    <div className="flex flex-column h-50 text-center">
                      <div>
                        <PageLink
                          page={"/admin/trainings-categories-components-new"}
                          title={"New Content"}
                        />
                      </div>
                      <span></span>
                      <div className="pt-5">
                        <PageLink
                          page={"/admin/trainings-categories/objects/list"}
                          title={"Categories"}
                        />
                      </div>
                    </div>
                    <div></div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
      </main>
      <ScrollToTop />
    </div>
  );
};

export const AdminTrainingCategoriesComponentsNew = function (props) {
  const WORKSPACE = "Training Category Content";
  const history = useHistory();
  const params = useParams();
  const [counter, setcounter] = useState(0);
  const dispatch = useDispatch();
  const [clicked, setclicked] = useState(false);
  const [btndisabled, setbtndisabled] = useState(false);
  const parentObject = useSelector((state) => state.trainings.category);
  // const loading = useSelector((state) => state.trainings.categoriesLoading);
  const object = useSelector((state) => state.trainings.component);
  const loading = useSelector((state) => state.trainings.componentsLoading);
  useEffect(() => {
    if (params?.parentId && parentObject === null) {
      dispatch(actions.getOneTrainingCategories(token, params?.parentId));
    }
    if (params?.objectId && object === null) {
      dispatch(actions.getOneCategoryComponents(token, params?.objectId));
    }
    if (object !== null) {
      settitle(object?.title);
    }
  }, [object]);
  const [title, settitle] = useState("");
  const languages = useSelector((state) => state.languages.languages);
  const userNames = useSelector((state) => state.auth.displayName);
  const token = useSelector((state) => state.auth.token);
  const [objects, setObjects] = useState([]);

  const activating = (e) => {
    e.preventDefault();
    if (window.confirm("Are you sure you want change the status?")) {
      if (object?.status === constants.active) {
        dispatch(actions.desActivatingCategoryComponents(token, object?.uuid));
        successUpdateNotification(WORKSPACE, "Inactive");
      } else {
        dispatch(actions.activatingCategoryComponents(token, object?.uuid));
        successUpdateNotification(WORKSPACE, "Active");
      }
    } else {
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (title === "") {
      setclicked(true);
    } else {
      if (object != null) {
        if (window.confirm("Are you sure you want update?")) {
          const obj = {
            title,
            id: object.uuid,editedBy:userNames,
          };
          dispatch(actions.updateCategoryComponents(token, obj));
          settitle("");
          setclicked(false);
          successUpdateNotification(WORKSPACE, "Updated");
        } else {
        }
      } else {
        if (window.confirm("Are you sure you want submit?")) {
          const object = {
            title,
            categoryId: parentObject?.uuid,
            creator: userNames,
            language: parentObject?.language,
          };
          dispatch(actions.createCategoryComponents(object, token));
          settitle("");
          setclicked(false);
          setbtndisabled(false);
          successCreationNotification(WORKSPACE);
        } else {
        }
      }
    }
  };

  return (
    <div className="">
      <Header />
      <ReactNotifications />
      <main id="main">
        <div className="breadcrumbs-modified d-flex align-items-center"></div>
        {loading ? (
          <MiSpinner />
        ) : (
          <section id="contact" className="input-data">
            <div className="container" data-aos="fade-up">
              <div className="row d-flex justify-content-end php-email-form">
                <div className="col-md-8">
                  <div className="">
                    <div className="">
                      {object !== null ? (
                        <>
                          <h5 className="bdf-bluefontcolor">
                            EDITING {WORKSPACE} IN {parentObject?.title} (
                            {parentObject?.language})
                          </h5>
                        </>
                      ) : (
                        <h5 className="bdf-bluefontcolor">
                          CREATE {WORKSPACE} IN {parentObject?.title} - (
                          {parentObject?.language})
                        </h5>
                      )}
                    </div>
                    <div className="form-group py-3">
                      <InputText2
                        title={"Title"}
                        value={title}
                        name="title"
                        placeholder="Title"
                        isClicked={clicked}
                        onChange={(e) => settitle(e.target.value)}
                      />
                    </div>
                    <div className="row gx-5"></div>
                    <SubmitButton
                      disabled={btndisabled}
                      action={object === null ? "Create" : "Update"}
                      onSubmit={onSubmit}
                    />
                    <span className="px-5"></span>
                    <ActivateButton
                      status={object?.status}
                      onSubmit={activating}
                    />
                  </div>
                </div>
                <div className="col-md-3 d-flex gy-5 ml-5 justify-content-end">
                  <div>
                    <div className="flex flex-column h-50 text-center">
                      <div>
                        <PageLink
                          page={
                            "/admin/trainings-categories-components/" +
                            parentObject?.uuid
                          }
                          title={"Contents List"}
                        />
                      </div>

                      <div className="d-none pt-5">
                        <PageLink
                          page={"/admin/trainings-categories-components-new"}
                          title={"New Content"}
                        />
                      </div>
                      <div className="pt-5">
                        <PageLink
                          page={"/admin/trainings-categories/objects/list"}
                          title={"Categories"}
                        />
                      </div>
                    </div>
                    <div></div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
      </main>
      <ScrollToTop />
    </div>
  );
};
