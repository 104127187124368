import { updateObject } from "../../utils/utility";
import {
  GET_TRAINING_INTROS_START,
  GET_TRAINING_INTROS_SUCCESS,
  GET_ONE_TRAINING_INTROS_SUCCESS,
  GET_TRAINING_INTROS_FAIL,
  GET_TRAINING_CATEGORIES_START,
  GET_TRAINING_CATEGORIES_SUCCESS,
  GET_ONE_TRAINING_CATEGORIES_SUCCESS,
  GET_TRAINING_CATEGORIES_FAIL,
  GET_CATEGORY_COMPONENTS_START,
  GET_CATEGORY_COMPONENTS_SUCCESS,
  GET_ONE_CATEGORY_COMPONENTS_SUCCESS,
  GET_CATEGORY_COMPONENTS_FAIL,
} from "../actions/training";

const initialReducer = {
  trainingIntro: null,
  trainingIntros: [],
  trainingIntrosLoading: false,
  trainingIntrosFail: null,
  category: null,
  categories: [],
  categoriesLoading: false,
  categoriesFail: null,
  component: null,
  components: [],
  componentsLoading: false,
  componentsFail: null,
};

const getTrainingIntrosStart = (state, action) => {
  return updateObject(state, {
    trainingIntros: [],
    trainingIntro: null,
    trainingIntrosLoading: true,
    trainingIntrosFail: null,
  });
};

const getTrainingIntrosSuccess = (state, action) => {
  return updateObject(state, {
    trainingIntros: action.payload,
    trainingIntrosLoading: false,
    trainingIntrosFail: null,
    trainingIntro: null,
  });
};

const getOneTrainingIntrosSuccess = (state, action) => {
  return updateObject(state, {
    trainingIntro: action.payload,
    trainingIntros: [],
    trainingIntrosLoading: false,
    trainingIntrosFail: null,
  });
};

const getTrainingIntrosFail = (state, action) => {
  return updateObject(state, {
    trainingIntros: [],
    trainingIntro: null,
    trainingIntrosLoading: false,
    trainingIntrosFail: action.payload,
  });
};

const getTrainingCategoriesStart = (state, action) => {
  return updateObject(state, {
    categories: [],
    category: null,
    categoriesLoading: true,
    categoriesFail: null,
  });
};

const getTrainingCategoriesSuccess = (state, action) => {
  return updateObject(state, {
    categories: action.payload,
    categoriesLoading: false,
    categoriesFail: null,
    category: null,
  });
};

const getOneTrainingCategoriesSuccess = (state, action) => {
  return updateObject(state, {
    category: action.payload,
    categories: [],
    categoriesLoading: false,
    categoriesFail: null,
  });
};

const getTrainingCategoriesFail = (state, action) => {
  return updateObject(state, {
    categories: [],
    category: null,
    categoriesLoading: false,
    categoriesFail: action.payload,
  });
};

const getCategoryComponentsStart = (state, action) => {
  return updateObject(state, {
    components: [],
    component: null,
    componentsLoading: true,
    componentsFail: null,
  });
};

const getCategoryComponentsSuccess = (state, action) => {
  return updateObject(state, {
    components: action.payload,
    componentsLoading: false,
    componentsFail: null,
    component: null,
  });
};

const getOneCategoryComponentsSuccess = (state, action) => {
  return updateObject(state, {
    component: action.payload,
    components: [],
    componentsLoading: false,
    componentsFail: null,
  });
};

const getCategoryComponentsFail = (state, action) => {
  return updateObject(state, {
    components: [],
    component: null,
    componentsLoading: false,
    componentsFail: action.payload,
  });
};

const reducer = (state = initialReducer, action) => {
  switch (action.type) {
    case GET_TRAINING_INTROS_START:
      return getTrainingIntrosStart(state, action);
    case GET_TRAINING_INTROS_SUCCESS:
      return getTrainingIntrosSuccess(state, action);
    case GET_ONE_TRAINING_INTROS_SUCCESS:
      return getOneTrainingIntrosSuccess(state, action);
    case GET_TRAINING_INTROS_FAIL:
      return getTrainingIntrosFail(state, action);
    case GET_TRAINING_CATEGORIES_START:
      return getTrainingCategoriesStart(state, action);
    case GET_TRAINING_CATEGORIES_SUCCESS:
      return getTrainingCategoriesSuccess(state, action);
    case GET_ONE_TRAINING_CATEGORIES_SUCCESS:
      return getOneTrainingCategoriesSuccess(state, action);
    case GET_TRAINING_CATEGORIES_FAIL:
      return getTrainingCategoriesFail(state, action);
    case GET_CATEGORY_COMPONENTS_START:
      return getCategoryComponentsStart(state, action);
    case GET_CATEGORY_COMPONENTS_SUCCESS:
      return getCategoryComponentsSuccess(state, action);
    case GET_ONE_CATEGORY_COMPONENTS_SUCCESS:
      return getOneCategoryComponentsSuccess(state, action);
    case GET_CATEGORY_COMPONENTS_FAIL:
      return getCategoryComponentsFail(state, action);
    default:
      return state;
  }
};

export default reducer;
