import { useTranslation } from "react-i18next";
import * as actions from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { ScrollToTop } from "../../components/backtotop";
import Header from "../components/header";
import Modal from "react-bootstrap/Modal";
import {
  ActivateButton,
  EditButton,
  InputDate,
  InputText,
  InputText2,
  InputTextArea,
  InputTextSecret,
  PageLink,
  SelectInput,
  SelectRadioButton,
  SubmitButton,
  UplodadFile,
} from "../components/inputs";
import { LanguagesTable } from "../components/languagestable";
import {
  successCreationNotification,
  successNotification,
  successUpdateNotification,
} from "../../components/notifications";
import { ReactNotifications } from "react-notifications-component";
import classnames from "classnames";
import { useHistory, useParams } from "react-router-dom";
import MiSpinner from "../../components/spinner";
import constants from "../../components/constants";
import { getLanguages } from "../../store/actions";
import { set } from "lodash";
import moment from "moment";

export const AdminPublications = function (props) {
  const WORKSPACE = "Publications";
  const history = useHistory();
  const params = useParams();
  const [counter, setcounter] = useState(0);
  const dispatch = useDispatch();
  const [clicked, setclicked] = useState(false);
  const [btndisabled, setbtndisabled] = useState(false);
  const object = useSelector((state) => state.publications.publication);
  const loading = useSelector(
    (state) => state.publications.publicationsLoading
  );
  useEffect(() => {
    dispatch(getLanguages());
    if (params?.objectId && object === null) {
      dispatch(actions.getOnePublications(token, params?.objectId));
    }
    if (object !== null) {
      settitle(object?.title);
      setdescription(object?.description);
    }
  }, [object]);
  const [title, settitle] = useState("");
  const [description, setdescription] = useState("");
  const [image, setimage] = useState(null);
  const languages = useSelector((state) => state.languages.languages);
  const userNames = useSelector((state) => state.auth.displayName);
  const token = useSelector((state) => state.auth.token);
  const [objects, setObjects] = useState([]);

  const fileSelectorHandler = (event) => {
    setimage(event.target.files[0]);
  };

  const activating = (e) => {
    e.preventDefault();
    if (window.confirm("Are you sure you want change the status?")) {
      if (object?.status === constants.active) {
        dispatch(actions.desActivatingPublications(token, object?.uuid));
        successUpdateNotification(WORKSPACE, "Inactive");
      } else {
        dispatch(actions.activatingPublications(token, object?.uuid));
        successUpdateNotification(WORKSPACE, "Active");
      }
    } else {
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (title === "" || description === "") {
      setclicked(true);
    } else {
      if (object != null) {
        if (window.confirm("Are you sure you want update?")) {
          const obj = {
            title,
            description,
            id: object.uuid,editedBy:userNames,
          };
          dispatch(actions.updatePublications(token, obj));
          settitle("");
          setdescription("");
          setclicked(false);
          successUpdateNotification(WORKSPACE, "Updated");
        } else {
        }
      } else {
        if (counter < languages.length) {
          const lang = languages[counter]
            ? languages[counter].abbreviation
            : "";
          const languageName = languages[counter]
            ? languages[counter].name
            : "";
          const subs = setObjects([
            ...objects,
            {
              title,
              description,
              creator: userNames,
              language: lang,
            },
          ]);
          setcounter(counter + 1);
          successNotification(WORKSPACE, languageName);
          settitle("");
          setdescription("");
          setclicked(false);
        }
        if (counter == languages.length - 1) {
          settitle(title);
          setdescription(description);
          setbtndisabled(true);
        }
        if (counter == languages.length) {
          if (window.confirm("Are you sure you want submit?")) {
            const fd = new FormData();
            fd.append("image", image);
            fd.append("objects", JSON.stringify(objects));
            dispatch(actions.createPublications(fd, token));
            setcounter(0);
            setObjects([]);
            settitle("");
            setdescription("");
            setclicked(false);
            setbtndisabled(false);
            successCreationNotification(WORKSPACE);
          } else {
          }
        }
      }
    }
  };

  return (
    <div className="">
      <Header />
      <ReactNotifications />
      <main id="main">
        <div className="breadcrumbs-modified d-flex align-items-center"></div>
        {loading ? (
          <MiSpinner />
        ) : (
          <section id="contact" className="input-data">
            <div className="container" data-aos="fade-up">
              <div className="row d-flex justify-content-end php-email-form">
                <div className="col-md-8">
                  <div className="">
                    <div className="">
                      {object !== null ? (
                        <>
                          <h5 className="bdf-bluefontcolor">
                            EDIT {WORKSPACE} ({object?.language})
                          </h5>
                        </>
                      ) : (
                        <h5 className="bdf-bluefontcolor">
                          CREATE {WORKSPACE} IN: {languages[counter]?.name}
                        </h5>
                      )}
                    </div>
                    <div className="form-group py-3">
                      <InputText2
                        title={"Title"}
                        value={title}
                        name="title"
                        placeholder="Title"
                        isClicked={clicked}
                        onChange={(e) => settitle(e.target.value)}
                      />
                    </div>
                    <div className="form-group py-3">
                      <InputTextArea
                        isClicked={clicked}
                        title={"Description"}
                        placeholder="Description"
                        value={description}
                        name="description"
                        onChange={(e) => setdescription(e.target.value)}
                      />
                    </div>
                    <div className="form-group py-3">
                      <UplodadFile
                        title="Image"
                        updating={
                          object != null ? constants.isTrue : constants.isFalse
                        }
                        name="image"
                        required
                        onChange={fileSelectorHandler}
                      />
                    </div>
                    <div className="row gx-5"></div>
                    <SubmitButton
                      disabled={btndisabled}
                      action={object === null ? "Create" : "Update"}
                      onSubmit={onSubmit}
                    />
                    <span className="px-2"></span>
                    <SubmitButton
                      disabled={!btndisabled}
                      classes={object === null ? "bg-success" : "d-none"}
                      action={"Submit"}
                      onSubmit={onSubmit}
                    />
                    <span className="px-5"></span>
                    <ActivateButton
                      status={object?.status}
                      onSubmit={activating}
                    />
                  </div>
                </div>
                <div className="col-md-3 d-flex gy-5 ml-5 justify-content-end">
                  <div>
                    <div className="flex flex-column h-50">
                      <PageLink
                        page={"/admin/publications/objects/list"}
                        title={"List"}
                      />
                    </div>
                    <div>
                      <LanguagesTable languages={languages} counter={counter} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
      </main>
      <ScrollToTop />
    </div>
  );
};

export const AdminPublicationContents = function (props) {
  const WORKSPACE = " CONTENTS";
  const params = useParams();
  const dispatch = useDispatch();
  const object = useSelector((state) => state.publications.publication);
  const loading = useSelector(
    (state) => state.publications.publicationsLoading
  );
  const contents = useSelector((state) => state.publications.contents);
  useEffect(() => {
    dispatch(getLanguages());
    if (params?.objectId && object === null) {
      dispatch(actions.getOnePublications(token, params?.objectId));
    }
    if (object !== null) {
      dispatch(actions.getPublicationContents(token, params?.objectId));
    }
  }, [object]);
  const token = useSelector((state) => state.auth.token);
  return (
    <div className="">
      <Header />
      <ReactNotifications />
      <main id="main">
        <div className="breadcrumbs-modified d-flex align-items-center"></div>
        {loading ? (
          <MiSpinner />
        ) : (
          <section id="contact" className="input-data">
            <div className="container" data-aos="fade-up">
              <div className="row d-flex justify-content-end php-email-form">
                <div className="col-md-8">
                  <div className="text-center">
                    <h5 className="bdf-bluefontcolor">
                      {}
                      {object?.title} ({object?.language}) {WORKSPACE} LIST
                    </h5>
                  </div>
                  <table className="table table-striped">
                    <thead>
                      <tr className="bdf-bluefontcolor">
                        <th>#</th>
                        <th>Title</th>
                        <th>Status</th>
                        <th>Language</th>
                        <th className="text-center">Action</th>
                      </tr>
                    </thead>
                    <tbody className="">
                      {contents?.map((data, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{data?.title}</td>
                          <td>{data?.status}</td>
                          <td>{data?.language}</td>
                          <td className="row d-flex justify-content-center">
                            <EditButton
                              title="Edit"
                              page="/admin/publications-contents-new"
                              id={data?.uuid}
                              parentId={object?.uuid}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className="col-md-3 d-flex gy-5 ml-5 justify-content-end">
                  <div>
                    <div className="flex flex-column h-50 text-center">
                      <div>
                        <PageLink
                          page={"/admin/publications-contents-new"}
                          title={"New Content"}
                        />
                      </div>
                      <span></span>
                      <div className="pt-5">
                        <PageLink
                          page={"/admin/publications/objects/list"}
                          title={"Publications"}
                        />
                      </div>
                    </div>
                    <div></div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
      </main>
      <ScrollToTop />
    </div>
  );
};

export const AdminPublicationContentsNew = function (props) {
  const WORKSPACE = " Content";
  const history = useHistory();
  const params = useParams();
  const [counter, setcounter] = useState(0);
  const dispatch = useDispatch();
  const [clicked, setclicked] = useState(false);
  const [btndisabled, setbtndisabled] = useState(false);
  const parentObject = useSelector((state) => state.publications.publication);
  // const loading = useSelector((state) => state.publications.publicationsLoading);
  const object = useSelector((state) => state.publications.content);
  const loading = useSelector((state) => state.publications.contentsLoading);
  useEffect(() => {
    if (params?.parentId && parentObject === null) {
      dispatch(actions.getOnePublications(token, params?.parentId));
    }
    if (params?.objectId && object === null) {
      dispatch(actions.getOnePublicationContents(token, params?.objectId));
    }
    if (object !== null) {
      settitle(object?.title);
      setdescription(object?.description);
      setdeadline(moment(object?.deadline).format("YYYY-MM-DD"));
    }
  }, [object]);
  const [title, settitle] = useState("");
  const [description, setdescription] = useState("");
  const [deadline, setdeadline] = useState("");
  const [fileName, setfileName] = useState(null);
  const languages = useSelector((state) => state.languages.languages);
  const userNames = useSelector((state) => state.auth.displayName);
  const token = useSelector((state) => state.auth.token);
  const [objects, setObjects] = useState([]);

  const fileSelectorHandler = (event) => {
    setfileName(event.target.files[0]);
  };

  const activating = (e) => {
    e.preventDefault();
    if (window.confirm("Are you sure you want change the status?")) {
      if (object?.status === constants.active) {
        dispatch(actions.desActivatingPublicationContents(token, object?.uuid));
        successUpdateNotification(WORKSPACE, "Inactive");
      } else {
        dispatch(actions.activatingPublicationContents(token, object?.uuid));
        successUpdateNotification(WORKSPACE, "Active");
      }
    } else {
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (title === "" || description === "") {
      setclicked(true);
    } else {
      if (object != null) {
        if (window.confirm("Are you sure you want update?")) {
          const obj = {
            title,
            description,
            deadline,
            id: object.uuid,editedBy:userNames,
          };
          dispatch(actions.updatePublicationContents(token, obj));
          settitle("");
          setdescription("");
          setclicked(false);
          successUpdateNotification(WORKSPACE, "Updated");
        } else {
        }
      } else {
        if (window.confirm("Are you sure you want submit?")) {
          const fd = new FormData();
          fd.append("fileName", fileName);
          fd.append("title", title);
          fd.append("publicationId", parentObject?.uuid);
          fd.append("creator", userNames);
          fd.append("description", description);
          fd.append("deadline", deadline);
          dispatch(actions.createPublicationContents(fd, token));
          settitle("");
          setdescription("");
          setclicked(false);
          setbtndisabled(false);
          successCreationNotification(WORKSPACE);
        } else {
        }
      }
    }
  };

  return (
    <div className="">
      <Header />
      <ReactNotifications />
      <main id="main">
        <div className="breadcrumbs-modified d-flex align-items-center"></div>
        {loading ? (
          <MiSpinner />
        ) : (
          <section id="contact" className="input-data">
            <div className="container" data-aos="fade-up">
              <div className="row d-flex justify-content-end php-email-form">
                <div className="col-md-8">
                  <div className="">
                    <div className="">
                      {object !== null ? (
                        <>
                          <h5 className="bdf-bluefontcolor">
                            EDITING {WORKSPACE} IN {parentObject?.title} (
                            {parentObject?.language})
                          </h5>
                        </>
                      ) : (
                        <h5 className="bdf-bluefontcolor">
                          CREATE {WORKSPACE} IN {parentObject?.title} - (
                          {parentObject?.language})
                        </h5>
                      )}
                    </div>
                    <div className="form-group py-2">
                      <InputText2
                        title={"Title"}
                        value={title}
                        name="title"
                        placeholder="Title"
                        isClicked={clicked}
                        onChange={(e) => settitle(e.target.value)}
                      />
                    </div>
                    <div className="form-group py-2">
                      <InputTextArea
                        isClicked={clicked}
                        title={"Description"}
                        placeholder="Description"
                        value={description}
                        name="description"
                        onChange={(e) => setdescription(e.target.value)}
                      />
                    </div>
                    <div className="form-group py-2">
                      <InputDate
                        isClicked={clicked}
                        title={"Deadline"}
                        placeholder="Deadline"
                        value={deadline}
                        name="deadline"
                        onChange={(e) => setdeadline(e.target.value)}
                      />
                    </div>
                    <div className="form-group py-2">
                      <UplodadFile
                        title="File"
                        updating={
                          object != null ? constants.isTrue : constants.isFalse
                        }
                        name="fileName"
                        required
                        accept={".pdf, .docx"}
                        onChange={fileSelectorHandler}
                      />
                    </div>
                    <div className="row gx-5"></div>
                    <SubmitButton
                      disabled={btndisabled}
                      action={object === null ? "Create" : "Update"}
                      onSubmit={onSubmit}
                    />
                    <span className="px-5"></span>
                    <ActivateButton
                      status={object?.status}
                      onSubmit={activating}
                    />
                  </div>
                </div>
                <div className="col-md-3 d-flex gy-5 ml-5 justify-content-end">
                  <div>
                    <div className="flex flex-column h-50 text-center">
                      <div>
                        <PageLink
                          page={
                            "/admin/publications-contents/" + parentObject?.uuid
                          }
                          title={"Contents List"}
                        />
                      </div>

                      <div className="d-none pt-5">
                        <PageLink
                          page={"/admin/publications-contents-new"}
                          title={"New Content"}
                        />
                      </div>
                      <div className="pt-5">
                        <PageLink
                          page={"/admin/publications/objects/list"}
                          title={"Publications"}
                        />
                      </div>
                    </div>
                    <div></div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
      </main>
      <ScrollToTop />
    </div>
  );
};

export const AdminYoutubeTestimonies = function (props) {
  const WORKSPACE = "Youtube Testimonies";
  const history = useHistory();
  const params = useParams();
  const [counter, setcounter] = useState(0);
  const dispatch = useDispatch();
  const [clicked, setclicked] = useState(false);
  const [btndisabled, setbtndisabled] = useState(false);
  const object = useSelector((state) => state.services.videoTestimonie);
  const loading = useSelector(
    (state) => state.services.videoTestimoniesLoading
  );
  useEffect(() => {
    dispatch(getLanguages());
    if (params?.objectId && object === null) {
      dispatch(actions.getOneVideoTestimonies(token, params?.objectId));
    }
    if (object !== null) {
      setvideoLink(object?.videoLink);
    }
  }, [object]);
  const [videoLink, setvideoLink] = useState("");
  const languages = useSelector((state) => state.languages.languages);
  const userNames = useSelector((state) => state.auth.displayName);
  const token = useSelector((state) => state.auth.token);
  const [objects, setObjects] = useState([]);

  const activating = (e) => {
    e.preventDefault();
    if (window.confirm("Are you sure you want change the status?")) {
      if (object?.status === constants.active) {
        dispatch(actions.desActivatingVideoTestimonies(token, object?.uuid));
        successUpdateNotification(WORKSPACE, "Inactive");
      } else {
        dispatch(actions.activatingVideoTestimonies(token, object?.uuid));
        successUpdateNotification(WORKSPACE, "Active");
      }
    } else {
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (videoLink === "") {
      setclicked(true);
    } else {
      if (object != null) {
        if (window.confirm("Are you sure you want update?")) {
          const obj = {
            videoLink,
            id: object.uuid,editedBy:userNames,
          };
          dispatch(actions.updateVideoTestimonies(token, obj));
          setvideoLink("");
          setclicked(false);
          successUpdateNotification(WORKSPACE, "Updated");
        } else {
        }
      } else {
        if (window.confirm("Are you sure you want submit?")) {
          const obj = {
            videoLink,
            creator: userNames,
          };
          dispatch(actions.createVideoTestimonies(obj, token));
          setcounter(0);
          setObjects([]);
          setvideoLink("");
          setclicked(false);
          setbtndisabled(false);
          successCreationNotification(WORKSPACE);
        } else {
        }
      }
    }
  };

  return (
    <div className="">
      <Header />
      <ReactNotifications />
      <main id="main">
        <div className="breadcrumbs-modified d-flex align-items-center"></div>
        {loading ? (
          <MiSpinner />
        ) : (
          <section id="contact" className="input-data">
            <div className="container" data-aos="fade-up">
              <div className="row d-flex justify-content-end php-email-form">
                <div className="col-md-8">
                  <div className="">
                    <div className="">
                      {object !== null ? (
                        <>
                          <h5 className="bdf-bluefontcolor">
                            EDIT {WORKSPACE}
                          </h5>
                        </>
                      ) : (
                        <h5 className="bdf-bluefontcolor">
                          CREATE {WORKSPACE}
                        </h5>
                      )}
                    </div>
                    <div className="form-group py-3">
                      <InputText2
                        title={"Video Link"}
                        value={videoLink}
                        name="videoLink"
                        placeholder="Video Link"
                        isClicked={clicked}
                        onChange={(e) => setvideoLink(e.target.value)}
                      />
                    </div>

                    <div className="row gx-5"></div>
                    <SubmitButton
                      disabled={btndisabled}
                      action={object === null ? "Create" : "Update"}
                      onSubmit={onSubmit}
                    />

                    <span className="px-5"></span>
                    <ActivateButton
                      status={object?.status}
                      onSubmit={activating}
                    />
                  </div>
                </div>
                <div className="col-md-3 d-flex gy-5 ml-5 justify-content-end">
                  <div>
                    <div className="flex flex-column h-50">
                      <PageLink
                        page={"/admin/youtube-testimonies/objects/list"}
                        title={"List"}
                      />
                    </div>
                    <div></div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
      </main>
      <ScrollToTop />
    </div>
  );
};

export const AdminTextTestimonies = function (props) {
  const WORKSPACE = "Testimonies";
  const history = useHistory();
  const params = useParams();
  const [counter, setcounter] = useState(0);
  const dispatch = useDispatch();
  const [clicked, setclicked] = useState(false);
  const [btndisabled, setbtndisabled] = useState(false);
  const services = useSelector((state) => state.services.services);
  const object = useSelector((state) => state.services.texttestimonie);
  const loading = useSelector((state) => state.services.texttestimoniesLoading);
  useEffect(() => {
    dispatch(actions.getServicesLanguage());
    if (params?.objectId && object === null) {
      dispatch(actions.getOneTextTestimonies(token, params?.objectId));
    }
    if (object !== null) {
      setfullname(object?.fullname);
      settestimony(object?.testimony);
      setrating(object?.rating);
      settitle(object?.title);
      setcompany(object?.company);
    }
  }, [object]);

  const setTheService = (value) => {
    setservice(value);
  };

  const setTheRating = (value) => {
    setrating(value);
  };

  const [fullname, setfullname] = useState("");
  const [testimony, settestimony] = useState("");
  const [company, setcompany] = useState("");
  const [rating, setrating] = useState("");
  const [title, settitle] = useState("");
  const [service, setservice] = useState({});
  const languages = useSelector((state) => state.languages.languages);
  const userNames = useSelector((state) => state.auth.displayName);
  const token = useSelector((state) => state.auth.token);
  const [objects, setObjects] = useState([]);
  const activating = (e) => {
    e.preventDefault();
    if (window.confirm("Are you sure you want change the status?")) {
      if (object?.status === constants.active) {
        dispatch(actions.desActivatingTextTestimonies(token, object?.uuid));
        successUpdateNotification(WORKSPACE, "Inactive");
      } else {
        dispatch(actions.activatingTextTestimonies(token, object?.uuid));
        successUpdateNotification(WORKSPACE, "Active");
      }
    } else {
    }
  };

  const ratingsTable = [
    { uuid: 1, name: 1 },
    { uuid: 2, name: 2 },
    { uuid: 3, name: 3 },
    { uuid: 4, name: 4 },
    { uuid: 5, name: 5 },
  ];

  const onSubmit = (e) => {
    e.preventDefault();
    if (fullname === "" || testimony === "") {
      setclicked(true);
    } else {
      if (object != null) {
        if (window.confirm("Are you sure you want update?")) {
          const obj = {
            fullname,
            testimony,
            title,
            company,
            rating,
            id: object.uuid,editedBy:userNames,
          };
          dispatch(actions.updateTextTestimonies(token, obj));
          setfullname("");
          settestimony("");
          settitle("");
          setcompany("");
          setrating("");
          setclicked(false);
          successUpdateNotification(WORKSPACE, "Updated");
        } else {
        }
      } else {
        if (counter < languages.length) {
          const lang = languages[counter]
            ? languages[counter].abbreviation
            : "";
          const languageName = languages[counter]
            ? languages[counter].fullname
            : "";
          const subs = setObjects([
            ...objects,
            {
              fullname,
              testimony,
              title,
              company,
              rating,
              creator: userNames,
              language: lang,
              serviceId: service,
            },
          ]);
          setcounter(counter + 1);
          successNotification(WORKSPACE, languageName);
          setfullname("");
          settestimony("");
          settitle("");
          setcompany("");
          setrating("");
          setclicked(false);
        }
        if (counter == languages.length - 1) {
          setfullname(fullname);
          settestimony(testimony);
          settitle(title);
          setcompany(company);
          setrating(rating);
          setbtndisabled(true);
        }
        if (counter == languages.length) {
          if (window.confirm("Are you sure you want submit?")) {
            dispatch(actions.createTextTestimonies(objects, token));
            setcounter(0);
            setObjects([]);
            setfullname("");
            settestimony("");
            settitle("");
            setcompany("");
            setrating("");
            setclicked(false);
            setbtndisabled(false);
            successCreationNotification(WORKSPACE);
          } else {
          }
        }
      }
    }
  };

  return (
    <div className="">
      <Header />
      <ReactNotifications />
      <main id="main">
        <div className="breadcrumbs-modified d-flex align-items-center"></div>
        {loading ? (
          <MiSpinner />
        ) : (
          <section id="contact" className="input-data">
            <div className="container" data-aos="fade-up">
              <div className="row d-flex justify-content-end php-email-form">
                <div className="col-md-8">
                  <div className="">
                    <div className="">
                      {object !== null ? (
                        <>
                          <h5 className="bdf-bluefontcolor">
                            EDIT {WORKSPACE} ({object?.language})
                          </h5>
                        </>
                      ) : (
                        <h5 className="bdf-bluefontcolor">
                          CREATE {WORKSPACE} IN: {languages[counter]?.name}
                        </h5>
                      )}
                    </div>
                    <div className="form-group py-1">
                      <InputText2
                        title={"Name"}
                        value={fullname}
                        name="fullname"
                        placeholder="Name"
                        isClicked={clicked}
                        onChange={(e) => setfullname(e.target.value)}
                      />
                    </div>
                    <div className="row py-1">
                      <div className="form-group col-md-6">
                        <InputText2
                          title={"Title"}
                          value={title}
                          name="title"
                          placeholder="Title"
                          isClicked={clicked}
                          onChange={(e) => settitle(e.target.value)}
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <InputText2
                          title={"Company"}
                          value={company}
                          name="company"
                          placeholder="Company"
                          isClicked={clicked}
                          onChange={(e) => setcompany(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="row py-1">
                      <div className="form-group col-md-6">
                        <SelectInput
                          title="Select Service"
                          startingValue={"SELECT"}
                          options={services}
                          value={service}
                          changed={setTheService}
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <SelectInput
                          title="Select Rate"
                          startingValue={"SELECT"}
                          options={ratingsTable}
                          value={rating}
                          changed={setTheRating}
                        />
                      </div>
                    </div>
                    <div className="form-group py-1">
                      <InputTextArea
                        isClicked={clicked}
                        title={"Testimony"}
                        placeholder="Testimony"
                        value={testimony}
                        name="testimony"
                        onChange={(e) => settestimony(e.target.value)}
                      />
                    </div>
                    <div className="row gx-5"></div>
                    <SubmitButton
                      disabled={btndisabled}
                      action={object === null ? "Create" : "Update"}
                      onSubmit={onSubmit}
                    />
                    <span className="px-2"></span>
                    <SubmitButton
                      disabled={!btndisabled}
                      classes={object === null ? "bg-success" : "d-none"}
                      action={"Submit"}
                      onSubmit={onSubmit}
                    />
                    <span className="px-5"></span>
                    <ActivateButton
                      status={object?.status}
                      onSubmit={activating}
                    />
                  </div>
                </div>
                <div className="col-md-3 d-flex gy-5 ml-5 justify-content-end">
                  <div>
                    <div className="flex flex-column h-50">
                      <PageLink
                        page={"/admin/text-testimonies/objects/list"}
                        title={"List"}
                      />
                    </div>
                    <div>
                      <LanguagesTable languages={languages} counter={counter} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
      </main>
      <ScrollToTop />
    </div>
  );
};
