import axios from "../../axios-base";

export const GET_SMS_SUCCESS = "GET_SMS_SUCCESS";
export const GET_ONE_SMS_SUCCESS = "GET_ONE_SMS_SUCCESS";
export const GET_SMS_START = "GET_SMS_START";
export const GET_SMS_FAIL = "GET_SMS_FAIL";

export const getSMSsSuccess = (data) => {
  return {
    type: GET_SMS_SUCCESS,
    payload: data,
  };
};

export const getOneSMSsSuccess = (data) => {
  return {
    type: GET_ONE_SMS_SUCCESS,
    payload: data,
  };
};

export const getSMSsStart = () => {
  return {
    type: GET_SMS_START,
  };
};
export const getSMSsFail = (error) => {
  return {
    type: GET_SMS_FAIL,
    payload: error,
  };
};

export const createSMSs = (object, token) => {
  return (dispatch) => {
    dispatch(getSMSsStart());
    axios
      .post(`/sms/send`, object, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getSMSsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getSMSsFail(err));
      });
  };
};

export const getSMSs = () => {
  return (dispatch) => {
    dispatch(getSMSsStart());
    axios
      .get(`/sms`)
      .then((response) => {
        dispatch(getSMSsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getSMSsFail(err));
      });
  };
};

export const getOneSMSs = (token, id) => {
  return (dispatch) => {
    dispatch(getSMSsStart());
    axios
      .get(`/sms/find/${id}`, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOneSMSsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getSMSsFail(err));
      });
  };
};