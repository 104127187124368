import axios from "../../axios-base";

export const GET_LANGUAGES_SUCCESS = "GET_LANGUAGES_SUCCESS";
export const GET_ONE_LANGUAGES_SUCCESS = "GET_ONE_LANGUAGES_SUCCESS";
export const GET_LANGUAGES_START = "GET_LANGUAGES_START";
export const GET_LANGUAGES_FAIL = "GET_LANGUAGES_FAIL";
export const GET_APPLICATION_LINKS_SUCCESS = "GET_APPLICATION_LINKS_SUCCESS";
export const GET_ONE_APPLICATION_LINKS_SUCCESS =
  "GET_ONE_APPLICATION_LINKS_SUCCESS";
export const GET_APPLICATION_LINKS_START = "GET_APPLICATION_LINKS_START";
export const GET_APPLICATION_LINKS_FAIL = "GET_APPLICATION_LINKS_FAIL";

export const getLanguagesSuccess = (languages) => {
  return {
    type: GET_LANGUAGES_SUCCESS,
    payload: languages,
  };
};

export const getOneLanguagesSuccess = (language) => {
  return {
    type: GET_ONE_LANGUAGES_SUCCESS,
    payload: language,
  };
};

export const getLanguagesStart = () => {
  return {
    type: GET_LANGUAGES_START,
  };
};
export const getLanguagesFail = (error) => {
  return {
    type: GET_LANGUAGES_FAIL,
    payload: error,
  };
};

export const getActiveLanguages = () => {
  return (dispatch) => {
    dispatch(getLanguagesStart());
    axios
      .get(`/languages/active`)
      .then((response) => {
        dispatch(getLanguagesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getLanguagesFail(err));
      });
  };
};

export const getLanguages = () => {
  return (dispatch) => {
    dispatch(getLanguagesStart());
    axios
      .get(`/languages`)
      .then((response) => {
        dispatch(getLanguagesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getLanguagesFail(err));
      });
  };
};

export const createLanguages = (object, token) => {
  return (dispatch) => {
    dispatch(getLanguagesStart());
    axios
      .post(`/languages/create`, object, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOneLanguagesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getLanguagesFail(err));
      });
  };
};

export const getOneLanguages = (token, id) => {
  return (dispatch) => {
    dispatch(getLanguagesStart());
    axios
      .get(`/languages/find/${id}`, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOneLanguagesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getLanguagesFail(err));
      });
  };
};

export const activatingLanguages = (token, id) => {
  return (dispatch) => {
    dispatch(getLanguagesStart());
    axios
      .put(`/languages/activating/${id}`, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOneLanguagesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getLanguagesFail(err));
      });
  };
};

export const desActivatingLanguages = (token, id) => {
  return (dispatch) => {
    dispatch(getLanguagesStart());
    axios
      .put(`/languages/desactivating/${id}`, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOneLanguagesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getLanguagesFail(err));
      });
  };
};

export const updateLanguages = (token, object) => {
  return (dispatch) => {
    dispatch(getLanguagesStart());
    axios
      .put(`/languages/edit/${object?.id}`, object, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOneLanguagesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getLanguagesFail(err));
      });
  };
};

export const getApplicationLinksSuccess = (languages) => {
  return {
    type: GET_APPLICATION_LINKS_SUCCESS,
    payload: languages,
  };
};

export const getOneApplicationLinksSuccess = (language) => {
  return {
    type: GET_ONE_APPLICATION_LINKS_SUCCESS,
    payload: language,
  };
};

export const getApplicationLinksStart = () => {
  return {
    type: GET_APPLICATION_LINKS_START,
  };
};
export const getApplicationLinksFail = (error) => {
  return {
    type: GET_APPLICATION_LINKS_FAIL,
    payload: error,
  };
};

export const getActiveApplicationLinks = () => {
  return (dispatch) => {
    dispatch(getApplicationLinksStart());
    axios
      .get(`/application-links/active`)
      .then((response) => {
        dispatch(getApplicationLinksSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getApplicationLinksFail(err));
      });
  };
};

export const getApplicationLinks = () => {
  return (dispatch) => {
    dispatch(getApplicationLinksStart());
    axios
      .get(`/application-links`)
      .then((response) => {
        dispatch(getApplicationLinksSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getApplicationLinksFail(err));
      });
  };
};

export const createApplicationLinks = (object, token) => {
  return (dispatch) => {
    dispatch(getApplicationLinksStart());
    axios
      .post(`/application-links/create`, object, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOneApplicationLinksSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getApplicationLinksFail(err));
      });
  };
};

export const getOneApplicationLinks = (token, id) => {
  return (dispatch) => {
    dispatch(getApplicationLinksStart());
    axios
      .get(`/application-links/find/${id}`, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOneApplicationLinksSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getApplicationLinksFail(err));
      });
  };
};

export const activatingApplicationLinks = (token, id) => {
  return (dispatch) => {
    dispatch(getApplicationLinksStart());
    axios
      .put(`/application-links/activating/${id}`, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOneApplicationLinksSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getApplicationLinksFail(err));
      });
  };
};

export const desActivatingApplicationLinks = (token, id) => {
  return (dispatch) => {
    dispatch(getApplicationLinksStart());
    axios
      .put(`/application-links/desactivating/${id}`, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOneApplicationLinksSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getApplicationLinksFail(err));
      });
  };
};

export const updateApplicationLinks = (token, object) => {
  return (dispatch) => {
    dispatch(getApplicationLinksStart());
    axios
      .put(`/application-links/edit/${object?.id}`, object, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOneApplicationLinksSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getApplicationLinksFail(err));
      });
  };
};
