import classnames from "classnames";
import { NavLink } from "react-router-dom";
import constants from "../../components/constants";

export const InputText = function (props) {
  return (
    <input
      type="text"
      name={props?.name}
      value={props?.value}
      className="form-control"
      placeholder={props?.placeholder}
      onChange={props?.onChange}
    />
  );
};

export const InputText2 = function (props) {
  let actualValue = false;
  if (props.value == "" && props.isClicked == true) {
    actualValue = true;
  }
  return (
    <div className="">
      <span className="bdf-bluefontcolor">{props.title}</span>
      <input
        type="text"
        name={props.name}
        value={props.value}
        className={classnames("form-control", {
          "form-control border-danger": actualValue,
        })}
        placeholder={props.placeholder}
        onChange={props.onChange}
      />
      <span className="text-danger">
        {actualValue === true ? props.title + " Is Required" : ""}
      </span>
    </div>
  );
};

export const InputTextSecret2 = function (props) {
  let actualValue = false;
  if (props.value == "" && props.isClicked == true) {
    actualValue = true;
  }
  return (
    <div className="">
      <span className="bdf-bluefontcolor">{props.title}</span>
      <input
        type="password"
        name={props.name}
        value={props.value}
        className={classnames("form-control", {
          "form-control border-danger": actualValue,
        })}
        placeholder={props.placeholder}
        onChange={props.onChange}
      />
      <span className="text-danger">
        {actualValue === true ? props.title + " Is Required" : ""}
      </span>
    </div>
  );
};

export const SelectInput = function (props) {
  let actualValue = false;
  if (props.value == "" && props.isClicked == true) {
    actualValue = true;
  }
  const onChangeHandler = (event) => {
    let value;
    if (event.target) {
      value = event.target.value;
    } else {
      value = event;
    }
    props.changed(value);
  };
  return (
    <div className="">
      <span className="bdf-bluefontcolor">{props.title}</span>
      <select
        className={"w-full form-control"}
        value={props.value}
        onChange={onChangeHandler}
      >
        <option>{props?.startingValue}</option>
        {(
          props?.elementConfig?.hasOwnProperty("optionsType")
            ? props?.elementConfig?.optionsType === "minimal"
            : false
        )
          ? props?.elementConfig?.options.map((option, index) => (
              <option value={option} key={index}>
                {option}
              </option>
            ))
          : props?.options.map((option, index) => (
              <option value={option.uuid} key={index}>
                {option.name}
              </option>
            ))}
      </select>
      <span className="text-danger">
        {actualValue === true ? props.title + " Is Required" : ""}
      </span>
    </div>
  );
};

export const InputDate = function (props) {
  let actualValue = false;
  let msg = " Is Required";
  if (props.value == "" && props.isClicked == true) {
    actualValue = true;
  }
  var date = new Date();
  var thedate = new Date(props?.value);
  // date.setDate(date.getDate() - 1);
  if (thedate.getTime() < date.getTime()) {
    actualValue = true;
    msg = " Can not be Today or in the Past";
  }
  return (
    <div className="">
      <span className="bdf-bluefontcolor">{props.title}</span>
      <input
        type="date"
        min={new Date()}
        name={props.name}
        value={props.value}
        className={classnames("form-control", {
          "form-control border-danger": actualValue,
        })}
        placeholder={props.placeholder}
        onChange={props.onChange}
      />
      <span className="text-danger">
        {actualValue === true ? props.title + msg : ""}
      </span>
    </div>
  );
};

export const InputTelephone = function (props) {
  let actualValue = false;
  let theValue = props?.value;
  let msg = " is Required";
  let  removeChar = props?.value?.replace(/[^0-9.]/g, "");
  let  removeDot = removeChar.replace(/\./g, "");
  let formatedNumber = removeDot.replace(/\B((?!\d))/g, "");
  theValue = formatedNumber;
  let actnumber = removeDot;


  if (props.value.trim() === "" && props.isClicked == true) {
    actualValue = true;
  } else if (
    (props?.value?.slice(0, 2) !== "07" ||
      constants.containsOnlyNumbers(props?.value) !== true ||
      props.value.length !== 10) &&
    props.isClicked == true
  ) {
    actualValue = true;
    msg = " must be 10 numbers. format 07xxxxxxxx";
  } else if (props?.unique === "true" && props.isClicked == true) {
    actualValue = true;
    msg = " " + props.value + " is used";
  }
  return (
    <div className="w-100">
      <span className="bdf-bluefontcolor">{props.title} (ex: 0788888888)</span>
      <input
        type="text"
        name={props.name}
        value={theValue}
        className={classnames("form-control", {
          "form-control border-danger": actualValue,
        })}
        placeholder={props.placeholder}
        onChange={props.onChange}
      />
      <span className="text-danger">
        {actualValue === true ? props.title + msg : ""}
      </span>
    </div>
  );
};

export const UplodadFile = function (props) {
  let accept = ".jpeg, .jpg, .png, .svg";
  let classes = "";
  let required = props.required;
  let actualValue = false;
  if (props.value == "" && props.isClicked == true) {
    actualValue = true;
  }

  if (props?.accept) {
    accept = props?.accept;
  }

  if (props.updating == constants.isTrue) {
    classes = "d-none";
    required = false;
  }

  return (
    <div className={classes}>
      <span className="bdf-bluefontcolor">{props.title}</span>
      <input
        required={required}
        type="file"
        name={props.name}
        accept={accept}
        className={classnames("form-control", {
          "form-control border-danger": actualValue,
        })}
        placeholder={props.placeholder}
        onChange={props.onChange}
      />
      <span className="text-danger">
        {actualValue === true ? props.title + " Is Required" : ""}
      </span>
    </div>
  );
};

export const InputTextArea = function (props) {
  let actualValue = false;
  let rowsValue = 3 + props?.rowsNbr;
  if (props.value == "" && props.isClicked == true) {
    actualValue = true;
  }

  return (
    <div className="">
      <span className="bdf-bluefontcolor">{props?.title}</span>
      <textarea
        className={classnames("form-control", {
          "form-control border-danger": actualValue,
        })}
        name={props?.name}
        value={props?.value}
        rows={rowsValue}
        placeholder={props?.placeholder}
        onChange={props?.onChange}
      ></textarea>
      <span className="text-danger">
        {actualValue === true ? props.title + " Is Required" : ""}
      </span>
    </div>
  );
};

export const SelectRadioButton = (props) => {
  let actualValue = false;
  if (props.value == "" && props.isClicked == true) {
    actualValue = true;
  }
  return (
    <div className="w-50">
      <label className="bdf-bluefontcolor">{props.title} </label>
      <div className="row" onChange={props.onChange}>
        <div className="space-x-2 col-md-3">
          <input
            type="radio"
            value={props.option1}
            id="true"
            name={props.name}
          />
          <label for="true" className="px-2">
            {props.option1}
          </label>
        </div>
        <div className="space-x-2 col-md-3">
          <input
            type="radio"
            value={props.option2}
            id="false"
            name={props.name}
          />
          <label for="false" className="px-2">
            {props.option2}
          </label>
        </div>
      </div>
      <span className="text-danger">
        {actualValue === true ? "Select One Option" : ""}
      </span>
    </div>
  );
};

export const SubmitButton = function (props) {
  let classes = props?.classes;
  return (
    <button
      disabled={props?.disabled}
      onClick={props?.onSubmit}
      type="submit"
      className={"col " + classes}
    >
      {props.action}
    </button>
  );
};

export const ActivateButton = function (props) {
  let classes = "d-none";
  let title = "";
  if (props?.status) {
    if (props?.status === "active") {
      classes = "d-show bg-danger";
      title = "Desactivate";
    } else {
      classes = "d-show bg-success";
      title = "Activate";
    }
  }
  return (
    <button
      disabled={props?.disabled}
      onClick={props?.onSubmit}
      type="submit"
      className={"col " + classes}
    >
      {title}
    </button>
  );
};

export const PageLink = function (props) {
  return (
    <NavLink
      to={props?.page}
      className="bg-bdf-blue px-5 py-2 text-white font-bold"
    >
      {props?.title}
    </NavLink>
  );
};

export const InputTextSecret = function (props) {
  return (
    <input
      type="password"
      name={props?.name}
      value={props?.value}
      className="form-control"
      placeholder={props?.placeholder}
      onChange={props?.onChange}
    />
  );
};

export const EditButton = function (props) {
  let classes = props?.classes;
  let idpath = props?.id;
  if (props?.parentId) {
    idpath = props?.parentId + "/" + props?.id;
  }
  return (
    <NavLink
      to={props?.page + "/" + idpath}
      className="bg-bdf-blue py-1 px-2 text-white w-auto"
    >
      {props?.title}
    </NavLink>
  );
};

export const ViewButton = function (props) {
  let classes = props?.classes;
  return (
    <button
      type="submit"
      onClick={props.onClick}
      className="py-1 px-2 text-white w-50 bg-success w-auto"
    >
      {props?.title}
    </button>
  );
};
