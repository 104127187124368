import axios from "../../axios-base";

export const GET_BACKGROUNDS_SUCCESS = "GET_BACKGROUNDS_SUCCESS";
export const GET_ONE_BACKGROUNDS_SUCCESS = "GET_ONE_BACKGROUNDS_SUCCESS";
export const GET_BACKGROUNDS_START = "GET_BACKGROUNDS_START";
export const GET_BACKGROUNDS_FAIL = "GET_BACKGROUNDS_FAIL";
export const GET_MISSION_VISION_VALUES_SUCCESS =
  "GET_MISSION_VISION_VALUES_SUCCESS";
export const GET_ONE_MISSION_VISION_VALUES_SUCCESS =
  "GET_ONE_MISSION_VISION_VALUES_SUCCESS";
export const GET_MISSION_VISION_VALUES_START =
  "GET_MISSION_VISION_VALUES_START";
export const GET_MISSION_VISION_VALUES_FAIL = "GET_MISSION_VISION_VALUES_FAIL";
export const GET_SM_EXECUTIVES_SUCCESS = "GET_SM_EXECUTIVES_SUCCESS";
export const GET_ONE_SM_EXECUTIVES_SUCCESS = "GET_ONE_SM_EXECUTIVES_SUCCESS";
export const GET_SM_EXECUTIVES_START = "GET_SM_EXECUTIVES_START";
export const GET_SM_EXECUTIVES_FAIL = "GET_SM_EXECUTIVES_FAIL";
export const GET_SM_MANAGERS_SUCCESS = "GET_SM_MANAGERS_SUCCESS";
export const GET_ONE_SM_MANAGERS_SUCCESS = "GET_ONE_SM_MANAGERS_SUCCESS";
export const GET_SM_MANAGERS_START = "GET_SM_MANAGERS_START";
export const GET_SM_MANAGERS_FAIL = "GET_SM_MANAGERS_FAIL";
export const GET_BD_EXECUTIVES_SUCCESS = "GET_BD_EXECUTIVES_SUCCESS";
export const GET_ONE_BD_EXECUTIVES_SUCCESS = "GET_ONE_BD_EXECUTIVES_SUCCESS";
export const GET_BD_EXECUTIVES_START = "GET_BD_EXECUTIVES_START";
export const GET_BD_EXECUTIVES_FAIL = "GET_BD_EXECUTIVES_FAIL";
export const GET_BD_MANAGERS_SUCCESS = "GET_BD_MANAGERS_SUCCESS";
export const GET_ONE_BD_MANAGERS_SUCCESS = "GET_ONE_BD_MANAGERS_SUCCESS";
export const GET_BD_MANAGERS_START = "GET_BD_MANAGERS_START";
export const GET_BD_MANAGERS_FAIL = "GET_BD_MANAGERS_FAIL";

export const getBackgroundsSuccess = (data) => {
  return {
    type: GET_BACKGROUNDS_SUCCESS,
    payload: data,
  };
};

export const getOneBackgroundsSuccess = (data) => {
  return {
    type: GET_ONE_BACKGROUNDS_SUCCESS,
    payload: data,
  };
};

export const getBackgroundsStart = () => {
  return {
    type: GET_BACKGROUNDS_START,
  };
};
export const getBackgroundsFail = (error) => {
  return {
    type: GET_BACKGROUNDS_FAIL,
    payload: error,
  };
};

export const getActiveBackgrounds = (lang) => {
  return (dispatch) => {
    dispatch(getBackgroundsStart());
    axios
      .get(`/bdf-backgrounds/active/${lang}`)
      .then((response) => {
        dispatch(getOneBackgroundsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getBackgroundsFail(err));
      });
  };
};

export const createBackgrounds = (object, token) => {
  return (dispatch) => {
    dispatch(getBackgroundsStart());
    axios
      .post(`/bdf-backgrounds/create`, object, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOneBackgroundsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getBackgroundsFail(err));
      });
  };
};

export const getBackgrounds = () => {
  return (dispatch) => {
    dispatch(getBackgroundsStart());
    axios
      .get(`/bdf-backgrounds`)
      .then((response) => {
        dispatch(getBackgroundsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getBackgroundsFail(err));
      });
  };
};

export const getOneBackgrounds = (token, id) => {
  return (dispatch) => {
    dispatch(getBackgroundsStart());
    axios
      .get(`/bdf-backgrounds/find/${id}`, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOneBackgroundsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getBackgroundsFail(err));
      });
  };
};

export const activatingBackgrounds = (token, id) => {
  return (dispatch) => {
    dispatch(getBackgroundsStart());
    axios
      .put(`/bdf-backgrounds/activating/${id}`, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOneBackgroundsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getBackgroundsFail(err));
      });
  };
};

export const desActivatingBackgrounds = (token, id) => {
  return (dispatch) => {
    dispatch(getBackgroundsStart());
    axios
      .put(`/bdf-backgrounds/desactivating/${id}`, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOneBackgroundsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getBackgroundsFail(err));
      });
  };
};

export const updateBackgrounds = (token, object) => {
  return (dispatch) => {
    dispatch(getBackgroundsStart());
    axios
      .put(`/bdf-backgrounds/edit/${object?.id}`, object, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOneBackgroundsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getBackgroundsFail(err));
      });
  };
};

export const getMissionVisionValuesSuccess = (data) => {
  return {
    type: GET_MISSION_VISION_VALUES_SUCCESS,
    payload: data,
  };
};

export const getOneMissionVisionValuesSuccess = (data) => {
  return {
    type: GET_ONE_MISSION_VISION_VALUES_SUCCESS,
    payload: data,
  };
};

export const getMissionVisionValuesStart = () => {
  return {
    type: GET_MISSION_VISION_VALUES_START,
  };
};
export const getMissionVisionValuesFail = (error) => {
  return {
    type: GET_MISSION_VISION_VALUES_FAIL,
    payload: error,
  };
};

export const getActiveMissionVisionValues = (lang) => {
  return (dispatch) => {
    dispatch(getMissionVisionValuesStart());
    axios
      .get(`/bdf-missions/mission-vision-values/active/${lang}`)
      .then((response) => {
        dispatch(getOneMissionVisionValuesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getMissionVisionValuesFail(err));
      });
  };
};

export const getSMExecutivesSuccess = (data) => {
  return {
    type: GET_SM_EXECUTIVES_SUCCESS,
    payload: data,
  };
};

export const getOneSMExecutivesSuccess = (data) => {
  return {
    type: GET_ONE_SM_EXECUTIVES_SUCCESS,
    payload: data,
  };
};

export const getSMExecutivesStart = () => {
  return {
    type: GET_SM_EXECUTIVES_START,
  };
};
export const getSMExecutivesFail = (error) => {
  return {
    type: GET_SM_EXECUTIVES_FAIL,
    payload: error,
  };
};

export const getActiveSMExecutives = (lang) => {
  return (dispatch) => {
    dispatch(getSMExecutivesStart());
    axios
      .get(`/executives/sm-executives/active/${lang}`)
      .then((response) => {
        dispatch(getSMExecutivesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getSMExecutivesFail(err));
      });
  };
};

export const createSMExecutives = (object, token) => {
  return (dispatch) => {
    dispatch(getSMExecutivesStart());
    axios
      .post(`/executives/create`, object, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOneSMExecutivesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getSMExecutivesFail(err));
      });
  };
};

export const getSMExecutives = () => {
  return (dispatch) => {
    dispatch(getSMExecutivesStart());
    axios
      .get(`/executives`)
      .then((response) => {
        dispatch(getSMExecutivesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getSMExecutivesFail(err));
      });
  };
};

export const getOneSMExecutives = (token, id) => {
  return (dispatch) => {
    dispatch(getSMExecutivesStart());
    axios
      .get(`/executives/find/${id}`, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOneSMExecutivesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getSMExecutivesFail(err));
      });
  };
};

export const activatingSMExecutives = (token, id) => {
  return (dispatch) => {
    dispatch(getSMExecutivesStart());
    axios
      .put(`/executives/activating/${id}`, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOneSMExecutivesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getSMExecutivesFail(err));
      });
  };
};

export const desActivatingSMExecutives = (token, id) => {
  return (dispatch) => {
    dispatch(getSMExecutivesStart());
    axios
      .put(`/executives/desactivating/${id}`, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOneSMExecutivesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getSMExecutivesFail(err));
      });
  };
};

export const updateSMExecutives = (token, object) => {
  return (dispatch) => {
    dispatch(getSMExecutivesStart());
    axios
      .put(`/executives/edit/${object?.id}`, object, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOneSMExecutivesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getSMExecutivesFail(err));
      });
  };
};

export const getSMManagersSuccess = (data) => {
  return {
    type: GET_SM_MANAGERS_SUCCESS,
    payload: data,
  };
};

export const getOneSMManagersSuccess = (data) => {
  return {
    type: GET_ONE_SM_MANAGERS_SUCCESS,
    payload: data,
  };
};

export const getSMManagersStart = () => {
  return {
    type: GET_SM_MANAGERS_START,
  };
};
export const getSMManagersFail = (error) => {
  return {
    type: GET_SM_MANAGERS_FAIL,
    payload: error,
  };
};

export const getActiveSMManagers = (lang) => {
  return (dispatch) => {
    dispatch(getSMManagersStart());
    axios
      .get(`/executives/sm-managers/active/${lang}`)
      .then((response) => {
        dispatch(getSMManagersSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getSMManagersFail(err));
      });
  };
};

export const createMissions = (object, token) => {
  return (dispatch) => {
    dispatch(getMissionVisionValuesStart());
    axios
      .post(`/bdf-missions/create`, object, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOneMissionVisionValuesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getMissionVisionValuesFail(err));
      });
  };
};

export const getMissions = () => {
  return (dispatch) => {
    dispatch(getMissionVisionValuesStart());
    axios
      .get(`/bdf-missions`)
      .then((response) => {
        dispatch(getMissionVisionValuesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getMissionVisionValuesFail(err));
      });
  };
};

export const getOneMissions = (token, id) => {
  return (dispatch) => {
    dispatch(getMissionVisionValuesStart());
    axios
      .get(`/bdf-missions/find/${id}`, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOneMissionVisionValuesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getMissionVisionValuesFail(err));
      });
  };
};

export const activatingMissions = (token, id) => {
  return (dispatch) => {
    dispatch(getMissionVisionValuesStart());
    axios
      .put(`/bdf-missions/activating/${id}`, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOneMissionVisionValuesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getMissionVisionValuesFail(err));
      });
  };
};

export const desActivatingMissions = (token, id) => {
  return (dispatch) => {
    dispatch(getMissionVisionValuesStart());
    axios
      .put(`/bdf-missions/desactivating/${id}`, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOneMissionVisionValuesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getMissionVisionValuesFail(err));
      });
  };
};

export const updateMissions = (token, object) => {
  return (dispatch) => {
    dispatch(getMissionVisionValuesStart());
    axios
      .put(`/bdf-missions/edit/${object?.id}`, object, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOneMissionVisionValuesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getMissionVisionValuesFail(err));
      });
  };
};

export const createVisions = (object, token) => {
  return (dispatch) => {
    dispatch(getMissionVisionValuesStart());
    axios
      .post(`/bdf-visions/create`, object, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOneMissionVisionValuesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getMissionVisionValuesFail(err));
      });
  };
};

export const getVisions = () => {
  return (dispatch) => {
    dispatch(getMissionVisionValuesStart());
    axios
      .get(`/bdf-visions`)
      .then((response) => {
        dispatch(getMissionVisionValuesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getMissionVisionValuesFail(err));
      });
  };
};

export const getOneVisions = (token, id) => {
  return (dispatch) => {
    dispatch(getMissionVisionValuesStart());
    axios
      .get(`/bdf-visions/find/${id}`, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOneMissionVisionValuesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getMissionVisionValuesFail(err));
      });
  };
};

export const activatingVisions = (token, id) => {
  return (dispatch) => {
    dispatch(getMissionVisionValuesStart());
    axios
      .put(`/bdf-visions/activating/${id}`, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOneMissionVisionValuesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getMissionVisionValuesFail(err));
      });
  };
};

export const desActivatingVisions = (token, id) => {
  return (dispatch) => {
    dispatch(getMissionVisionValuesStart());
    axios
      .put(`/bdf-visions/desactivating/${id}`, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOneMissionVisionValuesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getMissionVisionValuesFail(err));
      });
  };
};

export const updateVisions = (token, object) => {
  return (dispatch) => {
    dispatch(getMissionVisionValuesStart());
    axios
      .put(`/bdf-visions/edit/${object?.id}`, object, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOneMissionVisionValuesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getMissionVisionValuesFail(err));
      });
  };
};

export const createValues = (object, token) => {
  return (dispatch) => {
    dispatch(getMissionVisionValuesStart());
    axios
      .post(`/bdf-values/create`, object, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOneMissionVisionValuesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getMissionVisionValuesFail(err));
      });
  };
};

export const getValues = () => {
  return (dispatch) => {
    dispatch(getMissionVisionValuesStart());
    axios
      .get(`/bdf-values`)
      .then((response) => {
        dispatch(getMissionVisionValuesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getMissionVisionValuesFail(err));
      });
  };
};

export const getOneValues = (token, id) => {
  return (dispatch) => {
    dispatch(getMissionVisionValuesStart());
    axios
      .get(`/bdf-values/find/${id}`, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOneMissionVisionValuesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getMissionVisionValuesFail(err));
      });
  };
};

export const activatingValues = (token, id) => {
  return (dispatch) => {
    dispatch(getMissionVisionValuesStart());
    axios
      .put(`/bdf-values/activating/${id}`, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOneMissionVisionValuesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getMissionVisionValuesFail(err));
      });
  };
};

export const desActivatingValues = (token, id) => {
  return (dispatch) => {
    dispatch(getMissionVisionValuesStart());
    axios
      .put(`/bdf-values/desactivating/${id}`, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOneMissionVisionValuesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getMissionVisionValuesFail(err));
      });
  };
};

export const updateValues = (token, object) => {
  return (dispatch) => {
    dispatch(getMissionVisionValuesStart());
    axios
      .put(`/bdf-values/edit/${object?.id}`, object, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOneMissionVisionValuesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getMissionVisionValuesFail(err));
      });
  };
};

export const getBDManagersSuccess = (data) => {
  return {
    type: GET_BD_MANAGERS_SUCCESS,
    payload: data,
  };
};

export const getOneBDManagersSuccess = (data) => {
  return {
    type: GET_ONE_BD_MANAGERS_SUCCESS,
    payload: data,
  };
};

export const getBDManagersStart = () => {
  return {
    type: GET_BD_MANAGERS_START,
  };
};
export const getBDManagersFail = (error) => {
  return {
    type: GET_BD_MANAGERS_FAIL,
    payload: error,
  };
};

export const getBDExecutivesSuccess = (data) => {
  return {
    type: GET_BD_EXECUTIVES_SUCCESS,
    payload: data,
  };
};

export const getOneBDExecutivesSuccess = (data) => {
  return {
    type: GET_ONE_BD_EXECUTIVES_SUCCESS,
    payload: data,
  };
};

export const getBDExecutivesStart = () => {
  return {
    type: GET_BD_EXECUTIVES_START,
  };
};
export const getBDExecutivesFail = (error) => {
  return {
    type: GET_BD_EXECUTIVES_FAIL,
    payload: error,
  };
};

export const getActiveBDExecutives = (lang) => {
  return (dispatch) => {
    dispatch(getBDExecutivesStart());
    axios
      .get(`/executives/bd-executives/active/${lang}`)
      .then((response) => {
        dispatch(getBDExecutivesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getBDExecutivesFail(err));
      });
  };
};

export const getActiveBDManagers = (lang) => {
  return (dispatch) => {
    dispatch(getBDManagersStart());
    axios
      .get(`/executives/bd-managers/active/${lang}`)
      .then((response) => {
        dispatch(getBDManagersSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getBDManagersFail(err));
      });
  };
};
