export const career = "career";
export const tender = "tender";
export const report = "report";
export const active = "active";
export const inactive = "inactive";
export const isTrue = "true";
export const isFalse = "false";
export const executive = "executive";
export const manager = "manager";
export const boardofdirectors = "bd";
export const seniormanagement = "sm";

const containsOnlyNumbers = (str) => {
  return /^\d+$/.test(str);
};

export default {
  career,
  report,
  tender,
  active,
  inactive,
  isTrue,
  isFalse,
  executive,
  manager,
  boardofdirectors,
  seniormanagement,
  containsOnlyNumbers
};
