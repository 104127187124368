import axios from "../../axios-base";

export const GET_TRAINING_INTROS_SUCCESS = "GET_TRAINING_INTROS_SUCCESS";
export const GET_ONE_TRAINING_INTROS_SUCCESS =
  "GET_ONE_TRAINING_INTROS_SUCCESS";
export const GET_TRAINING_INTROS_START = "GET_TRAINING_INTROS_START";
export const GET_TRAINING_INTROS_FAIL = "GET_TRAINING_INTROS_FAIL";
export const GET_TRAINING_CATEGORIES_SUCCESS =
  "GET_TRAINING_CATEGORIES_SUCCESS";
export const GET_ONE_TRAINING_CATEGORIES_SUCCESS =
  "GET_ONE_TRAINING_CATEGORIES_SUCCESS";
export const GET_TRAINING_CATEGORIES_START = "GET_TRAINING_CATEGORIES_START";
export const GET_TRAINING_CATEGORIES_FAIL = "GET_TRAINING_CATEGORIES_FAIL";
export const GET_CATEGORY_COMPONENTS_SUCCESS =
  "GET_CATEGORY_COMPONENTS_SUCCESS";
export const GET_ONE_CATEGORY_COMPONENTS_SUCCESS =
  "GET_ONE_CATEGORY_COMPONENTS_SUCCESS";
export const GET_CATEGORY_COMPONENTS_START = "GET_CATEGORY_COMPONENTS_START";
export const GET_CATEGORY_COMPONENTS_FAIL = "GET_CATEGORY_COMPONENTS_FAIL";

export const getTrainingIntrosSuccess = (data) => {
  return {
    type: GET_TRAINING_INTROS_SUCCESS,
    payload: data,
  };
};

export const getOneTrainingIntrosSuccess = (data) => {
  return {
    type: GET_ONE_TRAINING_INTROS_SUCCESS,
    payload: data,
  };
};

export const getTrainingIntrosStart = () => {
  return {
    type: GET_TRAINING_INTROS_START,
  };
};
export const getTrainingIntrosFail = (error) => {
  return {
    type: GET_TRAINING_INTROS_FAIL,
    payload: error,
  };
};

export const getActiveTrainingIntros = (lang) => {
  return (dispatch) => {
    dispatch(getTrainingIntrosStart());
    axios
      .get(`/training-intros/active/${lang}`)
      .then((response) => {
        dispatch(getOneTrainingIntrosSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getTrainingIntrosFail(err));
      });
  };
};

export const createTrainingIntros = (object, token) => {
  return (dispatch) => {
    dispatch(getTrainingIntrosStart());
    axios
      .post(`/training-intros/create`, object, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOneTrainingIntrosSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getTrainingIntrosFail(err));
      });
  };
};

export const getTrainingIntros = () => {
  return (dispatch) => {
    dispatch(getTrainingIntrosStart());
    axios
      .get(`/training-intros`)
      .then((response) => {
        dispatch(getTrainingIntrosSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getTrainingIntrosFail(err));
      });
  };
};

export const getOneTrainingIntros = (token, id) => {
  return (dispatch) => {
    dispatch(getTrainingIntrosStart());
    axios
      .get(`/training-intros/find/${id}`, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOneTrainingIntrosSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getTrainingIntrosFail(err));
      });
  };
};

export const activatingTrainingIntros = (token, id) => {
  return (dispatch) => {
    dispatch(getTrainingIntrosStart());
    axios
      .put(`/training-intros/activating/${id}`, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOneTrainingIntrosSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getTrainingIntrosFail(err));
      });
  };
};

export const desActivatingTrainingIntros = (token, id) => {
  return (dispatch) => {
    dispatch(getTrainingIntrosStart());
    axios
      .put(`/training-intros/desactivating/${id}`, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOneTrainingIntrosSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getTrainingIntrosFail(err));
      });
  };
};

export const updateTrainingIntros = (token, object) => {
  return (dispatch) => {
    dispatch(getTrainingIntrosStart());
    axios
      .put(`/training-intros/edit/${object?.id}`, object, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOneTrainingIntrosSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getTrainingIntrosFail(err));
      });
  };
};

export const getTrainingCategoriesSuccess = (data) => {
  return {
    type: GET_TRAINING_CATEGORIES_SUCCESS,
    payload: data,
  };
};

export const getOneTrainingCategoriesSuccess = (data) => {
  return {
    type: GET_ONE_TRAINING_CATEGORIES_SUCCESS,
    payload: data,
  };
};

export const getTrainingCategoriesStart = () => {
  return {
    type: GET_TRAINING_CATEGORIES_START,
  };
};
export const getTrainingCategoriesFail = (error) => {
  return {
    type: GET_TRAINING_CATEGORIES_FAIL,
    payload: error,
  };
};

export const getActiveTrainingCategories = (lang) => {
  return (dispatch) => {
    dispatch(getTrainingCategoriesStart());
    axios
      .get(`/training-categories/active/${lang}`)
      .then((response) => {
        dispatch(getTrainingCategoriesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getTrainingCategoriesFail(err));
      });
  };
};

export const createTrainingCategories = (object, token) => {
  return (dispatch) => {
    dispatch(getTrainingCategoriesStart());
    axios
      .post(`/training-categories/create`, object, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOneTrainingCategoriesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getTrainingCategoriesFail(err));
      });
  };
};

export const getTrainingCategories = () => {
  return (dispatch) => {
    dispatch(getTrainingCategoriesStart());
    axios
      .get(`/training-categories`)
      .then((response) => {
        dispatch(getTrainingCategoriesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getTrainingCategoriesFail(err));
      });
  };
};

export const getOneTrainingCategories = (token, id) => {
  return (dispatch) => {
    dispatch(getTrainingCategoriesStart());
    axios
      .get(`/training-categories/find/${id}`, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOneTrainingCategoriesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getTrainingCategoriesFail(err));
      });
  };
};

export const activatingTrainingCategories = (token, id) => {
  return (dispatch) => {
    dispatch(getTrainingCategoriesStart());
    axios
      .put(`/training-categories/activating/${id}`, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOneTrainingCategoriesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getTrainingCategoriesFail(err));
      });
  };
};

export const desActivatingTrainingCategories = (token, id) => {
  return (dispatch) => {
    dispatch(getTrainingCategoriesStart());
    axios
      .put(`/training-categories/desactivating/${id}`, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOneTrainingCategoriesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getTrainingCategoriesFail(err));
      });
  };
};

export const updateTrainingCategories = (token, object) => {
  return (dispatch) => {
    dispatch(getTrainingCategoriesStart());
    axios
      .put(`/training-categories/edit/${object?.id}`, object, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOneTrainingCategoriesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getTrainingCategoriesFail(err));
      });
  };
};

export const getCategoryComponentsSuccess = (data) => {
  return {
    type: GET_CATEGORY_COMPONENTS_SUCCESS,
    payload: data,
  };
};

export const getOneCategoryComponentsSuccess = (data) => {
  return {
    type: GET_ONE_CATEGORY_COMPONENTS_SUCCESS,
    payload: data,
  };
};

export const getCategoryComponentsStart = () => {
  return {
    type: GET_CATEGORY_COMPONENTS_START,
  };
};
export const getCategoryComponentsFail = (error) => {
  return {
    type: GET_CATEGORY_COMPONENTS_FAIL,
    payload: error,
  };
};

export const createCategoryComponents = (object, token) => {
  return (dispatch) => {
    dispatch(getCategoryComponentsStart());
    axios
      .post(`/training-category-contents/create`, object, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOneCategoryComponentsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getCategoryComponentsFail(err));
      });
  };
};

export const getCategoryComponents = (token, id) => {
  return (dispatch) => {
    dispatch(getCategoryComponentsStart());
    axios
      .get(`/training-category-contents/findby-component/${id}`, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getCategoryComponentsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getCategoryComponentsFail(err));
      });
  };
};

export const getOneCategoryComponents = (token, id) => {
  return (dispatch) => {
    dispatch(getCategoryComponentsStart());
    axios
      .get(`/training-category-contents/find/${id}`, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOneCategoryComponentsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getCategoryComponentsFail(err));
      });
  };
};

export const activatingCategoryComponents = (token, id) => {
  return (dispatch) => {
    dispatch(getCategoryComponentsStart());
    axios
      .put(`/training-category-contents/activating/${id}`, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOneCategoryComponentsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getCategoryComponentsFail(err));
      });
  };
};

export const desActivatingCategoryComponents = (token, id) => {
  return (dispatch) => {
    dispatch(getCategoryComponentsStart());
    axios
      .put(`/training-category-contents/desactivating/${id}`, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOneCategoryComponentsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getCategoryComponentsFail(err));
      });
  };
};

export const updateCategoryComponents = (token, object) => {
  return (dispatch) => {
    dispatch(getCategoryComponentsStart());
    axios
      .put(`/training-category-contents/edit/${object?.id}`, object, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOneCategoryComponentsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getCategoryComponentsFail(err));
      });
  };
};
