import { updateObject } from "../../utils/utility";
import {
  GET_SMS_START,
  GET_SMS_SUCCESS,
  GET_ONE_SMS_SUCCESS,
  GET_SMS_FAIL,
} from "../actions/sms";

const initialReducer = {
  sms: null,
  smss: [],
  smssLoading: false,
  smssFail: null,
};

const getSMSsStart = (state, action) => {
  return updateObject(state, {
    smss: [],
    sms: null,
    smssLoading: true,
    smssFail: null,
  });
};

const getSMSsSuccess = (state, action) => {
  return updateObject(state, {
    smss: action.payload,
    smssLoading: false,
    smssFail: null,
    sms: null,
  });
};

const getOneSMSsSuccess = (state, action) => {
  return updateObject(state, {
    sms: action.payload,
    smss: [],
    smssLoading: false,
    smssFail: null,
  });
};

const getSMSsFail = (state, action) => {
  return updateObject(state, {
    smss: [],
    sms: null,
    smssLoading: false,
    smssFail: action.payload,
  });
};

const reducer = (state = initialReducer, action) => {
    switch (action.type) {
      case GET_SMS_START:
        return getSMSsStart(state, action);
      case GET_SMS_SUCCESS:
        return getSMSsSuccess(state, action);
      case GET_ONE_SMS_SUCCESS:
        return getOneSMSsSuccess(state, action);
      case GET_SMS_FAIL:
        return getSMSsFail(state, action);
      default:
        return state;
    }
  };
  
  export default reducer;
  
