import { updateObject } from "../../utils/utility";
import {
  GET_SERVICES_START,
  GET_SERVICES_SUCCESS,
  GET_ONE_SERVICES_SUCCESS,
  GET_SERVICES_FAIL,
  GET_TEXT_TESTIMONIES_START,
  GET_TEXT_TESTIMONIES_SUCCESS,
  GET_ONE_TEXT_TESTIMONIES_SUCCESS,
  GET_TEXT_TESTIMONIES_FAIL,
  GET_VIDEO_TESTIMONIES_START,
  GET_VIDEO_TESTIMONIES_SUCCESS,
  GET_ONE_VIDEO_TESTIMONIES_SUCCESS,
  GET_VIDEO_TESTIMONIES_FAIL,
  GET_SERVICE_REQUIREMENTS_START,
  GET_SERVICE_REQUIREMENTS_SUCCESS,
  GET_ONE_SERVICE_REQUIREMENTS_SUCCESS,
  GET_SERVICE_REQUIREMENTS_FAIL,
  GET_REQUIREMENT_CRITERIA_START,
  GET_REQUIREMENT_CRITERIA_SUCCESS,
  GET_ONE_REQUIREMENT_CRITERIA_SUCCESS,
  GET_REQUIREMENT_CRITERIA_FAIL,
  GET_CRITERIA_VALUES_START,
  GET_CRITERIA_VALUES_SUCCESS,
  GET_ONE_CRITERIA_VALUES_SUCCESS,
  GET_CRITERIA_VALUES_FAIL,
} from "../actions/services";

const initialReducer = {
  service: null,
  services: [],
  servicesLoading: false,
  servicesFail: null,
  texttestimonie: null,
  texttestimonies: [],
  texttestimoniesLoading: false,
  texttestimoniesFail: null,
  videoTestimonie: null,
  videoTestimonies: [],
  videoTestimoniesLoading: false,
  videoTestimoniesFail: null,
  requirement: null,
  requirements: [],
  requirementsLoading: false,
  requirementsFail: null,
  requirementCriteria: null,
  requirementCriterias: [],
  requirementCriteriasLoading: false,
  requirementCriteriasFail: null,
  criteriaValue: null,
  criteriaValues: [],
  criteriaValuesLoading: false,
  criteriaValuesFail: null,
};

const getServicesStart = (state, action) => {
  return updateObject(state, {
    services: [],
    service: null,
    servicesLoading: true,
    servicesFail: null,
  });
};

const getServicesSuccess = (state, action) => {
  return updateObject(state, {
    services: action.payload,
    servicesLoading: false,
    servicesFail: null,
    service: null,
  });
};

const getOneServicesSuccess = (state, action) => {
  return updateObject(state, {
    service: action.payload,
    services: [],
    servicesLoading: false,
    servicesFail: null,
  });
};

const getServicesFail = (state, action) => {
  return updateObject(state, {
    services: [],
    service: null,
    servicesLoading: false,
    servicesFail: action.payload,
  });
};

const getTextTestimoniesStart = (state, action) => {
  return updateObject(state, {
    texttestimonies: [],
    texttestimonie: null,
    texttestimoniesLoading: true,
    texttestimoniesFail: null,
  });
};

const getTextTestimoniesSuccess = (state, action) => {
  return updateObject(state, {
    texttestimonies: action.payload,
    texttestimoniesLoading: false,
    texttestimoniesFail: null,
    texttestimonie: null,
  });
};

const getOneTextTestimoniesSuccess = (state, action) => {
  return updateObject(state, {
    texttestimonie: action.payload,
    texttestimonies: [],
    texttestimoniesLoading: false,
    texttestimoniesFail: null,
  });
};

const getTextTestimoniesFail = (state, action) => {
  return updateObject(state, {
    texttestimonies: [],
    texttestimonie: null,
    texttestimoniesLoading: false,
    texttestimoniesFail: action.payload,
  });
};

const getVideoTestimoniesStart = (state, action) => {
  return updateObject(state, {
    videoTestimonies: [],
    videoTestimonie: null,
    videoTestimoniesLoading: true,
    videoTestimoniesFail: null,
  });
};

const getVideoTestimoniesSuccess = (state, action) => {
  return updateObject(state, {
    videoTestimonies: action.payload,
    videoTestimoniesLoading: false,
    videoTestimoniesFail: null,
    videoTestimonie: null,
  });
};

const getOneVideoTestimoniesSuccess = (state, action) => {
  return updateObject(state, {
    videoTestimonie: action.payload,
    videoTestimonies: [],
    videoTestimoniesLoading: false,
    videoTestimoniesFail: null,
  });
};

const getVideoTestimoniesFail = (state, action) => {
  return updateObject(state, {
    videoTestimonies: [],
    videoTestimonie: null,
    videoTestimoniesLoading: false,
    videoTestimoniesFail: action.payload,
  });
};

const getServiceRequirementsStart = (state, action) => {
  return updateObject(state, {
    requirements: [],
    requirement: null,
    requirementsLoading: true,
    requirementsFail: null,
  });
};

const getServiceRequirementsSuccess = (state, action) => {
  return updateObject(state, {
    requirements: action.payload,
    requirementsLoading: false,
    requirementsFail: null,
    requirement: null,
  });
};

const getOneServiceRequirementsSuccess = (state, action) => {
  return updateObject(state, {
    requirement: action.payload,
    requirements: [],
    requirementsLoading: false,
    requirementsFail: null,
  });
};

const getServiceRequirementsFail = (state, action) => {
  return updateObject(state, {
    requirements: [],
    requirement: null,
    requirementsLoading: false,
    requirementsFail: action.payload,
  });
};

const getRequirementsCriteriaStart = (state, action) => {
  return updateObject(state, {
    requirementCriterias: [],
    requirementCriteria: null,
    requirementCriteriasLoading: true,
    requirementCriteriasFail: null,
  });
};

const getRequirementsCriteriaSuccess = (state, action) => {
  return updateObject(state, {
    requirementCriterias: action.payload,
    requirementCriteriasLoading: false,
    requirementCriteriasFail: null,
    requirementCriteria: null,
  });
};

const getOneRequirementsCriteriaSuccess = (state, action) => {
  return updateObject(state, {
    requirementCriteria: action.payload,
    requirementCriterias: [],
    requirementCriteriasLoading: false,
    requirementCriteriasFail: null,
  });
};

const getRequirementsCriteriaFail = (state, action) => {
  return updateObject(state, {
    requirementCriterias: [],
    requirementCriteria: null,
    requirementCriteriasLoading: false,
    requirementCriteriasFail: action.payload,
  });
};

const getCriteriaValuesStart = (state, action) => {
  return updateObject(state, {
    criteriaValues: [],
    criteriaValue: null,
    criteriaValuesLoading: true,
    criteriaValuesFail: null,
  });
};

const getCriteriaValuesSuccess = (state, action) => {
  return updateObject(state, {
    criteriaValues: action.payload,
    criteriaValuesLoading: false,
    criteriaValuesFail: null,
    criteriaValue: null,
  });
};

const getOneCriteriaValuesSuccess = (state, action) => {
  return updateObject(state, {
    criteriaValue: action.payload,
    criteriaValues: [],
    criteriaValuesLoading: false,
    criteriaValuesFail: null,
  });
};

const getCriteriaValuesFail = (state, action) => {
  return updateObject(state, {
    criteriaValues: [],
    criteriaValue: null,
    criteriaValuesLoading: false,
    criteriaValuesFail: action.payload,
  });
};

const reducer = (state = initialReducer, action) => {
  switch (action.type) {
    case GET_SERVICES_START:
      return getServicesStart(state, action);
    case GET_SERVICES_SUCCESS:
      return getServicesSuccess(state, action);
    case GET_ONE_SERVICES_SUCCESS:
      return getOneServicesSuccess(state, action);
    case GET_SERVICES_FAIL:
      return getServicesFail(state, action);
    case GET_TEXT_TESTIMONIES_START:
      return getTextTestimoniesStart(state, action);
    case GET_TEXT_TESTIMONIES_SUCCESS:
      return getTextTestimoniesSuccess(state, action);
    case GET_ONE_TEXT_TESTIMONIES_SUCCESS:
      return getOneTextTestimoniesSuccess(state, action);
    case GET_TEXT_TESTIMONIES_FAIL:
      return getTextTestimoniesFail(state, action);
    case GET_VIDEO_TESTIMONIES_START:
      return getVideoTestimoniesStart(state, action);
    case GET_VIDEO_TESTIMONIES_SUCCESS:
      return getVideoTestimoniesSuccess(state, action);
    case GET_ONE_VIDEO_TESTIMONIES_SUCCESS:
      return getOneVideoTestimoniesSuccess(state, action);
    case GET_VIDEO_TESTIMONIES_FAIL:
      return getVideoTestimoniesFail(state, action);
    case GET_SERVICE_REQUIREMENTS_START:
      return getServiceRequirementsStart(state, action);
    case GET_SERVICE_REQUIREMENTS_SUCCESS:
      return getServiceRequirementsSuccess(state, action);
    case GET_ONE_SERVICE_REQUIREMENTS_SUCCESS:
      return getOneServiceRequirementsSuccess(state, action);
    case GET_SERVICE_REQUIREMENTS_FAIL:
      return getServiceRequirementsFail(state, action);
    case GET_REQUIREMENT_CRITERIA_START:
      return getRequirementsCriteriaStart(state, action);
    case GET_REQUIREMENT_CRITERIA_SUCCESS:
      return getRequirementsCriteriaSuccess(state, action);
    case GET_ONE_REQUIREMENT_CRITERIA_SUCCESS:
      return getOneRequirementsCriteriaSuccess(state, action);
    case GET_REQUIREMENT_CRITERIA_FAIL:
      return getRequirementsCriteriaFail(state, action);
    case GET_CRITERIA_VALUES_START:
      return getCriteriaValuesStart(state, action);
    case GET_CRITERIA_VALUES_SUCCESS:
      return getCriteriaValuesSuccess(state, action);
    case GET_ONE_CRITERIA_VALUES_SUCCESS:
      return getOneCriteriaValuesSuccess(state, action);
    case GET_CRITERIA_VALUES_FAIL:
      return getCriteriaValuesFail(state, action);
    default:
      return state;
  }
};

export default reducer;
