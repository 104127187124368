import classnames from "classnames";
import { NavLink } from "react-router-dom";
import constants from "../../components/constants";

export const ObjectDetails = function (props) {
  return (
    <div>
      <span className="bdf-bluefontcolor">{props?.title}:</span> {props?.value}
    </div>
  );
};

export const ImageCard = function (props) {
  return (
    <div>
      <img
        className="w-100"
        src={`${process.env.REACT_APP_BACKEND_URL}/${props?.src}`}
      />
    </div>
  );
};
