import { updateObject } from "../../utils/utility";
import {
  GET_LANGUAGES_START,
  GET_LANGUAGES_SUCCESS,
  GET_ONE_LANGUAGES_SUCCESS,
  GET_LANGUAGES_FAIL,
  GET_APPLICATION_LINKS_START,
  GET_APPLICATION_LINKS_SUCCESS,
  GET_ONE_APPLICATION_LINKS_SUCCESS,
  GET_APPLICATION_LINKS_FAIL,
} from "../actions/languages";

const initialReducer = {
  language: null,
  languages: [],
  languagesLoading: false,
  languagesFail: null,
  applicationLink: null,
  applicationLinks: [],
  applicationLinksLoading: false,
  applicationLinksFail: null,
};

const getLanguagesStart = (state, action) => {
  return updateObject(state, {
    languages: [],
    language: null,
    languagesLoading: true,
    languagesFail: null,
  });
};

const getLanguagesSuccess = (state, action) => {
  return updateObject(state, {
    languages: action.payload,
    languagesLoading: false,
    languagesFail: null,
    language: null,
  });
};

const getOneLanguagesSuccess = (state, action) => {
  return updateObject(state, {
    language: action.payload,
    languages: [],
    languagesLoading: false,
    languagesFail: null,
  });
};

const getLanguagesFail = (state, action) => {
  return updateObject(state, {
    languages: [],
    language: null,
    languagesLoading: false,
    languagesFail: action.payload,
  });
};

const getApplicationLinksStart = (state, action) => {
  return updateObject(state, {
    applicationLinks: [],
    applicationLink: null,
    applicationLinksLoading: true,
    applicationLinksFail: null,
  });
};

const getApplicationLinksSuccess = (state, action) => {
  return updateObject(state, {
    applicationLinks: action.payload,
    applicationLinksLoading: false,
    applicationLinksFail: null,
    applicationLink: null,
  });
};

const getOneApplicationLinksSuccess = (state, action) => {
  return updateObject(state, {
    applicationLink: action.payload,
    applicationLinks: [],
    applicationLinksLoading: false,
    applicationLinksFail: null,
  });
};

const getApplicationLinksFail = (state, action) => {
  return updateObject(state, {
    applicationLinks: [],
    applicationLink: null,
    applicationLinksLoading: false,
    applicationLinksFail: action.payload,
  });
};

const reducer = (state = initialReducer, action) => {
  switch (action.type) {
    case GET_LANGUAGES_START:
      return getLanguagesStart(state, action);
    case GET_LANGUAGES_SUCCESS:
      return getLanguagesSuccess(state, action);
    case GET_ONE_LANGUAGES_SUCCESS:
      return getOneLanguagesSuccess(state, action);
    case GET_LANGUAGES_FAIL:
      return getLanguagesFail(state, action);
    case GET_APPLICATION_LINKS_START:
      return getApplicationLinksStart(state, action);
    case GET_APPLICATION_LINKS_SUCCESS:
      return getApplicationLinksSuccess(state, action);
    case GET_ONE_APPLICATION_LINKS_SUCCESS:
      return getOneApplicationLinksSuccess(state, action);
    case GET_APPLICATION_LINKS_FAIL:
      return getApplicationLinksFail(state, action);
    default:
      return state;
  }
};

export default reducer;
