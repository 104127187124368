import { useTranslation } from "react-i18next";
import { ScrollToTop } from "../components/backtotop";
import { Footer } from "../components/footer";
import Header from "../header";
import { TestimonialsComponent } from "../services/service";
import {
  TrainingComponent,
  TrainingTestimonialsComponent,
} from "./trainingComponent";
import sacco from "../images/sacco.png";
import { useEffect } from "react";
import mySwiper from "../services/swiper";
import bdflogoimg from "../images/bdflogbg.PNG";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../store/actions/";
import { CollapseDemo } from "./collapse";

export const Training = function (props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const language = useSelector((state) => state.homepage.language);
  const trainingIntro = useSelector((state) => state.trainings.trainingIntro);
  const trainingCategories = useSelector((state) => state.trainings.categories);
  useEffect(() => {
    mySwiper();
    dispatch(actions.getActiveTrainingIntros(language));
    dispatch(actions.getActiveTrainingCategories(language));
    dispatch(actions.getActiveVideoTestimonies());
  }, [language]);
  return (
    <body className="page-about">
      <Header />
      <main id="main">
        <div className="breadcrumbs d-flex align-items-center about-header-bg">
          <div className="container position-relative d-flex flex-column align-items-center">
            <h2>{t("Training")}</h2>
          </div>
        </div>
        <section id="about" className="about">
          <div className="container" data-aos="fade-up">
            <div className="row gy-4" data-aos="fade-up">
              <div className="col-lg-4">
                <img
                  src={`${process.env.REACT_APP_BACKEND_URL}/${trainingIntro?.image}`}
                  className="img-fluid h-100"
                  alt=""
                />
              </div>
              <div className="col-lg-8">
                <div className="content ps-lg-3">
                  <h3>{trainingIntro?.title}</h3>
                  <p>{trainingIntro?.description}</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="services-list" className="services-list bg-light">
          <div className="container" data-aos="fade-up">
            <div className="section-header">
              <h2>{t("Training Categories")}</h2>
            </div>
            <div className="row gy-5">
              {trainingCategories?.map((category, index) => (
                <TrainingComponent category={category} key={index} />
              ))}
            </div>
          </div>
        </section>
        <TrainingTestimonialsComponent />
      </main>
      <Footer />
      <ScrollToTop />
    </body>
  );
};
