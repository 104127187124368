export const LanguagesTable = (props) => {
  return (
    <table>
      <tbody>
        {props.languages.map((lang, i) => (
          <tr key={i} className="text-md font-semibold">
            <td
              className={
                i <= props.counter
                  ? i == props.counter
                    ? " bg-warning text-primary px-2 py-1"
                    : "bg-success text-white px-2 py-1"
                  : "px-2 py-1"
              }
            >
              <div className="flex items-center">
                <span>
                  {i <= props.counter ? (
                    i == props.counter ? (
                      <i className="bi bi-keyboard-fill"></i>
                    ) : (
                      <i className="bi bi-check-circle-fill"></i>
                    )
                  ) : (
                    <i className="bi bi-skip-end-btn-fill"></i>
                  )}
                </span>
                <span className="font-weight-bold">{"    " + lang.name}</span>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
