import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { ScrollToTop } from "../components/backtotop";
import { Footer } from "../components/footer";
import Header from "../header";
import bgcard from "../images/bgcard.jpeg";
import { PublicationContentCard } from "./contentcard";
import * as actions from "../store/actions/";

export const PublicationTypeContent = function (props) {
  const params = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const publicationId = localStorage.getItem("publicationId");
  const contents = useSelector((state) => state.publications.contents);
  const publication = useSelector((state) => state.publications.publication);
  const language = useSelector((state) => state.homepage.language);
  const token = useSelector((state) => state.auth.token);
  let title = "Publications";
  if (publication?.type === "career") {
    title = "Careers";
  } else if (publication?.type === "tender") {
    title = "Tenders";
  } else if (publication?.type === "news") {
    title = "News";
  } else if (publication?.type === "report") {
    title = "Reports";
  }
  const [publicationsList, setpublicationsList] = useState([]);
  //let publicationsList = [];
  const publications = [
    {
      type: "career",
      title: "Amanota",
      image: bgcard,
      description:
        "Amanota yabakoze k'umwanya wa IT business analyst na software developer",
      fileName: "Final-marks.pdf",
      publishedDate: "06-20-2022",
    },
    // {
    //   type: "career",
    //   title: "Business Analyst",
    //   image: bgcard,
    //   description:
    //     "We are looking for a compentent Software developer with skills in OOP, Java and React js",
    //   fileName: "https://google.com",
    //   publishedDate: new Date(),
    //   deadline: new Date(),
    // },
    // {
    //   type: "career",
    //   title: "Branch Manager",
    //   image: bgcard,
    //   description:
    //     "We are looking for a compentent Software developer with skills in OOP, Java and React js",
    //   fileName: "https://google.com",
    //   publishedDate: new Date(),
    //   deadline: new Date(),
    // },
    {
      type: "tender",
      title: "Expert for PCG",
      image: bgcard,
      description: "We are looking for a Partial Credit Guarantee - PCG",
      fileName: "PcgAdvrt.pdf",
      publishedDate: new Date(),
    },
    {
      type: "tender",
      title: "Isoko ku gutanga Ibikoresho by'ikoranabuhanga",
      image: bgcard,
      description:
        "BDF iratanga isoko ryo kugura ibikoresho by'ikoranabuhanga mu myaka itatu",
      fileName: "https://google.com",
      publishedDate: new Date(),
    },
    {
      type: "news",
      title: "The Fund Newsletter, edition 1",
      image: bgcard,
      fileName: "https://google.com",
      publishedDate: new Date(),
    },
    {
      type: "news",
      title: "Economic Recovery Fund Completion Report",
      image: bgcard,
      fileName: "https://google.com",
      publishedDate: new Date(),
    },
    {
      type: "report",
      title: "Raporo y'umwaka 2020",
      image: bgcard,
      fileName: "https://google.com",
      publishedDate: new Date(),
    },
    {
      type: "report",
      title: "Raporo y'umwaka 2019",
      image: bgcard,
      fileName: "https://google.com",
      publishedDate: new Date(),
    },
    {
      type: "other",
      title: "Environmental & Social Safeguards",
      image: bgcard,
      // description:
      //   "We are looking for a compentent Software developer with skills in OOP, Java and React js",
      fileName: "https://google.com",
      publishedDate: new Date(),
    },
  ];
  useEffect(() => {
    dispatch(actions.getActivePublicationContents(params?.objectId));
    dispatch(actions.getOnePublications(token, params?.objectId));
  }, []);
  return (
    <body className="page-about">
      <Header />
      <main id="main">
        <div className="breadcrumbs d-flex align-items-center about-header-bg">
          <div className="container position-relative d-flex flex-column align-items-center">
            <h2>{t(title)}</h2>
          </div>
        </div>
        <section id="services-list" class="services-list">
          <div class="container" data-aos="fade-up">
            <div class="row gy-5">
              {contents?.map((content, index) => (
                <PublicationContentCard key={index} publication={content} />
              ))}
            </div>
          </div>
        </section>
      </main>
      <Footer />
      <ScrollToTop />
    </body>
  );
};
