import { useTranslation } from "react-i18next";
import { NavLink, useHistory } from "react-router-dom";
import bgcard from "../images/bgcard.jpeg";

export const PublicationCard = function (props) {
  const { t } = useTranslation();
  const publication = props?.publication;
  const history = useHistory();

  const publicationContent = () => {
    localStorage.setItem("publicationId", publication?.uuid);
    history.push("/publication-type/" + publication?.uuid);
  };

  return (
    <div class="col-lg-6" data-aos="fade-up" data-aos-delay="400">
      <div class="card-item">
        <div class="row h-100">
          <div class="col-xl-5 col-md-4">
            <img
              className="h-100 w-100"
              src={`${process.env.REACT_APP_BACKEND_URL}/${publication?.image}`}
              alt="bgcard"
            ></img>
          </div>
          <div class="col-xl-7 col-md-8 d-flex align-items-center">
            <div class="card-body">
              <h4 class="card-title">{publication?.title}</h4>
              <p>{publication?.description}</p>
              <button
                className="pt-1 bdf-bluefontcolor remove-border"
                onClick={(e) => {
                  e.preventDefault();
                  publicationContent();
                }}
              >
                {t("View Content")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
