import { useTranslation } from "react-i18next";
import minecofin from "../images/partners/minecofin.png";
import minicom from "../images/partners/minicom.png";
import minagri from "../images/partners/minagri.png";
import migeprof from "../images/partners/migeprof.png";
import mifotra from "../images/partners/mifotra.png";
import minaloc from "../images/partners/minaloc.png";
import rdb from "../images/partners/rdb.png";
import bnr from "../images/partners/bnr.png";
import worldbank from "../images/partners/worldbank.png";
import uncdf from "../images/partners/uncdf.png";
import jlifad from "../images/partners/jlifad.png";
import rca from "../images/partners/rca.png";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../store/actions/";

// import dotenv from "dotenv";
// dotenv.config();

export const PartnerCard = (props) => {
  const { t } = useTranslation();
  return (
    <div
      class="col-lg-3 col-md-5 service-item d-flex text-center"
      data-aos="fade-up"
      data-aos-delay="100"
    >
      <img alt="partner" src={props?.logo}></img>
    </div>
  );
};

export const PartnersSection = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const partners = useSelector((state) => state.homepage.partners);
  // const partners = [
  //   { partner: "MINECOFIN", logo: minecofin },
  //   { partner: "MINICOM", logo: minicom },
  //   { partner: "MINAGRI", logo: minagri },
  //   { partner: "MIGEPROF", logo: migeprof },
  //   { partner: "MIFOTRA", logo: mifotra },
  //   { partner: "MINALOC", logo: minaloc },
  //   { partner: "RDB", logo: rdb },
  //   { partner: "BNR", logo: bnr },
  //   { partner: "WORLD BANK", logo: worldbank },
  //   { partner: "UNDCF", logo: uncdf },
  //   { partner: "JLIFAD", logo: jlifad },
  //   { partner: "RCA", logo: rca },
  // ];

  useEffect(() => {
    dispatch(actions.getActivePartners());
  }, []);
  return (
    <section id="services-list" className="services-list bg-light">
      <div className="container" data-aos="fade-up">
        <div className="section-header">
          <h2>{t("Partners")}</h2>
        </div>
        <div className="row gy-4">
          {partners?.map((partner, index) => (
            <PartnerCard
              key={index}
              logo={`${process.env.REACT_APP_BACKEND_URL}/${partner?.logo}`}
            />
          ))}
        </div>
      </div>
    </section>
  );
};
