import { useEffect, useState } from "react";
import {
  ApplicationLink,
  ApplicationLinkHeader,
  HeaderLink,
  HomeHeaderLink,
} from "./components/links";
import { useTranslation } from "react-i18next";
import i18n from "./utils/i18n";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "./store/actions/";

const Header = () => {
  const dispatch = useDispatch();
  const languages = useSelector((state) => state.languages.languages);
  const applicationLinks = useSelector(
    (state) => state.languages.applicationLinks
  );
  const [selectLng, setSelectLng] = useState(
    localStorage.getItem("i18nextLng")
  );
  const [showLanguages, setShowLanguages] = useState(false);
  const { t } = useTranslation();
  const handleLang = (value) => {
    i18n.changeLanguage(value);
    setSelectLng(value);
    setShowLanguages(!showLanguages);
  };

  const mobileNavToogle = (e) => {
    e.preventDefault();
    const mobileNavShow = document.querySelector(".mobile-nav-show");
    const mobileNavHide = document.querySelector(".mobile-nav-hide");
    const languageHide = document.querySelector(".language-div");
    document.querySelector("body").classList.toggle("mobile-nav-active");
    mobileNavShow.classList.toggle("d-none");
    mobileNavHide.classList.toggle("d-none");
    languageHide.classList.toggle("d-none");
  };

  useEffect(() => {
    dispatch(actions.getActiveLanguages());
    dispatch(actions.getActiveApplicationLinks());
  }, [dispatch]);

  useEffect(() => {
    dispatch(actions.changeLanguage(selectLng));
    const selectHeader = document.querySelector("#header");
    if (selectHeader) {
      document.addEventListener("scroll", () => {
        window.scrollY > 100
          ? selectHeader.classList.add("sticked")
          : selectHeader.classList.remove("sticked");
      });
    }
    document.querySelectorAll(".mobile-nav-toggle").forEach((el) => {
      el.addEventListener("click", function (event) {
        event.preventDefault();
        mobileNavToogle();
      });
    });
    const navDropdowns = document.querySelectorAll(".navbar .dropdown > a");
    navDropdowns.forEach((el) => {
      el.addEventListener("click", function (event) {
        if (document.querySelector(".mobile-nav-active")) {
          event.preventDefault();
          this.classList.toggle("active");
          this.nextElementSibling.classList.toggle("dropdown-active");

          let dropDownIndicator = this.querySelector(".dropdown-indicator");
          dropDownIndicator.classList.toggle("bi-chevron-up");
          dropDownIndicator.classList.toggle("bi-chevron-down");
        }
      });
    });
  }, [selectLng]);
  return (
    <header id="header" className="header d-flex align-items-center fixed-top">
      <div className="container-fluid container-xl d-flex align-items-center justify-content-between">
        <HomeHeaderLink />
        <div className={showLanguages ? "displ-lang" : "d-none"}>
          {languages?.map((language, index) => (
            <div
              onClick={() => {
                handleLang(language.abbreviation);
                setShowLanguages(!showLanguages);
              }}
              key={index}
            >
              {language?.name}
            </div>
          ))}
        </div>
        <div className="mobile-nav-show">
          <span
            onClick={(e) => {
              e.preventDefault();
              setShowLanguages(!showLanguages);
            }}
          >
            <span>
              <i className="bi bi-globe language-flag text-xl"></i>
            </span>
          </span>
        </div>
        <i
          onClick={mobileNavToogle}
          className="mobile-nav-toggle mobile-nav-show bi bi-list"
        ></i>
        <i
          onClick={mobileNavToogle}
          className="mobile-nav-toggle mobile-nav-hide d-none bi bi-x"
        ></i>
        <nav id="navbar" className="navbar">
          <ul className="me-3">
            <li>
              <HeaderLink page="/" name={t("Home")} />
            </li>
            <li>
              <HeaderLink page="/about" name={t("About")} />
            </li>
            <li>
              <HeaderLink page="/services" name={t("Services")} />
            </li>
            <li>
              <HeaderLink page="/publications" name={t("Publications")} />
            </li>
            <li>
              <HeaderLink page="/training" name={t("Training")} />
            </li>
            <li className={applicationLinks?.length <= 0 ? "d-none" : "dropdown apply-btn pl-3"}>
              <li className="text-white front-semi-bold">{t("Apply")}</li>
              <ul>
                {applicationLinks?.map((link, index) => (
                  <ApplicationLinkHeader
                    key={index}
                    name={link?.name}
                    link={link?.link}
                  />
                ))}
              </ul>
            </li>
            <li>
              <HeaderLink page="/contactus" name={t("Contact")} />
            </li>
          </ul>
          <div className="language-div flex-column">
            <ul>
              <li className="dropdown">
                <a href="#" className="">
                  <span>
                    <i className="bi bi-globe language-flag w-100"></i>
                  </span>
                </a>
                <ul>
                  {languages.map((language, index) => (
                    <li
                      onClick={() => handleLang(language.abbreviation)}
                      key={index}
                    >
                      <a href="#">{language?.name}</a>
                    </li>
                  ))}
                </ul>
              </li>
            </ul>
            <div
              onClick={(e) => {
                e.preventDefault();
                setShowLanguages(!showLanguages);
              }}
            ></div>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Header;
