import { updateObject } from "../../utils/utility";
import {
  CHANGE_LANGUAGE,
  GET_HOME_START,
  GET_HOME_SUCCESS,
  GET_ONE_HOME_SUCCESS,
  GET_HOME_FAIL,
  GET_HOME_ACHIEVEMENTS_START,
  GET_HOME_ACHIEVEMENTS_SUCCESS,
  GET_ONE_HOME_ACHIEVEMENTS_SUCCESS,
  GET_HOME_ACHIEVEMENTS_FAIL,
  GET_HOME_ACHIEVEMENT_TITLES_START,
  GET_HOME_ACHIEVEMENT_TITLES_SUCCESS,
  GET_ONE_HOME_ACHIEVEMENT_TITLES_SUCCESS,
  GET_HOME_ACHIEVEMENT_TITLES_FAIL,
  GET_HOME_REQUEST_SERVICES_START,
  GET_HOME_REQUEST_SERVICES_SUCCESS,
  GET_ONE_HOME_REQUEST_SERVICES_SUCCESS,
  GET_HOME_REQUEST_SERVICES_FAIL,
  GET_PARTNERS_START,
  GET_PARTNERS_SUCCESS,
  GET_ONE_PARTNERS_SUCCESS,
  GET_PARTNERS_FAIL,
} from "../actions/homepage";

const initialReducer = {
  language: "rw",
  home: null,
  homes: [],
  homesLoading: false,
  homesFail: null,
  achievement: null,
  achievements: [],
  achievementsLoading: false,
  achievementsFail: null,
  achievementTitle: null,
  achievementTitles: [],
  achievementTitlesLoading: false,
  achievementTitlesFail: null,
  requestService: null,
  requestServices: [],
  requestServicesLoading: false,
  requestServicesFail: null,
  partner: null,
  partners: [],
  partnersLoading: false,
  partnersFail: null,
};

const changeLanguage = (state, action) => {
  return updateObject(state, {
    language: action.language,
  });
};

const getHomesStart = (state, action) => {
  return updateObject(state, {
    homes: [],
    home: null,
    homesLoading: true,
    homesFail: null,
  });
};

const getHomesSuccess = (state, action) => {
  return updateObject(state, {
    homes: action.payload,
    homesLoading: false,
    homesFail: null,
    home: null,
  });
};

const getOneHomesSuccess = (state, action) => {
  return updateObject(state, {
    home: action.payload,
    homes: [],
    homesLoading: false,
    homesFail: null,
  });
};

const getHomesFail = (state, action) => {
  return updateObject(state, {
    homes: [],
    home: null,
    homesLoading: false,
    homesFail: action.payload,
  });
};

const getHomeAchievementsStart = (state, action) => {
  return updateObject(state, {
    achievements: [],
    achievement: null,
    achievementsLoading: true,
    achievementsFail: null,
  });
};

const getHomeAchievementsSuccess = (state, action) => {
  return updateObject(state, {
    achievements: action.payload,
    achievementsLoading: false,
    achievementsFail: null,
    achievement: null,
  });
};

const getOneHomeAchievementsSuccess = (state, action) => {
  return updateObject(state, {
    achievement: action.payload,
    achievements: [],
    achievementsLoading: false,
    achievementsFail: null,
  });
};

const getHomeAchievementsFail = (state, action) => {
  return updateObject(state, {
    achievements: [],
    achievement: null,
    achievementsLoading: false,
    achievementsFail: action.payload,
  });
};

const getHomeAchievementTitlesStart = (state, action) => {
  return updateObject(state, {
    achievementTitles: [],
    achievementTitle: null,
    achievementTitlesLoading: true,
    achievementTitlesFail: null,
  });
};

const getHomeAchievementTitlesSuccess = (state, action) => {
  return updateObject(state, {
    achievementTitles: action.payload,
    achievementTitlesLoading: false,
    achievementTitlesFail: null,
    achievementTitle: null,
  });
};

const getOneHomeAchievementTitlesSuccess = (state, action) => {
  return updateObject(state, {
    achievementTitle: action.payload,
    achievementTitles: [],
    achievementTitlesLoading: false,
    achievementTitlesFail: null,
  });
};

const getHomeAchievementTitlesFail = (state, action) => {
  return updateObject(state, {
    achievementTitles: [],
    achievementTitle: null,
    achievementTitlesLoading: false,
    achievementTitlesFail: action.payload,
  });
};

const getHomeRequestServicesStart = (state, action) => {
  return updateObject(state, {
    requestServices: [],
    requestService: null,
    requestServicesLoading: true,
    requestServicesFail: null,
  });
};

const getHomeRequestServicesSuccess = (state, action) => {
  return updateObject(state, {
    requestServices: action.payload,
    requestServicesLoading: false,
    requestServicesFail: null,
    requestService: null,
  });
};

const getOneHomeRequestServicesSuccess = (state, action) => {
  return updateObject(state, {
    requestService: action.payload,
    requestServices: [],
    requestServicesLoading: false,
    requestServicesFail: null,
  });
};

const getHomeRequestServicesFail = (state, action) => {
  return updateObject(state, {
    requestServices: [],
    requestService: null,
    requestServicesLoading: false,
    requestServicesFail: action.payload,
  });
};

const getPartnersStart = (state, action) => {
  return updateObject(state, {
    partners: [],
    partner: null,
    partnersLoading: true,
    partnersFail: null,
  });
};

const getPartnersSuccess = (state, action) => {
  return updateObject(state, {
    partners: action.payload,
    partnersLoading: false,
    partnersFail: null,
    partner: null,
  });
};

const getOnePartnersSuccess = (state, action) => {
  return updateObject(state, {
    partner: action.payload,
    partners: [],
    partnersLoading: false,
    partnersFail: null,
  });
};

const getPartnersFail = (state, action) => {
  return updateObject(state, {
    partners: [],
    partner: null,
    partnersLoading: false,
    partnersFail: action.payload,
  });
};

const reducer = (state = initialReducer, action) => {
  switch (action.type) {
    case CHANGE_LANGUAGE:
      return changeLanguage(state, action);
    case GET_HOME_START:
      return getHomesStart(state, action);
    case GET_HOME_SUCCESS:
      return getHomesSuccess(state, action);
    case GET_ONE_HOME_SUCCESS:
      return getOneHomesSuccess(state, action);
    case GET_HOME_FAIL:
      return getHomesFail(state, action);
    case GET_HOME_ACHIEVEMENTS_START:
      return getHomeAchievementsStart(state, action);
    case GET_HOME_ACHIEVEMENTS_SUCCESS:
      return getHomeAchievementsSuccess(state, action);
    case GET_ONE_HOME_ACHIEVEMENTS_SUCCESS:
      return getOneHomeAchievementsSuccess(state, action);
    case GET_HOME_ACHIEVEMENTS_FAIL:
      return getHomeAchievementsFail(state, action);
    case GET_HOME_ACHIEVEMENT_TITLES_START:
      return getHomeAchievementTitlesStart(state, action);
    case GET_HOME_ACHIEVEMENT_TITLES_SUCCESS:
      return getHomeAchievementTitlesSuccess(state, action);
    case GET_ONE_HOME_ACHIEVEMENT_TITLES_SUCCESS:
      return getOneHomeAchievementTitlesSuccess(state, action);
    case GET_HOME_ACHIEVEMENT_TITLES_FAIL:
      return getHomeAchievementTitlesFail(state, action);
    case GET_HOME_REQUEST_SERVICES_START:
      return getHomeRequestServicesStart(state, action);
    case GET_HOME_REQUEST_SERVICES_SUCCESS:
      return getHomeRequestServicesSuccess(state, action);
    case GET_ONE_HOME_REQUEST_SERVICES_SUCCESS:
      return getOneHomeRequestServicesSuccess(state, action);
    case GET_HOME_REQUEST_SERVICES_FAIL:
      return getHomeRequestServicesFail(state, action);
    case GET_PARTNERS_START:
      return getPartnersStart(state, action);
    case GET_PARTNERS_SUCCESS:
      return getPartnersSuccess(state, action);
    case GET_ONE_PARTNERS_SUCCESS:
      return getOnePartnersSuccess(state, action);
    case GET_PARTNERS_FAIL:
      return getPartnersFail(state, action);
    default:
      return state;
  }
};

export default reducer;
