import { useTranslation } from "react-i18next";
import * as actions from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { ScrollToTop } from "../../components/backtotop";
import Header from "../components/header";
import Modal from "react-bootstrap/Modal";
import {
  ActivateButton,
  InputText,
  InputText2,
  InputTextArea,
  InputTextSecret,
  PageLink,
  SelectRadioButton,
  SubmitButton,
  UplodadFile,
} from "../components/inputs";
import { LanguagesTable } from "../components/languagestable";
import {
  successCreationNotification,
  successNotification,
  successUpdateNotification,
} from "../../components/notifications";
import { ReactNotifications } from "react-notifications-component";
import classnames from "classnames";
import { useHistory, useParams } from "react-router-dom";
import MiSpinner from "../../components/spinner";
import constants from "../../components/constants";
import { getLanguages } from "../../store/actions";
import { set } from "lodash";

export const AdminBDFBackground = function (props) {
  const WORKSPACE = "BDF Background";
  const history = useHistory();
  const params = useParams();
  const [counter, setcounter] = useState(0);
  const dispatch = useDispatch();
  const [clicked, setclicked] = useState(false);
  const [btndisabled, setbtndisabled] = useState(false);
  const object = useSelector((state) => state.about.background);
  const loading = useSelector((state) => state.about.backgroundsLoading);
  useEffect(() => {
    dispatch(getLanguages());
    if (params?.objectId && object === null) {
      dispatch(actions.getOneBackgrounds(token, params?.objectId));
    }
    if (object !== null) {
      settitle(object?.title);
      setdescription(object?.description);
    }
  }, [object]);
  const [title, settitle] = useState("");
  const [description, setdescription] = useState("");
  const [image, setimage] = useState(null);
  const languages = useSelector((state) => state.languages.languages);
  const userNames = useSelector((state) => state.auth.displayName);
  const token = useSelector((state) => state.auth.token);
  const [objects, setObjects] = useState([]);

  const fileSelectorHandler = (event) => {
    setimage(event.target.files[0]);
  };

  const activating = (e) => {
    e.preventDefault();
    if (window.confirm("Are you sure you want change the status?")) {
      if (object?.status === constants.active) {
        dispatch(actions.desActivatingBackgrounds(token, object?.uuid));
        successUpdateNotification(WORKSPACE, "Inactive");
      } else {
        dispatch(actions.activatingBackgrounds(token, object?.uuid));
        successUpdateNotification(WORKSPACE, "Active");
      }
    } else {
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (title === "" || description === "") {
      setclicked(true);
    } else {
      if (object != null) {
        if (window.confirm("Are you sure you want update?")) {
          const obj = {
            title,
            description,
            id: object.uuid,
            editedBy: userNames,
          };
          dispatch(actions.updateBackgrounds(token, obj));
          settitle("");
          setdescription("");
          setclicked(false);
          successUpdateNotification(WORKSPACE, "Updated");
        } else {
        }
      } else {
        if (counter < languages.length) {
          const lang = languages[counter]
            ? languages[counter].abbreviation
            : "";
          const languageName = languages[counter]
            ? languages[counter].name
            : "";
          const subs = setObjects([
            ...objects,
            {
              title,
              description,
              creator: userNames,
              language: lang,
            },
          ]);
          setcounter(counter + 1);
          successNotification(WORKSPACE, languageName);
          settitle("");
          setdescription("");
          setclicked(false);
        }
        if (counter == languages.length - 1) {
          settitle(title);
          setdescription(description);
          setbtndisabled(true);
        }
        if (counter == languages.length) {
          if (window.confirm("Are you sure you want submit?")) {
            const fd = new FormData();
            fd.append("image", image);
            fd.append("objects", JSON.stringify(objects));
            dispatch(actions.createBackgrounds(fd, token));
            setcounter(0);
            setObjects([]);
            settitle("");
            setdescription("");
            setclicked(false);
            setbtndisabled(false);
            successCreationNotification(WORKSPACE);
          } else {
          }
        }
      }
    }
  };

  return (
    <div className="">
      <Header />
      <ReactNotifications />
      <main id="main">
        <div className="breadcrumbs-modified d-flex align-items-center"></div>
        {loading ? (
          <MiSpinner />
        ) : (
          <section id="contact" className="input-data">
            <div className="container" data-aos="fade-up">
              <div className="row d-flex justify-content-end php-email-form">
                <div className="col-md-8">
                  <div className="">
                    <div className="">
                      {object !== null ? (
                        <>
                          <h5 className="bdf-bluefontcolor">
                            EDIT {WORKSPACE} ({object?.language})
                          </h5>
                        </>
                      ) : (
                        <h5 className="bdf-bluefontcolor">
                          CREATE {WORKSPACE} IN: {languages[counter]?.name}
                        </h5>
                      )}
                    </div>
                    <div className="form-group py-3">
                      <InputText2
                        title={"Title"}
                        value={title}
                        name="title"
                        placeholder="Title"
                        isClicked={clicked}
                        onChange={(e) => settitle(e.target.value)}
                      />
                    </div>
                    <div className="form-group py-3">
                      <InputTextArea
                        isClicked={clicked}
                        title={"Description"}
                        placeholder="Description"
                        value={description}
                        name="description"
                        onChange={(e) => setdescription(e.target.value)}
                      />
                    </div>
                    <div className="form-group py-3">
                      <UplodadFile
                        title="Image"
                        updating={
                          object != null ? constants.isTrue : constants.isFalse
                        }
                        name="image"
                        required
                        onChange={fileSelectorHandler}
                      />
                    </div>
                    <div className="row gx-5"></div>
                    <SubmitButton
                      disabled={btndisabled}
                      action={object === null ? "Create" : "Update"}
                      onSubmit={onSubmit}
                    />
                    <span className="px-2"></span>
                    <SubmitButton
                      disabled={!btndisabled}
                      classes={object === null ? "bg-success" : "d-none"}
                      action={"Submit"}
                      onSubmit={onSubmit}
                    />
                    <span className="px-5"></span>
                    <ActivateButton
                      status={object?.status}
                      onSubmit={activating}
                    />
                  </div>
                </div>
                <div className="col-md-3 d-flex gy-5 ml-5 justify-content-end">
                  <div>
                    <div className="flex flex-column h-50">
                      <PageLink
                        page={"/admin/about/bdf-background/objects/list"}
                        title={"List"}
                      />
                    </div>
                    <div>
                      <LanguagesTable languages={languages} counter={counter} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
      </main>
      <ScrollToTop />
    </div>
  );
};

export const AdminExecutive = function (props) {
  const WORKSPACE = "BDF Executives";
  const history = useHistory();
  const params = useParams();
  const [counter, setcounter] = useState(0);
  const dispatch = useDispatch();
  const [clicked, setclicked] = useState(false);
  const [btndisabled, setbtndisabled] = useState(false);
  const object = useSelector((state) => state.about.smExecutive);
  const loading = useSelector((state) => state.about.smExecutivesLoading);
  useEffect(() => {
    dispatch(getLanguages());
    if (params?.objectId && object === null) {
      dispatch(actions.getOneSMExecutives(token, params?.objectId));
    }
    if (object !== null) {
      settitle(object?.title);
      setprofile(object?.profile);
      settype(object?.type);
      setlevel(object?.level);
      setname(object?.name);
    }
  }, [object]);
  const [name, setname] = useState("");
  const [title, settitle] = useState("");
  const [profile, setprofile] = useState("");
  const [type, settype] = useState("");
  const [level, setlevel] = useState("");
  const [image, setimage] = useState(null);
  const languages = useSelector((state) => state.languages.languages);
  const userNames = useSelector((state) => state.auth.displayName);
  const token = useSelector((state) => state.auth.token);
  const [objects, setObjects] = useState([]);

  const fileSelectorHandler = (event) => {
    setimage(event.target.files[0]);
  };

  const activating = (e) => {
    e.preventDefault();
    if (window.confirm("Are you sure you want change the status?")) {
      if (object?.status === constants.active) {
        dispatch(actions.desActivatingSMExecutives(token, object?.uuid));
        successUpdateNotification(WORKSPACE, "Inactive");
      } else {
        dispatch(actions.activatingSMExecutives(token, object?.uuid));
        successUpdateNotification(WORKSPACE, "Active");
      }
    } else {
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (title === "" || profile === "") {
      setclicked(true);
    } else {
      if (object != null) {
        if (window.confirm("Are you sure you want update?")) {
          const obj = {
            title,
            profile,
            id: object.uuid,
            editedBy:userNames,
          };
          dispatch(actions.updateSMExecutives(token, obj));
          settitle("");
          setprofile("");
          settype("");
          setlevel("");
          setname("");
          setclicked(false);
          successUpdateNotification(WORKSPACE, "Updated");
        } else {
        }
      } else {
        if (counter < languages.length) {
          const lang = languages[counter]
            ? languages[counter].abbreviation
            : "";
          const languageName = languages[counter]
            ? languages[counter].name
            : "";
          const subs = setObjects([
            ...objects,
            {
              title,
              name,
              type:
                type === constants.isTrue
                  ? constants?.boardofdirectors
                  : constants?.seniormanagement,
              profile,
              level:
                level === constants.isTrue
                  ? constants?.executive
                  : constants?.manager,
              creator: userNames,
              language: lang,
            },
          ]);
          setcounter(counter + 1);
          successNotification(WORKSPACE, languageName);
          settitle("");
          setprofile("");
          settype("");
          setlevel("");
          setname("");
          setclicked(false);
        }
        if (counter == languages.length - 1) {
          settitle(title);
          setprofile(profile);
          settype(type);
          setlevel(level);
          setname(name);
          setbtndisabled(true);
        }
        if (counter == languages.length) {
          if (window.confirm("Are you sure you want submit?")) {
            const fd = new FormData();
            fd.append("image", image);
            fd.append("objects", JSON.stringify(objects));
            dispatch(actions.createSMExecutives(fd, token));
            setcounter(0);
            setObjects([]);
            settitle("");
            setprofile("");
            settype("");
            setlevel("");
            setname("");
            setclicked(false);
            setbtndisabled(false);
            successCreationNotification(WORKSPACE);
          } else {
          }
        }
      }
    }
  };

  return (
    <div className="">
      <Header />
      <ReactNotifications />
      <main id="main">
        <div className="breadcrumbs-modified d-flex align-items-center"></div>
        {loading ? (
          <MiSpinner />
        ) : (
          <section id="contact" className="input-data">
            <div className="container" data-aos="fade-up">
              <div className="row d-flex justify-content-end php-email-form">
                <div className="col-md-8">
                  <div className="">
                    <div className="">
                      {object !== null ? (
                        <>
                          <h5 className="bdf-bluefontcolor">
                            EDIT {WORKSPACE} ({object?.language})
                          </h5>
                        </>
                      ) : (
                        <h5 className="bdf-bluefontcolor">
                          CREATE {WORKSPACE} IN: {languages[counter]?.name}
                        </h5>
                      )}
                    </div>
                    <div className="form-group py-1">
                      <InputText2
                        title={"Name"}
                        value={name}
                        name="name"
                        placeholder="Name"
                        isClicked={clicked}
                        onChange={(e) => setname(e.target.value)}
                      />
                    </div>
                    <div className="form-group py-1">
                      <InputText2
                        title={"Title"}
                        value={title}
                        name="title"
                        placeholder="Title"
                        isClicked={clicked}
                        onChange={(e) => settitle(e.target.value)}
                      />
                    </div>
                    <div className="p-1 row">
                      <SelectRadioButton
                        title="Executive"
                        option1={constants.isTrue}
                        option2={constants.isFalse}
                        name="level"
                        value={level}
                        isClicked={clicked}
                        onChange={(e) => setlevel(e.target.value)}
                      />
                      <SelectRadioButton
                        title="Board Member"
                        option1={constants.isTrue}
                        option2={constants.isFalse}
                        name="type"
                        value={type}
                        isClicked={clicked}
                        onChange={(e) => settype(e.target.value)}
                      />
                    </div>
                    <div className="form-group py-1">
                      <InputTextArea
                        isClicked={clicked}
                        title={"Profile"}
                        placeholder="Profile"
                        value={profile}
                        name="profile"
                        onChange={(e) => setprofile(e.target.value)}
                      />
                    </div>
                    <div className="form-group py-1">
                      <UplodadFile
                        title="Image"
                        updating={
                          object != null ? constants.isTrue : constants.isFalse
                        }
                        name="image"
                        required
                        onChange={fileSelectorHandler}
                      />
                    </div>
                    <div className="row gx-5 pt-3"></div>
                    <SubmitButton
                      disabled={btndisabled}
                      action={object === null ? "Create" : "Update"}
                      onSubmit={onSubmit}
                    />
                    <span className="px-2"></span>
                    <SubmitButton
                      disabled={!btndisabled}
                      classes={object === null ? "bg-success" : "d-none"}
                      action={"Submit"}
                      onSubmit={onSubmit}
                    />
                    <span className="px-5"></span>
                    <ActivateButton
                      status={object?.status}
                      onSubmit={activating}
                    />
                  </div>
                </div>
                <div className="col-md-3 d-flex gy-5 ml-5 justify-content-end">
                  <div>
                    <div className="flex flex-column h-50">
                      <PageLink
                        page={"/admin/about/executives/objects/list"}
                        title={"List"}
                      />
                    </div>
                    <div>
                      <LanguagesTable languages={languages} counter={counter} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
      </main>
      <ScrollToTop />
    </div>
  );
};

export const AdminBDFMission = function (props) {
  const WORKSPACE = "BDF Missions";
  const history = useHistory();
  const params = useParams();
  const [counter, setcounter] = useState(0);
  const dispatch = useDispatch();
  const [clicked, setclicked] = useState(false);
  const [btndisabled, setbtndisabled] = useState(false);
  const object = useSelector((state) => state.about.misVisValue);
  const loading = useSelector((state) => state.about.misVisValuesLoading);
  useEffect(() => {
    dispatch(getLanguages());
    if (params?.objectId && object === null) {
      dispatch(actions.getOneMissions(token, params?.objectId));
    }
    if (object !== null) {
      setmission(object?.mission);
    }
  }, [object]);
  const [mission, setmission] = useState("");
  const languages = useSelector((state) => state.languages.languages);
  const userNames = useSelector((state) => state.auth.displayName);
  const token = useSelector((state) => state.auth.token);
  const [objects, setObjects] = useState([]);

  const activating = (e) => {
    e.preventDefault();
    if (window.confirm("Are you sure you want change the status?")) {
      if (object?.status === constants.active) {
        dispatch(actions.desActivatingMissions(token, object?.uuid));
        successUpdateNotification(WORKSPACE, "Inactive");
      } else {
        dispatch(actions.activatingMissions(token, object?.uuid));
        successUpdateNotification(WORKSPACE, "Active");
      }
    } else {
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (mission === "") {
      setclicked(true);
    } else {
      if (object != null) {
        if (window.confirm("Are you sure you want update?")) {
          const obj = {
            mission,
            id: object.uuid,editedBy:userNames,
          };
          dispatch(actions.updateMissions(token, obj));
          setmission("");
          setclicked(false);
          successUpdateNotification(WORKSPACE, "Updated");
        } else {
        }
      } else {
        if (counter < languages.length) {
          const lang = languages[counter]
            ? languages[counter].abbreviation
            : "";
          const languageName = languages[counter]
            ? languages[counter].name
            : "";
          const subs = setObjects([
            ...objects,
            {
              mission,
              creator: userNames,
              language: lang,
            },
          ]);
          setcounter(counter + 1);
          successNotification(WORKSPACE, languageName);
          setmission("");
          setclicked(false);
        }
        if (counter == languages.length - 1) {
          setmission(mission);
          setbtndisabled(true);
        }
        if (counter == languages.length) {
          if (window.confirm("Are you sure you want submit?")) {
            dispatch(actions.createMissions(objects, token));
            setcounter(0);
            setObjects([]);
            setmission("");
            setclicked(false);
            setbtndisabled(false);
            successCreationNotification(WORKSPACE);
          } else {
          }
        }
      }
    }
  };

  return (
    <div className="">
      <Header />
      <ReactNotifications />
      <main id="main">
        <div className="breadcrumbs-modified d-flex align-items-center"></div>
        {loading ? (
          <MiSpinner />
        ) : (
          <section id="contact" className="input-data">
            <div className="container" data-aos="fade-up">
              <div className="row d-flex justify-content-end php-email-form">
                <div className="col-md-8">
                  <div className="">
                    <div className="">
                      {object !== null ? (
                        <>
                          <h5 className="bdf-bluefontcolor">
                            EDIT {WORKSPACE} ({object?.language})
                          </h5>
                        </>
                      ) : (
                        <h5 className="bdf-bluefontcolor">
                          CREATE {WORKSPACE} IN: {languages[counter]?.name}
                        </h5>
                      )}
                    </div>
                    <div className="form-group py-3">
                      <InputTextArea
                        isClicked={clicked}
                        title={"Mission"}
                        placeholder="Mission"
                        value={mission}
                        name="mission"
                        onChange={(e) => setmission(e.target.value)}
                      />
                    </div>
                    <div className="row gx-5"></div>
                    <SubmitButton
                      disabled={btndisabled}
                      action={object === null ? "Create" : "Update"}
                      onSubmit={onSubmit}
                    />
                    <span className="px-2"></span>
                    <SubmitButton
                      disabled={!btndisabled}
                      classes={object === null ? "bg-success" : "d-none"}
                      action={"Submit"}
                      onSubmit={onSubmit}
                    />
                    <span className="px-5"></span>
                    <ActivateButton
                      status={object?.status}
                      onSubmit={activating}
                    />
                  </div>
                </div>
                <div className="col-md-3 d-flex gy-5 ml-5 justify-content-end">
                  <div>
                    <div className="flex flex-column h-50">
                      <PageLink
                        page={"/admin/about/missions/objects/list"}
                        title={"List"}
                      />
                    </div>
                    <div>
                      <LanguagesTable languages={languages} counter={counter} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
      </main>
      <ScrollToTop />
    </div>
  );
};

export const AdminBDFVision = function (props) {
  const WORKSPACE = "BDF Visions";
  const history = useHistory();
  const params = useParams();
  const [counter, setcounter] = useState(0);
  const dispatch = useDispatch();
  const [clicked, setclicked] = useState(false);
  const [btndisabled, setbtndisabled] = useState(false);
  const object = useSelector((state) => state.about.misVisValue);
  const loading = useSelector((state) => state.about.misVisValuesLoading);
  useEffect(() => {
    dispatch(getLanguages());
    if (params?.objectId && object === null) {
      dispatch(actions.getOneVisions(token, params?.objectId));
    }
    if (object !== null) {
      setvision(object?.vision);
    }
  }, [object]);
  const [vision, setvision] = useState("");
  const languages = useSelector((state) => state.languages.languages);
  const userNames = useSelector((state) => state.auth.displayName);
  const token = useSelector((state) => state.auth.token);
  const [objects, setObjects] = useState([]);

  const activating = (e) => {
    e.preventDefault();
    if (window.confirm("Are you sure you want change the status?")) {
      if (object?.status === constants.active) {
        dispatch(actions.desActivatingVisions(token, object?.uuid));
        successUpdateNotification(WORKSPACE, "Inactive");
      } else {
        dispatch(actions.activatingVisions(token, object?.uuid));
        successUpdateNotification(WORKSPACE, "Active");
      }
    } else {
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (vision === "") {
      setclicked(true);
    } else {
      if (object != null) {
        if (window.confirm("Are you sure you want update?")) {
          const obj = {
            vision,
            id: object.uuid,editedBy:userNames,
          };
          dispatch(actions.updateVisions(token, obj));
          setvision("");
          setclicked(false);
          successUpdateNotification(WORKSPACE, "Updated");
        } else {
        }
      } else {
        if (counter < languages.length) {
          const lang = languages[counter]
            ? languages[counter].abbreviation
            : "";
          const languageName = languages[counter]
            ? languages[counter].name
            : "";
          const subs = setObjects([
            ...objects,
            {
              vision,
              creator: userNames,
              language: lang,
            },
          ]);
          setcounter(counter + 1);
          successNotification(WORKSPACE, languageName);
          setvision("");
          setclicked(false);
        }
        if (counter == languages.length - 1) {
          setvision(vision);
          setbtndisabled(true);
        }
        if (counter == languages.length) {
          if (window.confirm("Are you sure you want submit?")) {
            dispatch(actions.createVisions(objects, token));
            setcounter(0);
            setObjects([]);
            setvision("");
            setclicked(false);
            setbtndisabled(false);
            successCreationNotification(WORKSPACE);
          } else {
          }
        }
      }
    }
  };

  return (
    <div className="">
      <Header />
      <ReactNotifications />
      <main id="main">
        <div className="breadcrumbs-modified d-flex align-items-center"></div>
        {loading ? (
          <MiSpinner />
        ) : (
          <section id="contact" className="input-data">
            <div className="container" data-aos="fade-up">
              <div className="row d-flex justify-content-end php-email-form">
                <div className="col-md-8">
                  <div className="">
                    <div className="">
                      {object !== null ? (
                        <>
                          <h5 className="bdf-bluefontcolor">
                            EDIT {WORKSPACE} ({object?.language})
                          </h5>
                        </>
                      ) : (
                        <h5 className="bdf-bluefontcolor">
                          CREATE {WORKSPACE} IN: {languages[counter]?.name}
                        </h5>
                      )}
                    </div>
                    <div className="form-group py-3">
                      <InputTextArea
                        isClicked={clicked}
                        title={"Vision"}
                        placeholder="Vision"
                        value={vision}
                        name="vision"
                        onChange={(e) => setvision(e.target.value)}
                      />
                    </div>
                    <div className="row gx-5"></div>
                    <SubmitButton
                      disabled={btndisabled}
                      action={object === null ? "Create" : "Update"}
                      onSubmit={onSubmit}
                    />
                    <span className="px-2"></span>
                    <SubmitButton
                      disabled={!btndisabled}
                      classes={object === null ? "bg-success" : "d-none"}
                      action={"Submit"}
                      onSubmit={onSubmit}
                    />
                    <span className="px-5"></span>
                    <ActivateButton
                      status={object?.status}
                      onSubmit={activating}
                    />
                  </div>
                </div>
                <div className="col-md-3 d-flex gy-5 ml-5 justify-content-end">
                  <div>
                    <div className="flex flex-column h-50">
                      <PageLink
                        page={"/admin/about/visions/objects/list"}
                        title={"List"}
                      />
                    </div>
                    <div>
                      <LanguagesTable languages={languages} counter={counter} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
      </main>
      <ScrollToTop />
    </div>
  );
};

export const AdminBDFValue = function (props) {
  const WORKSPACE = "BDF Values";
  const history = useHistory();
  const params = useParams();
  const [counter, setcounter] = useState(0);
  const dispatch = useDispatch();
  const [clicked, setclicked] = useState(false);
  const [btndisabled, setbtndisabled] = useState(false);
  const object = useSelector((state) => state.about.misVisValue);
  const loading = useSelector((state) => state.about.misVisValuesLoading);
  useEffect(() => {
    dispatch(getLanguages());
    if (params?.objectId && object === null) {
      dispatch(actions.getOneValues(token, params?.objectId));
    }
    if (object !== null) {
      setvalue(object?.value);
    }
  }, [object]);
  const [value, setvalue] = useState("");
  const languages = useSelector((state) => state.languages.languages);
  const userNames = useSelector((state) => state.auth.displayName);
  const token = useSelector((state) => state.auth.token);
  const [objects, setObjects] = useState([]);

  const activating = (e) => {
    e.preventDefault();
    if (window.confirm("Are you sure you want change the status?")) {
      if (object?.status === constants.active) {
        dispatch(actions.desActivatingValues(token, object?.uuid));
        successUpdateNotification(WORKSPACE, "Inactive");
      } else {
        dispatch(actions.activatingValues(token, object?.uuid));
        successUpdateNotification(WORKSPACE, "Active");
      }
    } else {
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (value === "") {
      setclicked(true);
    } else {
      if (object != null) {
        if (window.confirm("Are you sure you want update?")) {
          const obj = {
            value,
            id: object.uuid,editedBy:userNames,
          };
          dispatch(actions.updateValues(token, obj));
          setvalue("");
          setclicked(false);
          successUpdateNotification(WORKSPACE, "Updated");
        } else {
        }
      } else {
        if (counter < languages.length) {
          const lang = languages[counter]
            ? languages[counter].abbreviation
            : "";
          const languageName = languages[counter]
            ? languages[counter].name
            : "";
          const subs = setObjects([
            ...objects,
            {
              value,
              creator: userNames,
              language: lang,
            },
          ]);
          setcounter(counter + 1);
          successNotification(WORKSPACE, languageName);
          setvalue("");
          setclicked(false);
        }
        if (counter == languages.length - 1) {
          setvalue(value);
          setbtndisabled(true);
        }
        if (counter == languages.length) {
          if (window.confirm("Are you sure you want submit?")) {
            dispatch(actions.createValues(objects, token));
            setcounter(0);
            setObjects([]);
            setvalue("");
            setclicked(false);
            setbtndisabled(false);
            successCreationNotification(WORKSPACE);
          } else {
          }
        }
      }
    }
  };

  return (
    <div className="">
      <Header />
      <ReactNotifications />
      <main id="main">
        <div className="breadcrumbs-modified d-flex align-items-center"></div>
        {loading ? (
          <MiSpinner />
        ) : (
          <section id="contact" className="input-data">
            <div className="container" data-aos="fade-up">
              <div className="row d-flex justify-content-end php-email-form">
                <div className="col-md-8">
                  <div className="">
                    <div className="">
                      {object !== null ? (
                        <>
                          <h5 className="bdf-bluefontcolor">
                            EDIT {WORKSPACE} ({object?.language})
                          </h5>
                        </>
                      ) : (
                        <h5 className="bdf-bluefontcolor">
                          CREATE {WORKSPACE} IN: {languages[counter]?.name}
                        </h5>
                      )}
                    </div>
                    <div className="form-group py-3">
                      <InputTextArea
                        isClicked={clicked}
                        title={"Value"}
                        placeholder="Value"
                        value={value}
                        name="value"
                        onChange={(e) => setvalue(e.target.value)}
                      />
                    </div>
                    <div className="row gx-5"></div>
                    <SubmitButton
                      disabled={btndisabled}
                      action={object === null ? "Create" : "Update"}
                      onSubmit={onSubmit}
                    />
                    <span className="px-2"></span>
                    <SubmitButton
                      disabled={!btndisabled}
                      classes={object === null ? "bg-success" : "d-none"}
                      action={"Submit"}
                      onSubmit={onSubmit}
                    />
                    <span className="px-5"></span>
                    <ActivateButton
                      status={object?.status}
                      onSubmit={activating}
                    />
                  </div>
                </div>
                <div className="col-md-3 d-flex gy-5 ml-5 justify-content-end">
                  <div>
                    <div className="flex flex-column h-50">
                      <PageLink
                        page={"/admin/about/values/objects/list"}
                        title={"List"}
                      />
                    </div>
                    <div>
                      <LanguagesTable languages={languages} counter={counter} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
      </main>
      <ScrollToTop />
    </div>
  );
};
