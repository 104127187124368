import { updateObject } from "../../utils/utility";
import {
  GET_PUBLICATIONS_START,
  GET_PUBLICATIONS_SUCCESS,
  GET_ONE_PUBLICATIONS_SUCCESS,
  GET_PUBLICATIONS_FAIL,
  GET_PUBLICATION_CONTENTS_START,
  GET_PUBLICATION_CONTENTS_SUCCESS,
  GET_ONE_PUBLICATION_CONTENTS_SUCCESS,
  GET_PUBLICATION_CONTENTS_FAIL,
} from "../actions/publications";

const initialReducer = {
  publication: null,
  publications: [],
  publicationsLoading: false,
  publicationsFail: null,
  content: null,
  contents: [],
  contentsLoading: false,
  contentsFail: null,
};

const getPublicationsStart = (state, action) => {
  return updateObject(state, {
    publications: [],
    publication: null,
    publicationsLoading: true,
    publicationsFail: null,
  });
};

const getPublicationsSuccess = (state, action) => {
  return updateObject(state, {
    publications: action.payload,
    publicationsLoading: false,
    publicationsFail: null,
    publication: null,
  });
};

const getOnePublicationsSuccess = (state, action) => {
  return updateObject(state, {
    publication: action.payload,
    publications: [],
    publicationsLoading: false,
    publicationsFail: null,
  });
};

const getPublicationsFail = (state, action) => {
  return updateObject(state, {
    publications: [],
    publication: null,
    publicationsLoading: false,
    publicationsFail: action.payload,
  });
};

const getPublicationContentsStart = (state, action) => {
  return updateObject(state, {
    contents: [],
    content: null,
    contentsLoading: true,
    contentsFail: null,
  });
};

const getPublicationContentsSuccess = (state, action) => {
  return updateObject(state, {
    contents: action.payload,
    contentsLoading: false,
    contentsFail: null,
    content: null,
  });
};

const getOnePublicationContentsSuccess = (state, action) => {
  return updateObject(state, {
    content: action.payload,
    contents: [],
    contentsLoading: false,
    contentsFail: null,
  });
};

const getPublicationContentsFail = (state, action) => {
  return updateObject(state, {
    contents: [],
    content: null,
    contentsLoading: false,
    contentsFail: action.payload,
  });
};

const reducer = (state = initialReducer, action) => {
  switch (action.type) {
    case GET_PUBLICATIONS_START:
      return getPublicationsStart(state, action);
    case GET_PUBLICATIONS_SUCCESS:
      return getPublicationsSuccess(state, action);
    case GET_ONE_PUBLICATIONS_SUCCESS:
      return getOnePublicationsSuccess(state, action);
    case GET_PUBLICATIONS_FAIL:
      return getPublicationsFail(state, action);
    case GET_PUBLICATION_CONTENTS_START:
      return getPublicationContentsStart(state, action);
    case GET_PUBLICATION_CONTENTS_SUCCESS:
      return getPublicationContentsSuccess(state, action);
    case GET_ONE_PUBLICATION_CONTENTS_SUCCESS:
      return getOnePublicationContentsSuccess(state, action);
    case GET_PUBLICATION_CONTENTS_FAIL:
      return getPublicationContentsFail(state, action);
    default:
      return state;
  }
};

export default reducer;
